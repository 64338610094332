import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { IFieldData } from '../../types/IFieldData';
import { Card, CardActions, CardContent, CardHeader, Divider, Icon, IconButton, Tooltip } from '@mui/material';

import JSZip from "jszip"
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import printjs from 'print-js'
import { t } from 'i18next';

export type SwipeableImageListProps = {
    images: IFieldData;
    withCard: boolean;
}

export default function SwipeableImageList(props: SwipeableImageListProps) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = props.images.values.length - 1;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const labels = props.images.labels || [];
    const values = props.images.values || [];
    const notes = props.images.notes || [];

    return (
        props.withCard ?
            <Card>
                <CardHeader
                    title={notes[activeStep] || labels[activeStep]}
                    sx={{
                        display: 'block'
                    }
                    }
                    titleTypographyProps={{
                        sx: {
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        },
                        variant: "h5"
                    }}>
                </CardHeader >
                <Divider sx={{ mb: 2 }} variant="middle" />
                <CardContent>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {labels.map((label, index) => (
                            <div key={index}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <Box
                                        component="img"
                                        sx={{
                                            objectFit: "contain",
                                            width: "100%",
                                            height: "300px"
                                        }}
                                        src={props.images.values[index]}
                                        alt={label}
                                    />
                                ) : null}
                            </div>
                        ))}
                    </SwipeableViews>
                </CardContent>
                <Divider variant="middle" className="no-print" />
                <CardActions className="no-print" >
                    {maxSteps > 0 &&
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === maxSteps - 1}
                                >
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowLeft />
                                    ) : (
                                        <KeyboardArrowRight />
                                    )}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                </Button>
                            }
                            sx={{ backgroundColor: "#fff", py: 0, pl: "76px", ml: "auto" }}
                        />}
                    <Tooltip title={t("application.SwipeableImageList.Print")} disableInteractive>
                        <IconButton size="small"
                            sx={{ ml: "auto" }}
                            onClick={() => {
                                const value = values[activeStep];
                                if (value != undefined) {
                                    printjs(value, 'image');
                                }
                            }}>
                            <Icon>print</Icon>
                        </IconButton >
                    </Tooltip>
                    <Tooltip title={t("application.SwipeableImageList.Download Images")} disableInteractive>
                        <IconButton size="small"
                            sx={{ ml: "auto" }}
                            onClick={() => {
                                const zip = new JSZip();
                                const img = zip.folder("images");

                                const usedLabels: string[] = [];
                                values?.map((imageValue: string | undefined, index: any) => {
                                    if (imageValue) {
                                        let label = labels[index] || "";
                                        const filename = label.substring(0, label.lastIndexOf("."));
                                        const extension = label.substring(label.lastIndexOf(".") + 1);
                                        label = usedLabels.some((obj) => obj.toLowerCase() === (labels[index] || "").toLowerCase()) ? (filename + "_" + index + "." + extension) : (labels[index] || "");
                                        if (label) {
                                            usedLabels.push(label);
                                            img?.file(label, imageValue.substring(imageValue.indexOf("base64,") + 7), { base64: true });
                                        }
                                    }
                                });

                                zip.generateAsync({ type: "blob" })
                                    .then(function (content) {
                                        saveAs(content, "TovekFinderData_Images_" + dayjs().format('DD-MM-YYYY') + ".zip");
                                    });
                            }}>
                            <Icon>download</Icon>
                        </IconButton >
                    </Tooltip>
                </CardActions>
            </Card >
            :
            <>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {labels.map((label, index) => (
                        <div key={index}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <Box
                                    component="img"
                                    sx={{
                                        objectFit: "contain",
                                        width: "100%",
                                        height: "300px"
                                    }}
                                    src={props.images.values[index]}
                                    alt={label}
                                />
                            ) : null}
                        </div>
                    ))}
                </SwipeableViews>
                {maxSteps > 0 &&
                    <MobileStepper
                        className='no-print'
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                            </Button>
                        }
                        sx={{ backgroundColor: "#fff", py: 0, ml: "auto" }}
                    />}
            </>
    );
}