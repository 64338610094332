import React, { useContext } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import TovekAppBar from './components/TovekAppBar'
import { TovekAppContext } from './contexts/AppContext'
import HomePage from './pages/HomePage'
import Help from './pages/Help'
import { ITovekAppContext } from './types/ITovekContextApp'
import Report from './pages/Report'
import Login from './pages/Login'

//const basename: string = window.location.pathname.replace(/(\/search\/[^/]*)$/, "");

const ReactRouter: React.FC = () => {
    const { config } = useContext<ITovekAppContext>(TovekAppContext);

    return (
        <HashRouter /*basename={basename}*/>
            <div style={{
                display: "flex",
                flexFlow: "column",
                height: "100vh"
            }}>
                <TovekAppBar />
                <Routes>
                    <Route path="/" element={<Navigate replace to={config.categories ? "/search/people" : "/help"} />} />
                    <Route path="search" >
                        {config.categories?.map((category) => (
                            <Route key={category.key} path={category.key} element={<HomePage />} />
                        ))}
                        <Route path="all" element={<Navigate replace to={"/search/people"} />} />
                    </Route>
                    <Route path="help" element={<Help />} />
                    <Route path="login" element={<Login />} />
                    <Route path="report">
                        {config.categories?.map((category) => (
                            <Route key={category.key} path={category.key} element={<Report category={category.key} />} />
                        ))}
                        <Route key="all" path="all" element={<Report category="all" />} />
                    </Route>
                </Routes>
            </div>
        </HashRouter >
    )
}

export default ReactRouter