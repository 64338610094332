import { TextField, InputAdornment, Tooltip, IconButton, Box, Link, styled } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { t } from 'i18next';
import Linkify from 'linkify-react';
import moment from 'moment';
import * as React from 'react';
import { TovekAppContext } from '../../contexts/AppContext';
import { newINoteData } from '../../types/INoteData';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import ImageIcon from '@mui/icons-material/Image';
import HideImageIcon from '@mui/icons-material/HideImage';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

interface FormSectionHelperTextProps {
    openNote: any;
    index: any;
    field: any;
    fieldNote: any;
    isNoteVisible: any;
    closeNote: any;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function FormSectionHelperText(props: FormSectionHelperTextProps) {
    const { setFieldData, isNotesVisible } = React.useContext<ITovekAppContext>(TovekAppContext);
    const inputRef = React.useRef<HTMLInputElement>(null);

    let uploadingNoteImage = false;

    function removeNoteImage() {
        setFieldData(props.field.key, props.index, undefined, newINoteData(inputRef.current?.value));
    }

    function addNoteImage(img: string) {
        setFieldData(props.field.key, props.index, undefined, newINoteData(inputRef.current?.value, img, moment().format(t("application.formats.datetime"))));
    }

    function blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result + "");
            reader.readAsDataURL(blob);
        });
    }

    if (props.fieldNote != undefined) {
        return (
            <>
                {
                    props.isNoteVisible
                        ?
                        <TextField
                            inputRef={inputRef}
                            id={props.fieldNote.value}
                            key={props.fieldNote.value}
                            label={t("application.FormSectionHelperText.Note")}
                            variant="filled"
                            multiline
                            sx={{ width: "100%!important" }}
                            defaultValue={props.fieldNote.value}
                            onBlur={(e) => {
                                if (!uploadingNoteImage) {
                                    props.closeNote(e.currentTarget?.value || e.target?.value);
                                }
                            }}
                            onFocus={(e) => {
                                uploadingNoteImage = false;
                                e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                            }}
                            autoFocus={true}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && e.ctrlKey) {
                                    props.closeNote((e.currentTarget as HTMLInputElement)?.value);
                                }
                                if (e.key === 'Escape') {
                                    props.closeNote(null);
                                }
                            }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        {props.fieldNote.img ?
                                            <Tooltip title={t("application.FormSectionHelperText.Remove Note Image") + ""}>
                                                <IconButton size='small'
                                                    onClick={() => {
                                                        removeNoteImage();
                                                        if (inputRef.current) {
                                                            inputRef.current.focus();
                                                        }
                                                    }}
                                                    onMouseDown={(e) => { uploadingNoteImage = true; }}
                                                    edge="end"
                                                    tabIndex={-1}
                                                >
                                                    <HideImageIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            <Tooltip title={t("application.FormSectionHelperText.Upload Note Image") + ""}>
                                                <IconButton
                                                    size='small'
                                                    component="label"
                                                    onMouseDown={(e) => { uploadingNoteImage = true; }}
                                                    edge="end"
                                                    tabIndex={-1}
                                                >
                                                    <AddPhotoAlternateIcon fontSize='small' />
                                                    <VisuallyHiddenInput type="file" accept="image/*" onChange={(e) => {
                                                        if (e.target.files && e.target.files[0]) {
                                                            const file = e.target.files[0];
                                                            if (file.size > 204800) {
                                                                if (confirm("\n" + file.name + "\n\n" + t("application.FormSectionHelperText.The image is too large, do you want to upload it compressed?")) == true) {
                                                                    imageCompression(e.target.files[0], {
                                                                        maxSizeMB: 204800 / 1024 / 1024,
                                                                        maxWidthOrHeight: 1024,
                                                                        useWebWorker: true
                                                                    }).then(function (compressedFile) {
                                                                        blobToBase64(compressedFile).then(function (base64) {
                                                                            addNoteImage(base64);
                                                                            if (inputRef.current) {
                                                                                inputRef.current.focus();
                                                                            }
                                                                        });
                                                                    })
                                                                        .catch(function (error) {
                                                                            console.log(error.message);
                                                                        });
                                                                }
                                                            }
                                                            else {
                                                                blobToBase64(file).then(function (base64) {
                                                                    addNoteImage(base64);
                                                                    if (inputRef.current) {
                                                                        inputRef.current.focus();
                                                                    }
                                                                });
                                                            }
                                                        }
                                                    }} />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </InputAdornment>,
                            }
                            }
                        /> :
                        <span onDoubleClick={props.openNote} style={{ overflowWrap: "break-word", whiteSpace: "pre-wrap" }}>
                            {isNotesVisible && props.fieldNote.img &&
                                <Tooltip title={
                                    <Box>
                                        {props.fieldNote.value}
                                        <br />
                                        <img
                                            alt={props.fieldNote.value || props.fieldNote.timestamp || ""}
                                            src={props.fieldNote.img}
                                            style={{ objectFit: "contain", maxHeight: 200, width: "100%" }}
                                        />
                                        <br />
                                        {props.fieldNote.timestamp || ""}
                                    </Box>}
                                >
                                    <ImageIcon fontSize='small' sx={{ fontSize: 14, float: "right" }} />
                                </Tooltip>
                            }
                            {isNotesVisible && props.fieldNote.value ?
                                <Linkify as="span" options={{
                                    render: ({ attributes, content }) => {
                                        const { href, ...props } = attributes;
                                        return <Link underline="hover" href={href} target={"_blank"} {...props}>{content}</Link>;
                                    }
                                }}>
                                    {props.fieldNote.value}
                                </Linkify>
                                :
                                ""}

                        </span>
                }
            </>
        )
    }
    else {
        return (<></>);
    }
}