import React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { Icon } from '@mui/material';
import cytoscape from 'cytoscape';
import cxtmenu from 'cytoscape-cxtmenu';
import $ from 'jquery';

import copy from 'copy-to-clipboard';

function importAll(r) {
    return r.keys().reduce((accumulator, element, index) => {
        return { ...accumulator, [element]: r.keys().map(r)[index] };
    }, {});
}

const images = importAll(require.context('../../resources/images/icons'));

export function prepareOutput(preparedDiagram, tqlRef, editorRef, diagramDef, all, cy) {
    const diagramInJSON = JSON.parse(JSON.stringify(preparedDiagram));
    let tql = "";
    try {
        tql = tqlRef.current.value || "";
    } catch (e) { tql = "" }
    let content = "";
    try {
        content = editorRef.current.getContent({ format: 'text' }) || "";
    } catch (e) { content = "" }

    diagramInJSON.data.elements.nodes = _.filter(diagramInJSON.data.elements.nodes, (node) => {
        if (node.data.__mainObject && node.data.document.content == "##FINDER_OBJECT_CONTENT##") {
            node.data.document.content = content;
        }
        _.each(node.data.document.fields, (value, key) => {
            if (node.data.__mainObject && value.value == "##FINDER_OBJECT_CONTENT##") {
                node.data.document.fields[key].value = content;
            }
            if (node.data.__mainObject && value.value == "##FINDER_OBJECT_TQL##") {
                node.data.document.fields[key].value = tql;
            }
            delete node.data[key];
        });
        if (diagramDef && diagramDef.html_content_field && node.data.__mainObject) {
            node.data.document.fields[diagramDef.html_content_field.key] = { type: diagramDef.html_content_field.type, value: editorRef.current.getContent() };
        }
        delete node.data.content;
        delete node.data.label;
        delete node.data.__mainObject;
        if (cy) {
            const element = cy.getElementById(node.data.id);
            if (!all && element.selected()) {
                node.position = {
                    x: element.position().x,
                    y: element.position().y
                }
                return true;
            }
            return false;
        }
        return true;
    });

    diagramInJSON.data.elements.edges = _.filter(diagramInJSON.data.elements.edges, (edge) => {
        _.each(edge.data.document.fields, (value, key) => {
            delete edge.data[key];
        });
        delete edge.data.content;
        delete edge.data.label;

        if (_.find(diagramInJSON.data.elements.nodes, { data: { id: edge.data.source } }) && _.find(diagramInJSON.data.elements.nodes, { data: { id: edge.data.target } })) {
            if (cy) {
                const element = cy.getElementById(edge.data.id);
                if (!all && element.selected()) {
                    return true;
                }
                return false;
            }
            return true;
        }
        else {
            return false;
        }
    });
    return diagramInJSON;
}

export default function Cytoscape(props) {

    var jsonData = props.jsonData;

    const graphRef = React.useRef(null);

    let cy;
    const [_cy, setCy] = React.useState(null);

    props.registerCallback(() => {
        return _cy.png({
            full: true,
            output: 'blob'
        });
    });

    let lastClick;

    function memoize(style, func) {
        var memoized = function (ele) {
            return func(ele);
        };
        return memoized;
    }

    function convertDocumentFiledsToData(items, key) {
        var obj = {};
        for (var item in items) {
            obj[item] = items[item][key];
        }
        return obj;
    }

    function halign(ele) {
        if (ele.data('labelParameter')) {
            var nodeRatioX = ele.data('labelParameter').split(";")[1];
            if (nodeRatioX < 0) {
                return 'left';
            }
            else if (nodeRatioX > 0) {
                return 'right';
            }
            else {
                return 'center';
            }
        }
        else {
            return ele.dataCI('legenditem') ? 'right' : 'center';
        }
    }

    function valign(ele) {
        if (ele.data('labelParameter')) {
            var nodeRatioY = ele.data('labelParameter').split(";")[2];
            if (nodeRatioY < 0) {
                return 'top';
            }
            else if (nodeRatioY > 0) {
                return 'bottom';
            }
            else {
                return 'center';
            }
        }
        else {
            return (ele.data('shape') || ele.dataCI('legenditem')) ? 'center' : 'bottom';
        }
    }

    function getPositionAlongTheLine(x1, y1, x2, y2, percentage) {
        return {
            x: x1 * (1.0 - percentage) + x2 * percentage,
            y: y1 * (1.0 - percentage) + y2 * percentage
        };
    }

    function textMarginX(ele) {
        try {
            if (ele.data("_afterLayout") == -1) {
                return 0;
            }
            else if (ele.data("_afterLayout") == 1) {
                var parallelEdges = ele.parallelEdges();
                if (parallelEdges && parallelEdges.length > 1 && !ele.hasClass('edgebendediting-hasbendpoints')) {
                    var bbLabels = ele.boundingBox({
                        includeNodes: false,
                        includeEdges: true,
                        includeLabels: false,
                        includeMainLabels: false,
                        includeSourceLabels: false,
                        includeTargetLabels: false,
                        includeOverlays: false
                    });

                    var sorted = parallelEdges.sort(function (ele1, ele2) {
                        return ele1.midpoint().x - ele2.midpoint().x;
                    });
                    var index = sorted.indexOf(ele);
                    if (parallelEdges.length % 2 == 0 && index >= Math.floor(parallelEdges.length / 2)) {
                        index = index - Math.floor(parallelEdges.length / 2) + 1;
                    }
                    else {
                        index = index - Math.floor(parallelEdges.length / 2);
                    }
                    var correction = 0;
                    switch (parallelEdges.length) {
                        case 1:
                        case 2:
                            correction = 2;
                            break;
                        case 3:
                        case 4:
                            correction = 1.5;
                            break;
                        default:
                            correction = 1;
                            break;
                    }
                    var numberOfSegments = bbLabels.w / (parallelEdges.length + 1) / correction;
                    return (numberOfSegments * index) * (bbLabels.w > bbLabels.h ? 1 : -1);
                }
            }
            else {
                var labelParameter = ele.data('labelParameter');
                if (labelParameter && cy.edgeBendEditing('get') && ele.scratch('_labelParameterSet') != false) {
                    var bendSegments = cy.edgeBendEditing('get').getSegmentPoints(ele);
                    if (bendSegments && bendSegments.length > 0) {
                        ele.scratch()['_labelParameterSet'] = true;
                        var segment = parseFloat(labelParameter.split(";")[1]);
                        var ratio = parseFloat(labelParameter.split(";")[2]);
                        var distance = parseFloat(labelParameter.split(";")[3]);
                        var position = parseFloat(labelParameter.split(";")[5]);



                        var x1 = ele.sourceEndpoint().x;
                        var y1 = ele.sourceEndpoint().y;
                        var x2 = ele.targetEndpoint().x;
                        var y2 = ele.targetEndpoint().y;
                        var midpoint = ele.midpoint();
                        if (segment > -1) {
                            var segpts = [x1, y1];
                            segpts = segpts.concat(bendSegments);
                            segpts = segpts.concat(x2, y2);
                            if (segpts.length / 2 >= segment) {
                                x1 = segpts[segment * 2];
                                y1 = segpts[segment * 2 + 1];
                                x2 = segpts[segment * 2 + 2];
                                y2 = segpts[segment * 2 + 3];
                            }
                        }
                        var newX = getPositionAlongTheLine(x1, y1,
                            x2, y2,
                            ratio).x;
                        newX = newX + (position > 1 ? -1 : position) * distance / Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2)) * (y1 - y2);
                        return newX - midpoint.x;
                    }
                    else if (ele.scratch('_labelParameterSet')) {
                        ele.scratch()['_labelParameterSet'] = false;
                    }
                }
            }
            return 0;
        }
        catch (e) {
            return 0;
        }

    }

    function textMarginY(ele) {
        try {
            if (ele.data("_afterLayout") == -1) {
                return 0;
            }
            else if (ele.data("_afterLayout") == 1) {
                var parallelEdges = ele.parallelEdges();
                if (parallelEdges && parallelEdges.length > 1 && !ele.hasClass('edgebendediting-hasbendpoints')) {
                    var bbLabels = ele.boundingBox({
                        includeNodes: false,
                        includeEdges: true,
                        includeLabels: false,
                        includeMainLabels: false,
                        includeSourceLabels: false,
                        includeTargetLabels: false,
                        includeOverlays: false
                    });

                    var sorted = parallelEdges.sort(function (ele1, ele2) {
                        return ele1.midpoint().y - ele2.midpoint().y;
                    });
                    var index = sorted.indexOf(ele);
                    if (parallelEdges.length % 2 == 0 && index >= Math.floor(parallelEdges.length / 2)) {
                        index = index - Math.floor(parallelEdges.length / 2) + 1;
                    }
                    else {
                        index = index - Math.floor(parallelEdges.length / 2);
                    }
                    var correction = 0;
                    switch (parallelEdges.length) {
                        case 1:
                        case 2:
                            correction = 2;
                            break;
                        case 3:
                        case 4:
                            correction = 1.5;
                            break;
                        default:
                            correction = 1;
                            break;
                    }
                    var numberOfSegments = bbLabels.h / (parallelEdges.length + 1) / correction;
                    return (numberOfSegments * index) * (bbLabels.h > bbLabels.w ? 1 : -1);
                }
            }
            else {
                var labelParameter = ele.data('labelParameter');
                if (labelParameter && cy.edgeBendEditing('get') && ele.scratch('_labelParameterSet') != false) {
                    var bendSegments = cy.edgeBendEditing('get').getSegmentPoints(ele);
                    if (bendSegments && bendSegments.length > 0) {
                        ele.scratch()['_labelParameterSet'] = true;
                        var segment = parseFloat(labelParameter.split(";")[1]);
                        var ratio = parseFloat(labelParameter.split(";")[2]);
                        var distance = parseFloat(labelParameter.split(";")[3]);
                        var position = parseFloat(labelParameter.split(";")[5]);

                        var x1 = ele.sourceEndpoint().x;
                        var y1 = ele.sourceEndpoint().y;
                        var x2 = ele.targetEndpoint().x;
                        var y2 = ele.targetEndpoint().y;
                        var midpoint = ele.midpoint();
                        if (segment > -1) {
                            var segpts = [x1, y1];
                            segpts = segpts.concat(bendSegments);
                            segpts = segpts.concat(x2, y2);
                            if (segpts.length / 2 >= segment) {
                                x1 = segpts[segment * 2];
                                y1 = segpts[segment * 2 + 1];
                                x2 = segpts[segment * 2 + 2];
                                y2 = segpts[segment * 2 + 3];
                            }
                        }
                        var newY = getPositionAlongTheLine(x1, y1,
                            x2, y2,
                            ratio).y;
                        newY = newY + (position > 1 ? -1 : position) * distance / Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2)) * (x2 - x1);
                        return newY - midpoint.y;
                    }
                    else if (ele.scratch('_labelParameterSet')) {
                        ele.scratch()['_labelParameterSet'] = false;
                    }
                }
            }
            return 0;
        }
        catch (e) {
            return 0;
        }
    }

    function getAttributeFromHtmlLabel(label, attr, is) {
        if (label && label.indexOf("<html>") == 0) {
            var html = $.parseHTML(label);
            if (html.length != 1) {
                var attrValue;
                for (var item in html) {
                    var itemAttrValue = getAttributeFromHtmlLabel("<html>" + $(html[item]).html(), attr);
                    if (itemAttrValue) {
                        attrValue = itemAttrValue;
                        break;
                    }
                }
                return attrValue;
            }
            else {
                if (is) {
                    if ($(html).is(attr)) {
                        return $(html).is(attr);
                    }
                    else {
                        return getAttributeFromHtmlLabel($(html).html(), attr, is);
                    }
                }
                else {
                    if ($(html).attr(attr)) {
                        return $(html).attr(attr);
                    }
                    else if (html[0].tagName && $(html).css(attr)) {
                        return $(html).css(attr);
                    }
                    else {
                        return getAttributeFromHtmlLabel($(html).html(), attr, is);
                    }
                }
            }
        }
    }

    function pSBC(p, c0, c1, l) {
        var r, g, b, P, f, t, h, i = parseInt;
        var m = Math.round;
        var a = typeof (c1) == "string";
        if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a))
            return null;
        if (!this.pSBCr)
            this.pSBCr = function (d) {
                var n = d.length;
                var x = {};
                if (n > 9) {
                    d = d.split(",");
                    r = d[0];
                    g = d[1];
                    b = d[2];
                    a = d[3];
                    n = d.length;
                    if (n < 3 || n > 4)
                        return null;
                    x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1
                }
                else {
                    if (n == 8 || n == 6 || n < 4)
                        return null;
                    if (n < 6)
                        d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
                    d = i(d.slice(1), 16);
                    if (n == 9 || n == 5)
                        x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = m((d & 255) / 0.255) / 1000;
                    else
                        x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1
                }
                return x
            };
        h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 == "c" ? !h : false : h, f = this.pSBCr(c0), P = p < 0, t = c1 && c1 != "c" ? this.pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }, p = P ? p * -1 : p, P = 1 - p;
        if (!f || !t)
            return null;
        if (l)
            r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b);
        else {
            r = m(Math.pow((P * Math.pow(f.r, 2) + p * Math.pow(t.r, 2)), 0.5));
            g = m(Math.pow((P * Math.pow(f.g, 2) + p * Math.pow(t.g, 2)), 0.5));
            b = m(Math.pow((P * Math.pow(f.b, 2) + p * Math.pow(t.b, 2)), 0.5));
        }
        a = f.a;
        t = t.a;
        f = a >= 0 || t >= 0;
        a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
        if (h)
            return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
        else
            return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2);
    }

    function doParseStyleValue(key, style, unquote, assigner, divider) {
        var value = '';
        if (style) {
            key += (assigner || ":");
            var start = style.indexOf(key);
            start = style.charAt(start - 1) == '-' ? -1 : start;
            if (start >= 0) {
                var from = start + key.length;
                var end;
                if (style.length > from && style.charAt(from) == '"') {
                    end = style.indexOf("\"" + (divider || ";"), start);
                    if (unquote) {
                        value = (end > 0) ? style.substring(from + 1, end) : style.substring(from + 1, style.length - 1);
                    }
                    else {
                        value = (end > 0) ? style.substring(from, end + 1) : style.substring(from, style.length);
                    }
                }
                else {
                    end = style.indexOf(divider || ";", start);
                    value = (end > 0) ? style.substring(from, end) : style.substring(from);
                }
            }
        }
        return value;
    }

    function validTextColour(color) {
        var validColor = null;
        if (color === null) {
            return validColor;
        }
        if (color === undefined) {
            return validColor;
        }
        if (color === "") {
            return validColor;
        }
        if (color === "inherit") {
            return validColor;
        }
        if (color === "transparent") {
            return validColor;
        }
        if (color === "#") {
            return validColor;
        }
        var image = document.createElement("img");
        image.style.color = "rgb(0, 0, 1)";
        image.style.color = color;
        validColor = image.style.color !== "rgb(0, 0, 1)";
        if (!validColor && color.indexOf('#') != 0) {
            return validTextColour("#" + color);
        }
        return validColor ? image.style.color : null;
    }

    function formatHtmlLabel(html) {
        html = html || '';
        html = html.split("<br>").join("\n").split("<br>").join("\n").split("<br>").join("\n");
        html = $("<p>" + html + "<p>");
        html.find("style").remove();
        return html.text();
    }

    function getBackgroundImage(ele) {
        var itemtype = ele.data['_v11n:itemtype'];
        var shape = ele.data['shape'];
        if (_.isFunction(ele.data)) {
            itemtype = ele.data('_v11n:itemtype');
            shape = ele.data('shape');
        }
        var icon = doParseStyleValue('icon', ele.data('_v11n:style'));
        if (icon) {
            var paths = icon.split('/');
            if (paths[0] === '"xlink:href="data:image') {
                icon = icon.substring(13);
                icon = icon.substring(0, icon.indexOf("\""));
            }
            else {
                var hue = doParseStyleValue('hue', ele.data('_v11n:style'));
                if (hue) {
                    icon = images['./v11n/' + icon + '.svg']; //TODO if object has hue
                }
                else {
                    icon = images['./v11n/' + icon + '.svg'];
                }
            }
        }
        else {
            if (shape || doParseStyleValue('border-shape', ele.data('_v11n:style'))) {
                icon = 'none';
            }
            else if (itemtype > 0) {
                icon = images['./v11n/' + '/object/unknown' + '.svg'];
            }
            else {
                icon = images['./v11n/' + '/object/document' + '.svg'];
            }
        }
        return icon;
    }

    function getProp(obj, prop) {
        for (var key in obj) {
            if (key.toLowerCase() == prop.toLowerCase()) {
                return obj[key];
            }
        }
    }

    const drawGraph = () => {

        cytoscape.use(cxtmenu);

        cytoscape('collection', 'dataCI', function (param) {
            if (param) {
                return getProp(this.data(), param);
            }
            else {
                var dataCI = {};
                var obj = this.data();
                for (var key in obj) {
                    dataCI[key.toLowerCase()] = obj[key];
                }
                return dataCI;
            }
        });

        cytoscape.warnings(false);

        var elements = {
            nodes: [],
            edges: []
        };

        jsonData.data.elements.nodes.forEach(function (element) {
            element.data = $.extend(element.data, convertDocumentFiledsToData(element.data.document.fields, "value"), { content: element.data.document.content }, { label: element.data.document.fields["_v11n:label"].value });
            elements.nodes.push(element);
        });

        jsonData.data.elements.edges.forEach(function (element) {
            element.data = $.extend(element.data, convertDocumentFiledsToData(element.data.document.fields, "value"), { content: element.data.document.content }, { label: element.data.document.fields["_v11n:label"].value });
            elements.edges.push(element);
        });

        cy = cytoscape({
            container: graphRef.current,
            boxSelectionEnabled: true,
            userPanningEnabled: true,
            autounselectify: false,
            maxZoom: 5,
            minZoom: 0.1,
            wheelSensitivity: 0.1,
            layout: {
                name: 'concentric',
                nodeDimensionsIncludeLabels: true
            },
            style: [
                {
                    selector: 'node, edge',
                    style: {
                        'label': memoize('label', function (ele) {
                            return formatHtmlLabel(ele.data('label'));
                        }),
                        'text-events': 'yes',
                        'text-rotation': 'none',
                        'text-justification': 'center',
                        'font-family': memoize('font-family', function (ele) {
                            return doParseStyleValue('font-family', ele.data('_v11n:style')) || 'sans-serif';
                        }),
                        'font-size': memoize('font-size', function (ele) {
                            let value = getAttributeFromHtmlLabel(ele.data('label'), 'size');
                            if (value) {
                                value = value * 0.33;
                            }
                            else {
                                value = 1;
                            }
                            let style = ele.data('_v11n:style');
                            let font = doParseStyleValue('font-size', style);
                            let labelZoom = ((doParseStyleValue('zoom', style) || 1) * (doParseStyleValue('label', style) || 1) - 1) * 4;
                            return font ? Math.round((font * value + labelZoom)) : Math.round((11 * value + labelZoom));
                        }),
                        'color': memoize('color', function (ele) {
                            let value = getAttributeFromHtmlLabel(ele.data('label'), 'color');
                            if (!value) {
                                value = doParseStyleValue('font-color', ele.data('_v11n:style'));
                            }
                            let validColor = validTextColour(value);
                            return validColor ? validColor : '#000';
                        }),
                        'font-style': memoize('font-style', function (ele) {
                            let style = getAttributeFromHtmlLabel(ele.data('label'), 'i', true) ? 'italic' : 'normal';
                            return style || doParseStyleValue('font-style', ele.data('_v11n:style'));
                        }),
                        'font-weight': memoize('font-weight', function (ele) {
                            let weight = getAttributeFromHtmlLabel(ele.data('label'), 'b', true) ? 'bold' : 'normal';
                            return weight || doParseObjectStyleValue('font-weight', ele.data('_v11n:style'));
                        }),
                        'overlay-opacity': 0,
                        'text-background-color': memoize('text-background-color', function (ele) {
                            let bgcolor = getAttributeFromHtmlLabel(ele.data('label'), 'bgcolor');
                            return bgcolor || '#FFF';
                        }),
                        'text-background-opacity': memoize('text-background-opacity', function (ele) {
                            return ele.data('labelTransparent') ? 0 : 1;
                        }),
                        'text-wrap': 'wrap',
                        'text-max-width': '1000px',
                        'min-zoomed-font-size': 2,
                        'z-compound-depth': memoize('z-compound-depth', function (ele) {
                            return ele.data('shape') && (ele.data('viewType') || "").toLowerCase() != 'shape' ? (ele.dataCI('legenditem') ? 'auto' : 'bottom') : 'auto';
                        })
                    }
                }, {
                    selector: 'node',
                    style: {
                        'width': function (ele) {
                            //return doParseStyleValue('width', ele.data('_v11n:style')) || 30; 
                            //var icon = doParseStyleValue('icon', ele.data('_v11n:style'));
                            return doParseStyleValue('width', ele.data('_v11n:style'), true, "=", " ");
                        },
                        'height': function (ele) {
                            //return doParseStyleValue('height', ele.data('_v11n:style')) || 30; 
                            //var icon = doParseStyleValue('icon', ele.data('_v11n:style'));
                            return doParseStyleValue('height', ele.data('_v11n:style'), true, "=", " ");
                        },
                        'shape': memoize('shape', function (ele) {
                            let value = doParseStyleValue('border-shape', ele.data('_v11n:style'))
                                || 'ellipse';
                            switch (value) {
                                case 'arrowleft':
                                case 'arrowright':
                                    value = 'polygon';
                                    break;
                                case 'rhomb':
                                    value = 'diamond';
                                    break;
                                case 'star5':
                                    value = 'star';
                                    break;
                                case 'star6':
                                case 'star8':
                                    value = 'polygon';
                                    break;
                                case 'line':
                                case 'line-oriented':
                                    value = 'polygon';
                                    break;
                            }
                            return value;
                        }),
                        'shape-polygon-points': memoize('shape-polygon-points', function (ele) {
                            let value = doParseStyleValue('border-shape', ele.data('_v11n:style'));
                            switch (value) {
                                case 'arrowright':
                                    return [-0.5, 0, -1, 1, 0.5, 1, 1, 0, 0.5, -1, -1, -1, -0.5, 0];
                                case 'arrowleft':
                                    return [-1, 0, -0.5, 1, 1, 1, 0.5, 0, 1, -1, -0.5, -1, -1, 0];
                                case 'line':
                                    return [-1, 0, 1, 0];
                                case 'line-oriented':
                                    return [-1, 0, -0.2, 0, -0.2, -0.2, 0.3, 0, 1, 0, 0.3, 0, -0.2, 0.2, -0.2, 0, 0.3, 0];
                                case 'star6':
                                    return [-0.5, 0, -0.7, 0.4, -0.3, 0.4, 0, 0.9, 0.3, 0.4, 0.7, 0.4, 0.5, 0, 0.7, -0.4, 0.3, -0.4, 0, -0.9, -0.3, -0.4, -0.7, -0.4, -0.5, 0];
                                case 'star8':
                                    return [-1, 0, -0.4, 0.2, -0.6, 0.6, -0.2, 0.4, 0, 1, 0.2, 0.4, 0.6, 0.6, 0.4, 0.2, 1, 0, 0.4, -0.2, 0.6, -0.6, 0.2, -0.4, 0, -1, -0.2, -0.4, -0.6, -0.6, -0.4, -0.2, -1, 0];
                                default:
                                    return [];
                            }
                        }),
                        'background-color': memoize('background-color', function (ele) {
                            let style = ele.data('_v11n:style');
                            let shape = doParseStyleValue('border-shape', ele.data('_v11n:style'));
                            let value;
                            if (shape == 'line' || shape == 'line-oriented') {
                                value = doParseStyleValue('border-color', style);
                            }
                            else {
                                value = doParseStyleValue('background-color', style)
                                    || doParseStyleValue('border-fill-color', style)
                                    || pSBC(0.8, validTextColour(doParseStyleValue('border-color', style)));
                            }
                            let validColor = validTextColour(value);
                            return validColor ? validColor : 'transparent';
                        }),
                        'background-opacity': memoize('background-opacity', function (ele) {
                            let style = ele.data('_v11n:style');
                            let shape = doParseStyleValue('border-shape', ele.data('_v11n:style'));
                            let value;
                            if (shape == 'line' || shape == 'line-oriented') {
                                value = doParseStyleValue('border-color', style);
                            }
                            else {
                                value = doParseStyleValue('background-color', style)
                                    || doParseStyleValue('border-fill-color', style)
                                    || doParseStyleValue('border-color', style);
                            }
                            let validColor = validTextColour(value);
                            return validColor ? (parseFloat(validColor.split(',')[3]) || 1) : 0;
                        }),
                        'border-color': memoize('border-color', function (ele) {
                            let value = doParseStyleValue('border-color', ele.data('_v11n:style'));
                            let validColor = validTextColour(value);
                            return validColor ? validColor : 'transparent';
                        }),
                        'border-opacity': memoize('border-opacity', function (ele) {
                            let value = doParseStyleValue('border-color', ele.data('_v11n:style'));
                            let validColor = validTextColour(value);
                            return validColor ? (parseFloat(validColor.split(',')[3]) || 1) : 0;
                        }),
                        'border-width': memoize('border-width', function (ele) {
                            let style = ele.data('_v11n:style');
                            let width = doParseStyleValue('border-width', style) || doParseStyleValue('line-width', style);
                            return width || 0;
                        }),
                        'border-style': memoize('border-style', function (ele) {
                            let style = ele.data('_v11n:style');
                            return doParseStyleValue('border-style', style)
                                || doParseStyleValue('line-style', style)
                                || doParseStyleValue('border-linetype', style)
                                || 'solid';
                        }),
                        'background-fit': 'contain',
                        'background-clip': 'none',
                        'padding': memoize('padding', function (ele) {
                            let value = doParseStyleValue('border-color', ele.data('_v11n:style'));
                            let shape = doParseStyleValue('border-shape', ele.data('_v11n:style'))
                            let coef = 28;
                            switch (shape) {
                                case 'star6':
                                case 'star8':
                                    coef = 68;
                                    break;
                            }
                            return validTextColour(value) && !ele.data('shape') ? ((ele.width() || 1) / 100 * coef) : 0;
                        }),
                        'background-width-relative-to': 'inner',
                        'background-height-relative-to': 'inner',
                        'background-image-crossorigin': 'anonymous',
                        'background-image-opacity': memoize('background-image-opacity', function (ele) {
                            return (ele.data('viewType') || "").toLowerCase() == "shape" ? 0 : 1;
                        }),
                        'background-image': memoize('background-image', (ele) => { return getBackgroundImage(ele); }),
                        'text-halign': memoize('text-halign', function (ele) {
                            if (ele.data('labelParameter')) {
                                return 'center';
                            }
                            else {
                                return ele.dataCI('legenditem') ? 'right' : 'center';
                            }
                        }),
                        'text-valign': memoize('text-valign', function (ele) {
                            if (ele.data('labelParameter')) {
                                return 'center';
                            }
                            else {
                                return ele.data('shape') || ele.dataCI('legenditem') ? 'center' : 'bottom';
                            }
                        }),
                        'text-margin-x': memoize('text-margin-x', function (ele) {
                            if (ele.data('labelParameter') && ele._private.rscratch.labelWidth) {
                                let offsetX = parseFloat(ele.data('labelParameter').split(";")[5]);
                                let nodeRatioX = parseFloat(ele.data('labelParameter').split(";")[1]);
                                let labelRatioX = parseFloat(ele.data('labelParameter').split(";")[3]);
                                let margin = halign(ele);
                                switch (margin) {
                                    case 'right':
                                        margin = (ele.width() * nodeRatioX) - (ele._private.rscratch.labelWidth * labelRatioX) + offsetX;
                                        break;
                                    case 'left':
                                        margin = (ele.width() * nodeRatioX) - (ele._private.rscratch.labelWidth * labelRatioX) + offsetX;
                                        break;
                                    default:
                                        margin = offsetX;
                                }
                                return margin;
                            }
                            else {
                                return ele.data('shape') && ele.isChild() ? 5 : 0;
                            }
                        }),
                        'text-margin-y': memoize('text-margin-y', function (ele) {
                            if (ele.data('labelParameter') && ele._private.rscratch.labelHeight) {
                                let offsetY = parseFloat(ele.data('labelParameter').split(";")[6]);
                                let nodeRatioY = parseFloat(ele.data('labelParameter').split(";")[2]);
                                let labelRatioY = parseFloat(ele.data('labelParameter').split(";")[4]);
                                let margin = valign(ele);
                                switch (margin) {
                                    case 'top':
                                        margin = (ele.height() * nodeRatioY) - (ele._private.rscratch.labelHeight * labelRatioY) + offsetY;
                                        break;
                                    case 'bottom':
                                        margin = (ele.height() * nodeRatioY) - (ele._private.rscratch.labelHeight * labelRatioY) + offsetY;
                                        break;
                                    default:
                                        margin = offsetY;
                                }
                                return margin;
                            }
                            else {
                                return ele.dataCI('legenditem') ? 0 : 5;
                            }
                        })
                    }
                }, {
                    selector: 'edge',
                    style: {
                        'curve-style': memoize('curve-style', function (ele) {
                            return ele.scratch('actual-curve-style') || 'straight';
                        }),
                        'target-arrow-color': memoize('target-arrow-color', function (ele) {
                            let style = ele.data('_v11n:style');
                            let value = doParseStyleValue('color', style)
                                || doParseStyleValue('line-color', style);
                            let validColor = validTextColour(value);
                            return validColor ? validColor : '#002168';
                        }),
                        'source-arrow-color': memoize('source-arrow-color', function (ele) {
                            let style = ele.data('_v11n:style');
                            let value = doParseStyleValue('color', style)
                                || doParseStyleValue('line-color', style);
                            let validColor = validTextColour(value);
                            return validColor ? validColor : '#002168';
                        }),
                        'target-arrow-shape': memoize('target-arrow-shape', function (ele) {
                            return ele.dataCI('_v11n:itemtype') === '3' ? 'triangle-backcurve' : 'none';
                        }),
                        'arrow-scale': 1.5,
                        'line-color': memoize('line-color', function (ele) {
                            let style = ele.data('_v11n:style');
                            let value = doParseStyleValue('color', style)
                                || doParseStyleValue('line-color', style);
                            let validColor = validTextColour(value);
                            return validColor ? validColor : '#002168';
                        }),
                        'line-style': memoize('line-style', function (ele) {
                            let value = doParseStyleValue('line-style', ele.data('_v11n:style'));
                            return value ? (value == 'solid' ? 'solid' : 'dashed') : 'solid';
                        }),
                        'line-dash-pattern': memoize('line-dash-pattern', function (ele) {
                            return doParseStyleValue('line-style', ele.data('_v11n:style')) == 'dotted' ?
                                [0, doParseStyleValue('line-width', ele.data('_v11n:style')) * 5 || 5] :
                                [doParseStyleValue('line-width', ele.data('_v11n:style')) * 5 || 10, (doParseStyleValue('line-width', ele.data('_v11n:style')) * 5 || 10) / 2];
                        }),
                        'line-cap': memoize('line-cap', function (ele) {
                            return doParseStyleValue('line-style', ele.data('_v11n:style')) == 'dotted' ? 'round' : 'butt';
                        }),
                        'width': memoize('width', function (ele) {
                            return doParseStyleValue('line-width', ele.data('_v11n:style')) || 1;
                        }),
                        'source-distance-from-node': memoize('source-distance-from-node', function (ele) {
                            let size = Math.max(parseInt(ele.source().style('width')), parseInt(ele.source().style('height')));
                            return Math.round(size * 0.05);
                        }),
                        'target-distance-from-node': memoize('target-distance-from-node', function (ele) {
                            let size = Math.max(parseInt(ele.target().style('width')), parseInt(ele.target().style('height')));
                            return Math.round(size * 0.05);
                        }),
                        'text-margin-x': textMarginX,
                        'text-margin-y': textMarginY
                    }
                }, {
                    selector: ":selected",
                    style: {
                        'text-background-color': '#70b7ff',
                        'text-background-opacity': 1,
                        'text-background-padding': 1,
                        'color': '#FFF',
                        'overlay-color': '#3399FF',
                        'overlay-opacity': memoize('overlay-opacity', function (ele) {
                            return 0.2;
                        }),
                        'z-compound-depth': memoize('z-compound-depth', function (ele) {
                            return ele.isParent() ? 'auto' : 'top';
                        })
                    }
                }, {
                    selector: "node:selected",
                    style: {
                        'text-background-padding': 3
                    }
                }, {
                    selector: ":active",
                    style: {
                        'text-outline-opacity': 0
                    }
                }
            ],
            elements: elements
        });

        cy.cxtmenu({
            selector: 'core',
            commands: [
                {
                    content: ReactDOMServer.renderToString(<Icon>all_out</Icon>),
                    select: function (ele) {
                        var layout = cy.layout({ name: 'concentric', animate: true, padding: 0 });
                        layout.run();
                    }
                },
                {
                    content: ReactDOMServer.renderToString(<Icon>account_tree</Icon>),
                    select: function (ele) {
                        var layout = cy.layout({ name: 'breadthfirst', animate: true, padding: 0 });
                        layout.run();
                    }
                },
                {
                    content: ReactDOMServer.renderToString(<Icon>grid_view</Icon>),
                    select: function (ele) {
                        var layout = cy.layout({ name: 'grid', animate: true, padding: 0 });
                        layout.run();
                    }
                }
            ]
        });

        setCy(cy);
    }

    React.useEffect(() => {
        drawGraph();
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('click', handleClick);
        // Don't forget to clean up
        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('click', handleClick);
        }
    }, [])

    const handleClick = (event) => {
        lastClick = event.target;
        if (lastClick && lastClick.nodeName == "CANVAS") {
            window.getSelection()?.removeAllRanges();
        }
        else {
            cy.elements().unselect();
        }
    }

    const handleKeyDown = (event) => {
        if (lastClick && lastClick.nodeName == "CANVAS") {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if ((event.ctrlKey || event.metaKey) && charCode === 'a') {
                cy.elements().select();
                event.stopPropagation();
                event.preventDefault();
            }
            if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
                copy(JSON.stringify(prepareOutput(jsonData, props.tqlRef, props.editorRef, props.diagramDef, false, cy)));
            }
        }
    }

    return (
        <div ref={graphRef} style={{ width: '100%', height: '50vh' }} tabIndex={0} />
    )
}