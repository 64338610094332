import 'react-app-polyfill/ie11';
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';
import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import theme from './styles/theme'
import './styles/main.css'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { TovekAppContextProvider } from './contexts/AppContext';
import { ToastContainer } from 'react-toastify';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ConfirmProvider } from 'material-ui-confirm';

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                limit={5}
                hideProgressBar={false}
                newestOnTop
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover />
            <TovekAppContextProvider>
                <ConfirmProvider>
                    <CssBaseline />
                    <App />
                </ConfirmProvider>
            </TovekAppContextProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
