import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, IconButton, TextField } from '@mui/material';
import { ISource } from '../../types/IConfiguration';
import SourcesCheckboxes from '../Sources/SourcesCheckboxes';

import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckIcon from '@mui/icons-material/Check';
import { t } from 'i18next';

export type NewUserSourcesDialogProps = {
    open: boolean;
    onClose: (newStep: string) => void;
    sources: any;
    step?: any;
}

export default function NewUserSourcesDialog(props: NewUserSourcesDialogProps) {
    const [newStep, setNewStep] = React.useState<string>("");

    const handleClose = (ok: boolean) => {
        props.onClose(ok ? newStep : "");
        setNewStep("");
    };

    React.useEffect(() => {
        setNewStep(props.step);
    }, [props.step]);

    return (
        <Dialog
            fullWidth={true}
            open={props.open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {t("application.NewUserSourcesDialog.Named sources set")}
            </DialogTitle>
            <DialogContent dividers sx={{ overflow: "hidden" }}>
                <FormControl fullWidth={true} sx={{ pb: 5 }}>
                    <TextField
                        label={t("application.NewUserSourcesDialog.Enter a sources set name")}
                        autoFocus
                        defaultValue={newStep}
                        onChange={(e) => setNewStep(e.target.value)}
                    />
                </FormControl>
                <DialogContentText component="div">
                    <SourcesCheckboxes sources={props.sources} groupBy={(option: ISource) => option.group} stepKey={"NEW"} searchDisabled={true} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <IconButton onClick={() => { setNewStep(""); handleClose(false); }} >
                    <DoDisturbIcon />
                </IconButton >
                <IconButton disabled={newStep == ""} onClick={() => handleClose(true)}>
                    <CheckIcon />
                </IconButton >
            </DialogActions>
        </Dialog >
    );
}
