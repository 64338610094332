import { createTheme } from '@mui/material/styles';

// A custom theme for this app
let theme = createTheme();
theme = createTheme(theme, {
    palette: {
        background: {
            default: "#f3f5f9"
        },
        primary: {
            main: '#32a5c4',
            dark: '#28849d',
            light: '#96d4e4',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#002168',
            contrastText: '#ffffff'
        },
        third: {
            main: '#FF6600 ',
            contrastText: '#ffffff',
            dark: '#cc5200',
            light: '#ff8c40 '
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "white"
                },
                //colorInherit: ""
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.between('xs', 'sm')]: {
                        padding: '0 12px',
                    },
                    [theme.breakpoints.up('md')]: {
                        padding: '0 12px',
                    }
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
            },
        }
    }
});

declare module '@mui/material/styles' {
    interface Palette {
        third: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        third?: PaletteOptions['primary'];
    }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        third: true;
    }
}

export default theme;