import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Icon, IconButton } from '@mui/material';
import { TovekAppContext } from '../../contexts/AppContext';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import { useEffect, useRef } from 'react';
import { t } from 'i18next';
import { toast, Bounce } from 'react-toastify';

export type PromptDialogProps = {
    open: boolean;
    onClose: (succes: boolean, returnValue: string) => void;
    onPrint?: (returnValue: string) => void;
    title: string;
    text?: string;
    fieldLabel?: string;
    fieldValue?: string;
    multiline?: boolean;
    actions?: string[];
    variant?: "filled" | "standard" | "outlined" | undefined;
}

export default function PromptDialog(props: PromptDialogProps) {
    const { utilObjectLocalization } = React.useContext<ITovekAppContext>(TovekAppContext);
    const [promptDialogValue, setPromptDialogValue] = React.useState("");
    const inputEl = useRef(null);

    const handleClose = (succes: boolean) => {
        if (!succes) {
            setPromptDialogValue("");
        }
        props.onClose(succes, promptDialogValue);
        setPromptDialogValue("");
    };

    useEffect(() => {
        setPromptDialogValue(props.fieldValue || "");
    }, [props.open]);

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}>
            <DialogTitle>{utilObjectLocalization(props, "title")}
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true} sx={{ width: 600 }}>
                {props.text &&
                    <DialogContentText>
                        {props.text}
                    </DialogContentText>
                }
                <TextField
                    inputRef={inputEl}
                    multiline={props.multiline}
                    rows={20}
                    autoFocus
                    margin="dense"
                    id="value"
                    label={props.fieldLabel}
                    fullWidth
                    variant={props.variant || "standard"}
                    value={promptDialogValue}
                    onChange={(e) => { setPromptDialogValue(e.target.value) }}
                    onKeyDown={(e) => {
                        if (!props.multiline && e.keyCode == 13) {
                            if (promptDialogValue == "") {
                                handleClose(false);
                            }
                            else {
                                handleClose(true);
                            }
                        }
                    }}
                />
            </DialogContent>

            <DialogActions>
                {props.actions?.indexOf("close") != -1 &&
                    <IconButton onClick={() => handleClose(false)} >
                        <Icon>do_disturb</Icon>
                    </IconButton >
                }
                {props.actions?.indexOf("save") != -1 &&
                    <IconButton disabled={promptDialogValue == ""} onClick={() => handleClose(true)}>
                        <Icon>check</Icon>
                    </IconButton >
                }
                {props.actions?.indexOf("export") != -1 &&
                    <IconButton disabled={promptDialogValue == ""} onClick={() => { if (props.onPrint) { props.onPrint(promptDialogValue) } }}>
                        <Icon>print</Icon>
                    </IconButton >
                }
                {props.actions?.indexOf("copy") != -1 &&
                    <IconButton
                        disabled={promptDialogValue == ""}
                        onClick={async () => {
                            if (promptDialogValue != "") {
                                if ("clipboard" in navigator) {
                                    await navigator.clipboard.writeText(promptDialogValue || "");
                                } else {
                                    if (inputEl) {
                                        (inputEl.current as any).focus();
                                        (inputEl.current as any).select();
                                        document.execCommand("copy");
                                    }
                                }
                                toast(
                                    t("application.SourcesCheckboxes.Copied"),
                                    { type: toast.TYPE.SUCCESS, toastId: "copied", autoClose: 2000, transition: Bounce }
                                );
                            }
                        }}><Icon>content_copy</Icon></IconButton>}
            </DialogActions>
        </Dialog >
    );
}
