import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';
import { toast, cssTransition, Bounce } from 'react-toastify';
import { TovekAppContext } from '../../contexts/AppContext';
import { ISource } from '../../types/IConfiguration';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type SourceFieldCheckboxProps = {
    step: string;
    source: ISource;
    field: string;
}

export default function SourceFieldCheckbox(props: SourceFieldCheckboxProps) {
    const { setSourceDisabledField, isSourceDisabledField } = React.useContext<ITovekAppContext>(TovekAppContext);
    const [checked, setChecked] = React.useState(!isSourceDisabledField(props.source, props.step, props.field));

    const handleChange = (_event: React.SyntheticEvent, checked: boolean) => {
        setChecked(checked);
        setSourceDisabledField(props.source, props.step, props.field, !checked);
    }

    return (
        <Checkbox
            sx={{ pt: 0, pb: 0 }}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={checked}
            onChange={handleChange}
            size="small"
        />
    );
}