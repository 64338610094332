import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IconButton, Box, styled, Tooltip, tooltipClasses, TooltipProps, Typography, ListItem, Link, FilterOptionsState } from '@mui/material';
import { useState } from 'react';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import { ISource } from '../../types/IConfiguration';
import { TovekAppContext } from '../../contexts/AppContext';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import _ from 'lodash';
import { t } from 'i18next';
import { toast, Bounce, cssTransition } from 'react-toastify';
import CustomListboxComponent from './CustomListboxComponent';
import SourceFieldCheckbox from './SourceFieldCheckbox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 380,
        maxHeight: 500,
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
});

type CheckboxesTagsProps = {
    sources: ISource[];
    stepKey: string,
    searchDisabled: boolean | undefined;
}

export default function CheckboxesTags(props: CheckboxesTagsProps) {
    //const [open, setOpen] = useState(true);
    const { config, getSourceSelected, setSourceSelected, hasSourceDisabledFields, utilObjectLocalization } = React.useContext<ITovekAppContext>(TovekAppContext);
    const value = getSourceSelected(props.stepKey);
    const [transition, setTransition] = useState(true);

    function toastForLogin(needeLoginSource: ISource) {
        return (
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
                <Typography variant="h6">{utilObjectLocalization(needeLoginSource, "title")}</Typography>
                {t("application.CheckboxesTags.This resource requires login. Please continue by logging in at ")}
                <Link underline="hover" href={needeLoginSource.login_url} target="_blank">
                    {t("application.CheckboxesTags.login page")}
                </Link>.
            </Typography>
        )
    }

    const handleChange = (_event: React.SyntheticEvent, newValue: ISource[]) => {
        const needeLoginSources = _.filter(_.difference(newValue, value), 'login_url');
        for (const index in needeLoginSources) {
            const toastId = needeLoginSources[index].login_url || "login_url";
            if (toast.isActive(toastId)) {
                toast.update(toastId, {
                    render: toastForLogin(needeLoginSources[index]),
                    transition: transition ? cssTransition({
                        enter: "shake-horizontal",
                        exit: "slide-out-right"
                    }) : cssTransition({
                        enter: "shake-horizontal",
                        exit: "slide-out-right"
                    }),
                    autoClose: 5000
                });
                setTransition(!transition);
            } else {
                toast(
                    toastForLogin(needeLoginSources[index]),
                    {
                        type: toast.TYPE.INFO,
                        toastId: toastId,
                        autoClose: 5000,
                        transition: Bounce
                    }
                );
            }
        }
        setSourceSelected(newValue, props.stepKey);
    }

    //Fokusuje policko pro vyhledavani
    // const inputRef = React.useRef<HTMLInputElement>();
    // React.useEffect(() => {
    //     if (!props.searchDisabled && inputRef.current != undefined) {
    //         inputRef.current.focus();
    //     }
    // }, []);

    function NewlineText(props: { text: any; }) {
        const text = props.text || "";
        return text.split('\n').map((str: any, i: any) => <span key={i} style={i != 0 ? { paddingLeft: 8 } : {}}>{str}<br /></span>);
    }

    let _filteredValues: ISource[] = props.sources;
    const filterOptions = (options: ISource[], state: FilterOptionsState<ISource>) => {
        const results = createFilterOptions<ISource>()(options, state);
        //setFilteredValues(results);
        _filteredValues = results;
        return results;
    };

    return (
        <Box sx={{
            "& .MuiAutocomplete-popperDisablePortal": {
                position: "inherit!important",
                transform: "none!important",
                ".MuiAutocomplete-paper": {
                    boxShadow: "none!important"
                },
                width: "100%!important"
            },
            '& .MuiAutocomplete-groupLabel': {
                width: "fit-content"
            },
            "& .MuiAutocomplete-clearIndicator": {
                visibility: 'visible'
            }
        }}>
            <Autocomplete
                filterOptions={filterOptions}
                limitTags={10}
                disablePortal
                open={true}
                /*onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                openOnFocus*/
                onChange={handleChange}
                multiple
                fullWidth
                id="checkboxes-tags-sources"
                isOptionEqualToValue={(option, value) => option.key === value.key}
                options={props.sources}
                value={value}
                disableCloseOnSelect
                getOptionLabel={(option) => utilObjectLocalization(option, "title")}
                clearText={t("application.CheckboxesTags.Clear")}
                closeText=""
                popupIcon=""
                renderOption={(optionProps, option, { selected }) => {
                    return (
                        <CustomWidthTooltip
                            key={option.key}
                            enterDelay={1000}
                            enterNextDelay={1000}
                            leaveDelay={100}
                            sx={{ position: "fixed!important" }}
                            PopperProps={{
                                modifiers: [
                                    {
                                        name: "offset",
                                        options: {
                                            offset: [0, 5],
                                        },
                                    },
                                ],
                            }}
                            title={
                                <React.Fragment>
                                    {/* <span dangerouslySetInnerHTML={{ __html: utilObjectLocalization(option, "description") || utilObjectLocalization(option, "title") }}/> */}
                                    <Typography variant="h6">{utilObjectLocalization(option, "title")}</Typography>
                                    {option.login_url &&
                                        <Typography variant="subtitle2" sx={{ ml: 1 }}>{t("application.CheckboxesTags.This resource requires login. Please continue by logging in at ")}
                                            <Link underline="hover" href={option.login_url} target="_blank">{t("application.CheckboxesTags.login page")}</Link>.
                                        </Typography>
                                    }
                                    {option.type == "tql" &&
                                        <>
                                            <Typography variant="subtitle1" sx={{ mt: 1 }}>{t("application.CheckboxesTags.TQL File")}</Typography>
                                            <Typography variant="caption" sx={{ ml: 1 }}><Link underline="hover" href={config.tqls_web_path + "/" + option.file} target="_blank">{option.file}</Link></Typography><br />
                                        </>
                                    }
                                    <Typography variant="subtitle1" sx={{ mt: 1 }}>{t("application.CheckboxesTags.Used Fields")}</Typography>
                                    {option.used_fields && (option.used_fields.length == 0 || option.type == "plugin") ?
                                        <Typography variant="subtitle2" sx={{ ml: 1 }}>{t("application.CheckboxesTags.The corresponding fields are currently not filled in, but the resource opens with an empty query")}</Typography>
                                        :
                                        <Typography variant="caption">
                                            {option.used_fields?.map((field: string, index: React.Key | null | undefined) => (
                                                <p key={index}>
                                                    <SourceFieldCheckbox step={props.stepKey} source={option} field={field} />
                                                    {utilObjectLocalization(_.find(config.fields, { key: field }), "title")}
                                                </p>
                                            ))}
                                        </Typography>
                                    }
                                    {(option.description || option.description_url) &&
                                        <>
                                            {option.description &&
                                                <>
                                                    <Typography variant="subtitle1" sx={{ mt: 1 }}>{t("application.CheckboxesTags.Description")}</Typography>
                                                    <Typography variant="caption" sx={{ ml: 1 }}><NewlineText text={utilObjectLocalization(option, "description")} /></Typography>
                                                </>
                                            }
                                            {option.description_url &&
                                                <Typography variant="caption" sx={{ ml: 1 }}><Link underline="hover" href={option.description_url} target="_blank">{option.description_url}</Link></Typography>
                                            }
                                        </>
                                    }

                                    <Typography variant="subtitle1" sx={{ mt: 1 }}>URL</Typography>
                                    <Typography variant="caption" sx={{ ml: 1 }}><Link underline="hover" href={option.url} target="_blank">{option.url}</Link></Typography>
                                </React.Fragment>
                            }
                        >
                            <ListItem {...optionProps} style={{ display: "inline-flex", padding: 6 }} sx={{ width: { lg: "50%", md: "100%" } }} key={option.key}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    //style={{ marginRight: 8 }}
                                    checked={selected}
                                    indeterminate={selected && hasSourceDisabledFields(option, props.stepKey)}
                                    size="small"
                                />
                                {option.type == "plugin" ? "**" : ""} {utilObjectLocalization(option, "title")}
                            </ListItem>
                        </CustomWidthTooltip >
                    )
                }
                }
                renderInput={(params) => (
                    <TextField {...params} label={t("application.CheckboxesTags.Sources")} //inputRef={inputRef} variant="standard"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: <>
                                {value.length == 0 && props.stepKey != "ALL" &&
                                    <IconButton sx={{ mr: -4 }} size='small' title={t("application.CheckboxesTags.Select All")} onClick={(e) => handleChange(e, _filteredValues)}>
                                        <LibraryAddCheckOutlinedIcon fontSize="small" />
                                    </IconButton>
                                }
                                {params.InputProps.endAdornment}
                            </>
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            sx: value.length == 0 ? { color: "rgba(0, 0, 0, 0.3)" } : {}
                        }} />
                )}
                //PerfectScrollbar
                ListboxComponent={CustomListboxComponent}
                ListboxProps={{ style: { maxHeight: "unset", overflow: "auto" } }}
                groupBy={(option) => option.group || ""}
            />
        </Box >
    );
}