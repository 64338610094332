import * as React from 'react'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import {
    usePopupState,
    bindHover,
    bindMenu,
    bindFocus
} from 'material-ui-popup-state/hooks'
import { IconButton, ListItemIcon, Tooltip } from '@mui/material'
import { ICategory, IField } from '../../types/IConfiguration'
import { Icon } from '@mui/material';
import { TovekAppContext } from '../../contexts/AppContext'
import { ITovekAppContext } from '../../types/ITovekContextApp'

import HoverMenu from 'material-ui-popup-state/HoverMenu'
import { debounce } from 'lodash'

type IMenuPopupStateProps = {
    menuId: string,
    menuTitle?: any,
    menuStartIcon?: string,
    menuEndIcon?: string,
    menuitems?: any[],
    action?: any,
    size?: "small" | "medium" | "large" | undefined,
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | "third" | undefined,
    actualValue?: string
}

export default function MenuPopupState(props: IMenuPopupStateProps) {
    const { utilObjectLocalization, createFieldCustom } = React.useContext<ITovekAppContext>(TovekAppContext);
    const popupState = usePopupState({ variant: 'popover', popupId: props.menuId });

    const debouncedHandleMouseLeave = debounce(
        (e) => popupState.onMouseLeave(e), 100
    );
    const handlOnMouseEnter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        debouncedHandleMouseLeave.cancel();
        if (!popupState.isOpen && popupState.anchorEl) {
            // @ts-ignore
            e.currentTarget = popupState.anchorEl;
            e.type = "mouseover";
            popupState.setOpen(true, e);
        }
    };
    const computeStyle = (menuitem: any) => {
        const sx: any = {};
        if (props.actualValue && menuitem.key == props.actualValue) {
            sx.fontWeight = "bold";
        }
        if (menuitem.type && menuitem.type == "custom") {
            sx.fontStyle = "italic";
        }
        return sx;
    };

    /*const reducer = (prevState: any, state: any) => {
        return { open: state.open, value: state.value || "", searchOff: state.searchOff || false };
    };
    const [state, dispatch] = React.useReducer(reducer, { open: false, value: "", searchOff: false });
    const handleClosePromptDialogSet = (succes: boolean, returnValue: string) => {
        if (succes) {
            createFieldCustom("custom", returnValue);
        }
        dispatch({ open: false });
    };*/

    return (
        <div>
            {props.menuTitle ?
                <Button
                    {...bindHover(popupState)}
                    {...bindFocus(popupState)}
                    onMouseLeave={debouncedHandleMouseLeave}
                    startIcon={props.menuStartIcon && <Icon>{props.menuStartIcon}</Icon>}
                    endIcon={props.menuEndIcon && <Icon>{props.menuEndIcon}</Icon>}
                    size={props.size}
                    color={props.color}
                >
                    {props.menuTitle}
                </Button>
                :
                <IconButton
                    size={props.size}
                    {...bindHover(popupState)}
                    {...bindFocus(popupState)}
                    onMouseLeave={debouncedHandleMouseLeave}
                >
                    <Icon>{props.menuStartIcon}</Icon>
                </IconButton>
            }

            <HoverMenu
                {...bindMenu(popupState)}
                onMouseEnter={handlOnMouseEnter}>
                {props.menuitems?.map((menuitem: any) => (
                    //<Tooltip key={menuitem.key || menuitem.title} title={utilObjectLocalization(menuitem, "title")} placement="left" disableInteractive>
                    <MenuItem
                        key={menuitem.key || menuitem.title}
                        onClick={(e) => {
                            if (!e.ctrlKey) {
                                popupState.close();
                            }
                            props.action(menuitem)
                        }}
                        sx={computeStyle(menuitem)}>
                        {menuitem.icon &&
                            <ListItemIcon sx={props.actualValue && menuitem.key == props.actualValue ? { color: "inherit" } : {}}>
                                <Icon fontSize="small">{menuitem.icon}</Icon>
                            </ListItemIcon>
                        }
                        {utilObjectLocalization(menuitem, "title") || menuitem.key}
                    </MenuItem>
                    //</Tooltip>
                ))}
            </HoverMenu>
        </div >
    )
}