import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import GTranslateIcon from '@mui/icons-material/GTranslate';
import { DialogContent, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import useLocalStorage from '../../controller/useLocalStorage';
import i18next, { t } from 'i18next';
import { ILang } from '../../types/IConfiguration';
import { TovekAppContext } from '../../contexts/AppContext';
import { ITovekAppContext } from '../../types/ITovekContextApp';

export type LangDialogProps = {
    open: boolean;
    onClose: () => void;
}

const defaultLangs = [
    { name: "Čeština", code: 'cs' },
    { name: "English", code: 'en' }
];

export default function LangDialog(props: LangDialogProps) {
    const { config } = React.useContext<ITovekAppContext>(TovekAppContext);

    const { open, onClose } = props;

    const languages: ILang[] = config.languages || defaultLangs;

    const [lang, setLang] = useLocalStorage("lang", languages[0].code);

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value: string) => {
        i18next.changeLanguage(value, () => { setLang(value); onClose(); });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{t('application.LangDialog.Set language')}</DialogTitle>
            <DialogContent dividers={true}>
                <List sx={{ pt: 0 }}>
                    {languages.map((language) => (
                        <ListItemButton onClick={() => handleListItemClick(language.code)} key={language.code}>
                            <ListItemIcon>
                                <GTranslateIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={language.name} />
                        </ListItemButton>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
}
