import * as React from 'react';
import { MuiTelInput, matchIsValidTel, MuiTelInputInfo } from "mui-tel-input";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { TovekAppContext } from '../../contexts/AppContext';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormHelperText, Input } from '@mui/material';

interface FormSectionPhoneNumberProps {
    inputLabelProps: any;
    InputPropsEndAdornment: any;
    helperText: any;
    openNote: any;
    index: any;
    field: any;
    fieldValue: any;
    fieldNote: any;
    fieldUrl: any;
    fieldLabel: any;
    fieldSearchOff: any;
    isUsedField: any;
}

export default function FormSectionPhoneNumber(props: FormSectionPhoneNumberProps) {
    const { setFieldData, isNotesVisible, utilObjectLocalization } = useContext<ITovekAppContext>(TovekAppContext);

    const [value, setValue] = useState(props.fieldValue);
    const [error, setError] = useState(false);
    const [info, setInfo] = useState<MuiTelInputInfo>();

    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (props.fieldValue != value && inputRef.current) {
            inputRef.current.focus();
        }
        if (inputRef.current) {
            if (!/^\+/.test(inputRef.current.value)) {
                validate(inputRef.current.value);
            }
            else {
                setError(inputRef.current.value != "" && !matchIsValidTel(inputRef.current.value));
            }
        }
    }, [value]);

    const handleChange = (newValue: string, info: MuiTelInputInfo) => {
        if (inputRef.current && !/^\+/.test(inputRef.current.value)) {
            handleChangeSimple(inputRef.current.value);
        }
        else {
            setValue(newValue);
            setInfo(info);
            setError(newValue != "" && !matchIsValidTel(newValue));
        }
    };

    const handleChangeSimple = (newValue: string) => {
        setValue(newValue);
        validate(newValue);
    };

    function validate(newValue: string) {
        let computedError = false;
        if (newValue.length != 0) {
            if (props.field.validation) {
                computedError = newValue.match(props.field.validation.regex) == null;
            } else {
                if (/^[0-9# -]+$/.test(newValue)) {
                    computedError = false;
                }
                else if (/^\+/.test(newValue) && matchIsValidTel(newValue)) {
                    computedError = false;
                }
                else {
                    computedError = true;
                }
            }
        }
        setError(computedError);
    }

    return (<FormControl
        required={props.isUsedField()}
        variant="standard"
        disabled={props.fieldSearchOff}
        fullWidth={true}>
        <InputLabel
            htmlFor="formatted-text-mask-input"
            shrink={value ? true : false}
            sx={props.inputLabelProps()?.sx}>
            {
                utilObjectLocalization(props.field, "title")
            }
            {
                //value != "" && !/^\+/.test(value) && " (+### ### ### ###)"
            }
        </InputLabel>
        {!props.field.validation && value.indexOf("+") == 0 ?
            <MuiTelInput
                inputRef={inputRef}
                sx={{
                    mt: 2,
                    "& .MuiInputAdornment-positionStart": /*value ? {} :*/ { display: 'none' }
                }}
                InputProps={{
                    sx: {
                        ".MuiInputBase-input": {
                            marginRight: '30px'
                        }
                    },
                    endAdornment: <props.InputPropsEndAdornment />
                }}
                variant="standard"
                disableDropdown={true}
                value={value}
                onChange={handleChange}
                error={error}
                onBlur={(e) => setFieldData(props.field.key, props.index, e.target.value)}
                title={utilObjectLocalization(props.field, "tooltip", '')}
            /> :
            <Input
                inputRef={inputRef}
                type="tel"
                error={error}
                value={value}
                onChange={(e) => {
                    handleChangeSimple(e.target.value);
                }}
                name="textmask"
                id="formatted-text-mask-input"
                endAdornment={<props.InputPropsEndAdornment />}
                onBlur={(e) => setFieldData(props.field.key, props.index, e.target.value)}
                title={utilObjectLocalization(props.field, "tooltip", '')}
                sx={{
                    ".MuiInputBase-input": {
                        marginRight: '30px'
                    }
                }}
            />
        }
        <FormHelperText onDoubleClick={props.openNote}
            sx={isNotesVisible ? { minHeight: 16 } : { minHeight: 8 }}>
            {props.helperText}
        </FormHelperText>
    </FormControl>
    );
}
