import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, Button, createTheme, Grid, Icon, IconButton, Paper, styled, useMediaQuery } from '@mui/material';
import FormTextFields from './Form/FormTextFields';
import VerticalTabs from './Sources/VerticalTabs';
import HorizontalTabs from './Sources/HorizontalTabs';
import PerfectScrollbar from 'react-perfect-scrollbar';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    margin: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const Content: React.FC = () => {

    const [value, setValue] = React.useState('form');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const minSize = useMediaQuery('(min-width:600px)', { noSsr: true });

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} sx={{ width: "100%", ml: 0 }}>
                <Grid item sm={12} md={7} sx={{
                    overflow: "hidden", p: "0!important",
                    height: "calc( 100vh - 64px )",
                    [theme.breakpoints.down('md')]: { height: "calc( 100vh - 112px )", display: value == "search" ? "none" : "" }
                }}>
                    <PerfectScrollbar>
                        <FormTextFields />
                    </PerfectScrollbar>
                </Grid>
                <Grid item sm={12} md={5} sx={{
                    minHeight: "350px", overflow: "hidden", p: "0!important",
                    height: "calc( 100vh - 64px )",
                    [theme.breakpoints.down('md')]: { height: "calc( 100vh - 134px )", display: value == "form" ? "none" : "" },
                    [theme.breakpoints.down('xs')]: { height: "calc( 100vh - 112px )" }
                }}>
                    <Item sx={{ my: 3, ml: 1.5, mr: 3 }}>
                        {minSize ?
                            <VerticalTabs />
                            :
                            <HorizontalTabs />
                        }
                    </Item>
                </Grid>
            </Grid>
            <Paper className="bottom-navigation"
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, [theme.breakpoints.up('md')]: { display: "none" } }} elevation={3}>
                <BottomNavigation
                    value={value} onChange={handleChange}
                >
                    <BottomNavigationAction label="search" value="search" icon={<Icon>search</Icon>} />
                    <BottomNavigationAction label="form" value="form" icon={<Icon>assignment</Icon>} />
                </BottomNavigation>
            </Paper>
        </Box >
    )
}

export default Content
