import React, { useReducer, useState } from 'react';
import _ from 'lodash';
import { Button, Link, Stack, Tooltip } from '@mui/material';
import CheckboxesTags from './CheckboxesTags';
import { TovekAppContext } from '../../contexts/AppContext';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import { AxiosResponse } from 'axios';
import ConfigService from '../../services/config';
import { ISource } from '../../types/IConfiguration';
import PromptDialog from '../Dialogs/PromptDialog';
import { Bounce, cssTransition, toast } from 'react-toastify';
import i18next, { t } from 'i18next';
import Bowser from "bowser";
import useLocalStorage from '../../controller/useLocalStorage';

type SourcesCheckboxesProps = {
    sources: ISource[];
    afterSearched?: () => void;
    groupBy?: (option: ISource) => string | undefined;
    stepKey: string;
    searchDisabled?: boolean | undefined;
}

export default function SourcesCheckboxes(props: SourcesCheckboxesProps) {
    const { category, preparedDataForSend, getSourceSelected, utilObjectLocalization } = React.useContext<ITovekAppContext>(TovekAppContext);

    const [firstTime, setFirstTime] = useLocalStorage<boolean>("firstTime", true);

    const reducer = (prevState: any, state: any) => {
        return { open: state.open, value: state.value || "" };
    };
    const [state, dispatch] = useReducer(reducer, { open: false, value: "" });
    const handleClosePromptDialogSet = () => {
        dispatch({ open: false });
    };

    const formCall = (path: string, params: any, method: "POST" | "GET", source: ISource | undefined) => {
        const form = document.createElement('form');
        form.className = "hidden";
        form.method = method;
        form.target = "_blank";
        form.action = path;

        if (source?.encoding) {
            form.acceptCharset = source.encoding;
        }

        for (const key in params) {
            //if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
            //}
        }

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    const [transition, setTransition] = useState(true);

    function getRequests(defaultConfig?: any) {
        ConfigService.getRequests(category, props.stepKey, JSON.stringify(preparedDataForSend(category, props.stepKey)), defaultConfig)
            .then((response: AxiosResponse<any, string>) => {
                let textMessage = t("application.SourcesCheckboxes.Searched, number of queries") + ": " + (response.data.requests.length - _.filter(response.data.requests, { type: "ERROR" }).length);
                if (response.data.requests.length == 0) {
                    textMessage = t("application.SourcesCheckboxes.Please fill in at least one field that uses the selected resources.")
                }
                toast.update("searched", {
                    render: textMessage,
                    type: toast.TYPE.INFO, toastId: "searched", autoClose: false, transition: Bounce, icon: null
                });
                if (firstTime && response.data.requests.length > 1) {
                    let browserHelp = Bowser.getParser(window.navigator.userAgent).getBrowserName();
                    switch (browserHelp) {
                        case 'Microsoft Edge':
                            browserHelp = i18next.language == "cs" ? "https://support.microsoft.com/cs-cz/microsoft-edge/blokov%C3%A1n%C3%AD-automaticky-otev%C3%ADran%C3%BDch-oken-v-microsoft-edgi-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5" : "https://support.microsoft.com/en-us/microsoft-edge/block-pop-ups-in-microsoft-edge-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5";
                            break;
                        case 'Firefox':
                            browserHelp = i18next.language == "cs" ? "https://support.mozilla.org/cs/kb/Blokov%C3%A1n%C3%AD%20vyskakovac%C3%ADch%20oken" : "https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting";
                            break;
                        case 'Chrome':
                            browserHelp = i18next.language == "cs" ? "https://support.google.com/chrome/answer/95472?hl=cs&co=GENIE.Platform%3DDesktop" : "https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop";
                            break;
                        default:
                            browserHelp = "";
                    }
                    toast(
                        <span style={{ wordBreak: "break-word" }}>
                            {t("application.SourcesCheckboxes.You have started the search for the very first time, it is possible that not all the bookmarks you searched for will open due to the blocking of pop-up windows by the browser. You need to enable this popup.")}
                            {browserHelp &&
                                <>
                                    <br /><br />
                                    <Link href={browserHelp} target="_blank">{t("application.SourcesCheckboxes.How to do it")}</Link>
                                </>
                            }
                        </span>,
                        { type: toast.TYPE.WARNING, toastId: "firstTime", autoClose: false, transition: Bounce, onClose: () => setFirstTime(false) }
                    );
                }
                response.data.requests.forEach((request: any) => {
                    switch (request.type) {
                        case "ERROR":
                            {
                                console.log(request.url);
                                const source = _.find(getSourceSelected(), { key: request.key });
                                toast(
                                    <span style={{ wordBreak: "break-word" }}>
                                        {utilObjectLocalization(source, "title")}
                                        <br /><br />
                                        {
                                            t("application.SourcesCheckboxes.Search failed, probably an error in the fields used, in the configuration of the resource or the resource itself.")
                                        }
                                        <br /><br />
                                        <Link href={source?.url} target="_blank">{t("application.SourcesCheckboxes.Open the source with empty query")}</Link>
                                    </span >,
                                    { type: toast.TYPE.ERROR, toastId: "error" + request.key, autoClose: false, transition: Bounce }
                                );
                            }
                            break;
                        case "POST":
                            formCall(request.url, request.data, "POST", _.find(getSourceSelected(), { key: request.key }));
                            break;
                        case "GET_params":
                            window.open(request.url, "_blank");
                            break;
                        case "tql":
                            if (request.url.indexOf("tovek-tools://") == 0 && request.url.length > 1020) {
                                let query = request.url;
                                const source = _.find(getSourceSelected(), { key: request.key });
                                if (source && source.url) {
                                    const beginUrlLength = source.url.substring(0, source.url.indexOf("#{qry}")).length;
                                    const endUrlLength = source.url.substring(source.url.indexOf("#{qry}") + "#{qry}".length).length;
                                    query = query.substring(beginUrlLength, query.length - endUrlLength);
                                }
                                dispatch({ open: true, value: decodeURIComponent(query) });
                            }
                            else {
                                window.open(request.url, "_blank");
                            }
                            break;
                        default:
                            window.open(request.url, "_blank");
                    }
                });
            })
            .catch((e: any) => {
                ConfigService.check401(e);
                toast.update("searched", {
                    render: ConfigService.getErrorText(e),
                    type: toast.TYPE.ERROR, toastId: "searched", autoClose: false, transition: Bounce, icon: null
                });
            });
    }

    return (< Stack spacing={2} sx={{ pl: props.searchDisabled ? 0 : 3 }}>
        {!props.searchDisabled &&
            <>
                <Button
                    color="third"
                    variant="contained"
                    size="large"
                    onClick={() => {
                        if (toast.isActive("searched")) {
                            toast.update("searched", {
                                render: t("application.SourcesCheckboxes.Searching"),
                                transition: transition ? cssTransition({
                                    enter: "shake-horizontal",
                                    exit: "slide-out-right"
                                }) : cssTransition({
                                    enter: "shake-horizontal",
                                    exit: "slide-out-right"
                                }),
                                icon: <div className="Toastify__spinner"></div>
                            });
                            setTransition(!transition);
                        } else {
                            toast(
                                t("application.SourcesCheckboxes.Searching"),
                                { toastId: "searched", autoClose: false, transition: Bounce, icon: <div className="Toastify__spinner"></div> }
                            );
                        }
                        if (localStorage.getItem("noconfig") == "true") {
                            fetch("./config/minimal.json")
                                .then((res) => res.json()).then((defaultConfig) => getRequests(defaultConfig));
                        } else {
                            getRequests();
                        }

                        if (props.afterSearched != undefined) {
                            props.afterSearched();
                        }
                    }}
                >{t("application.SourcesCheckboxes.Search")}</Button>
                <PromptDialog
                    title={t("application.SourcesCheckboxes.Export")}
                    text={t("application.SourcesCheckboxes.The query is too long to open in Tovek Tools (due to browser limitations), please copy the query to Tovek Tools manually")}
                    fieldValue={state.value}
                    multiline
                    open={state.open}
                    onClose={handleClosePromptDialogSet}
                    variant="filled"
                    actions={["copy"]}
                />
            </>
        }
        <CheckboxesTags sources={props.sources} stepKey={props.stepKey} searchDisabled={props.searchDisabled} />
    </Stack >
    );
}