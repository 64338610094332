import React from 'react'
import i18next from 'i18next'
import { Typography } from '@mui/material'

function Copyright() {
        return (
                <Typography variant="caption" align="left">
                        {'Copyright © '}
                        <a color="inherit" href="https://tovek.cz/">
                                tovek.cz
                        </a>{' '}
                        {new Date().getFullYear()}
                        <Typography variant="caption" sx={{ float: "right" }}>
                                app &quot;{process.env.REACT_APP_VERSION}&quot;; conf {localStorage.getItem('lastConfigVersion')}
                        </Typography>
                </Typography>
        )
}

const Help: React.FC = () => {
        const helpPath: string = './help/TF-UserGuide_' + i18next.language + '.pdf?v=' + process.env.REACT_APP_VERSION

        function urlExists(url: string | URL) {
                const http = new XMLHttpRequest()
                http.open('HEAD', url, false)
                http.send()
                return http.status != 404
        }

        return (
                <>
                        <embed id="tovek-pdfhelp" src={urlExists(helpPath) ? helpPath : './help/TF-UserGuide_en.pdf?v=' + process.env.REACT_APP_VERSION} style={{ height: '100%', width: '100%' }} />
                        <Copyright />
                </>
        )
}

export default Help
