import React, { useContext, useEffect } from 'react'
import { TovekAppContext } from '../contexts/AppContext';
import { ITovekAppContext } from '../types/ITovekContextApp';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Container, Divider, Icon, IconButton, Stack, SvgIcon, TextField, Tooltip, Typography, styled } from '@mui/material';

import mansvg from '../resources/images/icons/v11n/person/man.svg';
import womansvg from '../resources/images/icons/v11n/person/woman.svg';
import companysvg from '../resources/images/icons/v11n/place/company.svg';
import appointmentsvg from '../resources/images/icons/v11n/action/appointment.svg';
import documentsvg from '../resources/images/icons/v11n/object/document.svg';

import _ from 'lodash';
import SwipeableImageList from '../components/Images/SwipeableImageList';
import { t } from 'i18next';

import saveAs from 'file-saver';
import printjs from 'print-js';

import $ from 'jquery';
import dayjs from 'dayjs';
import Cytoscape, { prepareOutput } from '../components/Reports/Cytoscape';
import { IFieldData } from '../types/IFieldData';
import { IReport } from '../types/IConfiguration';
import { Editor } from '@tinymce/tinymce-react';
import imageCompression from 'browser-image-compression';
import i18n from '../i18n/config';

import ConfigService from '../services/config';
import { AxiosResponse } from 'axios';
import { Bounce, toast } from 'react-toastify';

import JSZip from 'jszip';

type ReportProps = {
    category: string;
}

export default function Report(props: ReportProps) {
    const { setDownload, download, config, data, lastConfigVersion, getDataFields, category, preparedReportDataForSend, computeAllFieldsTxt, prepareDiagram, getFieldData, setCategory, utilObjectLocalization, setNeedBackdrop, prepareDisplayFields } = useContext<ITovekAppContext>(TovekAppContext);

    const [isReady, setIsReady] = React.useState<any>();

    function _getRequest(findedReportDef: IReport, key: string, defaultConfig?: any) {
        const prep = preparedReportDataForSend(findedReportDef);
        return ConfigService.getReports(category, JSON.stringify(prep), defaultConfig)
            .then((response: AxiosResponse<any, string>) => {
                let report = "";
                _.forEach(response.data.requests, (request) => {
                    if (request.url.match(/^-\d+$/)) {
                        report = report.slice(0, -"\n".length);
                        report = report.slice(0, request.url);
                        report += "\n";
                    }
                    else {
                        report += request.url + "\n";
                    }
                });
                return ({ key: key, data: report });
            })
            .catch((e: Error) => {
                console.log(e);
                ConfigService.check401(e);
                toast(
                    <span style={{ wordBreak: "break-word" }}>
                        {t("application.Report.Part of Report failed")}:
                        <br />
                        {utilObjectLocalization(findedReportDef, "title")}
                    </span >,
                    { type: toast.TYPE.ERROR, toastId: "error" + key, autoClose: false, transition: Bounce }
                );
                return ({ key: key, data: "" });
            });
    }

    function getRequests(findedReportDef: IReport, key: string) {
        if (localStorage.getItem("noconfig") == "true") {
            return fetch("./config/minimal.json").then((res) => res.json()).then((defaultConfig) => _getRequest(findedReportDef, key, defaultConfig))
        }
        else {
            return _getRequest(findedReportDef, key);
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    useEffect(() => {
        if (download) {
            if (download == "all") {
                const zip = new JSZip();

                const imageData: IFieldData = getDataFields().image;
                const img = zip.folder("images");
                if (imageData) {
                    const values = imageData.values || [];
                    const labels = imageData.labels || [];

                    const usedLabels: string[] = [];
                    values?.map((imageValue: string | undefined, index: any) => {
                        if (imageValue) {
                            let label = labels[index] || "";
                            const filename = label.substring(0, label.lastIndexOf("."));
                            const extension = label.substring(label.lastIndexOf(".") + 1);
                            label = usedLabels.some((obj) => obj.toLowerCase() === (labels[index] || "").toLowerCase()) ? (filename + "_" + index + "." + extension) : (labels[index] || "");
                            if (label) {
                                usedLabels.push(label);
                                img?.file(label, imageValue.substring(imageValue.indexOf("base64,") + 7), { base64: true });
                            }
                        }
                    });
                }

                const savedData = JSON.parse(JSON.stringify(data));
                savedData.version = lastConfigVersion;
                zip.file("TovekFinderData_" + dayjs().format('DD-MM-YYYY') + ".json", new Blob([JSON.stringify(savedData)], { type: "application/json" }));

                // @ts-ignore
                zip.file("AllFields_" + dayjs().format('DD-MM-YYYY') + ".txt", editorRef.current.getContent({ format: 'text' }) as string);

                // @ts-ignore
                zip.file("AllFields_" + dayjs().format('DD-MM-YYYY') + ".html", new Blob([editorRef.current.getContent() as string], { type: "text/html" }));

                // @ts-ignore
                zip.file("Query_" + dayjs().format('DD-MM-YYYY') + ".tql", new Blob([$("#Query-TextField").val() as string], { type: "text/plain;charset=utf-8" }));

                const header: string[] = [];
                const csvData: string[] = [];
                prepareDisplayFields().map((dispField: { config: any; data: { values: any[]; }; }) => {
                    if (dispField) {
                        header.push(utilObjectLocalization(dispField.config, "title"));
                        csvData.push("\"" + dispField.data.values.join(", ") + "\"");
                    }
                })
                // @ts-ignore
                zip.file("Summary_" + dayjs().format('DD-MM-YYYY') + ".csv", new Blob([header.join(",") + "\n" + csvData.join(",")], { type: 'text/csv;charset=utf-8' }));

                const diagramInJSON = prepareOutput(isReady.preparedDiagram, tqlRef, editorRef, isReady.diagramDef, true);
                zip.file("IndexableJsonData_" + dayjs().format('DD-MM-YYYY') + ".json", new Blob([JSON.stringify(diagramInJSON)], { type: "application/json" }));

                const canvas = document.getElementsByTagName("canvas");
                const canvasDiagram = canvas[canvas.length - 1];
                if (canvasDiagram) {
                    // @ts-ignore
                    canvasDiagram.toBlob(async function (blob: Blob) {
                        zip.file("Diagram_" + dayjs().format('DD-MM-YYYY') + ".png", blob);
                    });
                }

                isReady.reports.map((reportKey: string) => {
                    // @ts-ignore
                    const findedReportDef: IReport = _.find(config.reports, { "key": reportKey }) || { type: "err" };
                    if (findedReportDef) {
                        if (findedReportDef.type == "txt") {
                            zip.file(utilObjectLocalization(findedReportDef, "title") + "_" + dayjs().format('DD-MM-YYYY') + "." + findedReportDef.ext, $("#" + reportKey + "-TextField").val() as string);
                        }
                    }
                });

                zip.generateAsync({ type: "blob" })
                    .then(function (content) {
                        saveAs(content, "TovekFinderReport_" + dayjs().format('DD-MM-YYYY') + ".zip");
                    });
            }
            else if (download == "indexableData") {
                const diagramInJSON = prepareOutput(isReady.preparedDiagram, tqlRef, editorRef, isReady.diagramDef, true);
                const blob = new Blob([JSON.stringify(diagramInJSON)], { type: "application/json" });
                saveAs(blob, "TovekFinder-IndexableJsonData_" + dayjs().format('DD-MM-YYYY') + ".json");
            }
            setDownload("");
        }
    }, [download]);

    useEffect(() => {
        setNeedBackdrop(true);
        if (category != "all") {
            setCategory(props.category);
        }
        setTimeout(() => {
            const ajaxArray: Promise<any>[] = [];
            const reportsObject: any = {};
            const reports = _.find(config.categories, { "key": props.category })?.reports || [];
            reports.map((reportKey) => {
                // @ts-ignore
                const findedReportDef: IReport = _.find(config.reports, { "key": reportKey }) || { type: "err" };
                if (findedReportDef) {
                    if (findedReportDef.type == "categoryFields") {
                        ajaxArray.push(new Promise<any>((resolve, reject) => {
                            resolve({ key: "preparedDisplayFields", data: prepareDisplayFields() });
                        }));
                    }
                    if (findedReportDef.type == "allFields") {
                        ajaxArray.push(new Promise<any>((resolve, reject) => {
                            resolve({ key: "computedAllFieldsTxt", data: computeAllFieldsTxt() });
                        }));
                    }
                    if (findedReportDef.type == "tql") {
                        ajaxArray.push(getRequests(findedReportDef, "computedBaseTQL"));
                    }
                    if (findedReportDef.type == "diagram") {
                        ajaxArray.push(new Promise<any>((resolve, reject) => {
                            resolve({ key: "preparedDiagram", data: prepareDiagram(findedReportDef), diagramDef: findedReportDef });
                        }));
                    }
                    if (findedReportDef.type == "txt") {
                        ajaxArray.push(getRequests(findedReportDef, reportKey));
                    }
                }
            });

            Promise.all(ajaxArray).then((values) => {
                _.forEach(values, (value) => {
                    reportsObject[value.key] = value.data;
                    if (value.diagramDef) {
                        reportsObject["diagramDef"] = value.diagramDef;
                    }
                });
                reportsObject.reports = reports;
                setIsReady(reportsObject);
                setNeedBackdrop(false);
            });

        }, 300);
    }, []);

    function categoryImage() {
        //TODO load default image from config
        switch (category) {
            case "company":
                return companysvg;
            case "event":
                return appointmentsvg;
            case "people": {
                const sex = getDataFields()["sex"]?.values ? getDataFields()["sex"]?.values[0] : "m";
                return sex != "w" ? mansvg : womansvg;
            }
            default:
                return documentsvg
        }
    }

    function hasImages(): boolean {
        const imageData: IFieldData = JSON.parse(JSON.stringify(getFieldData("image")));
        return imageData?.values[0] ? true : false;
    }

    function prepareImages(): IFieldData {
        const imageData: IFieldData = JSON.parse(JSON.stringify(getFieldData("image")));
        const hasImages = imageData?.values[0] ? true : false;
        if (!hasImages) {
            imageData.labels = [utilObjectLocalization(_.find(config.categories, { "key": category }), "title")];
            imageData.values = [categoryImage()];
        }
        return imageData?.values[0] ? imageData : { labels: [], values: [], notes: [], searchOff: [] } as IFieldData;
    }

    const [collapsedCard, setCollapsedCard] = React.useState<boolean>(true);
    const [collapsedCards, setCollapsedCards] = React.useState<any>({});

    function setCollapse(id: string) {
        const updatedValue: any = {};
        updatedValue[id] = !collapsedCards[id];
        setCollapsedCards((collapsedCards: any) => ({
            ...collapsedCards,
            ...updatedValue
        }));
    }

    function prepareSummary() {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2} sx={{ p: 5 }}>
                        <SwipeableImageList images={prepareImages() || {}} withCard={false} />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container spacing={2}>
                            {
                                isReady.preparedDisplayFields.map((field: any, index: any) => (
                                    field == null ?
                                        <Grid item xs={12} key={index}></Grid>
                                        :
                                        <Grid item xs={12} md={6} key={index} sx={{ overflowWrap: "anywhere" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4} sx={{ textAlign: "initial" }}>
                                                    <Typography variant="button" component="div" sx={{ textTransform: 'uppercase', fontWeight: "bold" }}>
                                                        {utilObjectLocalization(field.config, "title")}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8} sx={{ textAlign: "initial" }}>
                                                    {field.data.values.map((value: string, index2: any) => (
                                                        <span key={index2}>
                                                            <Typography variant="body1">
                                                                {value}
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                {field.data.notes[index2].value}
                                                            </Typography>
                                                        </span>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                )
                                )
                            }
                        </Grid>
                    </Grid >
                </Grid>
            </>
        )
    }

    function blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result + "");
            reader.readAsDataURL(blob);
        });
    }

    const callbackRef = React.useRef();
    function registerCallback(callback: any) {
        callbackRef.current = callback;
    }

    const editorRef = React.useRef<any>(null);

    const tqlRef = React.useRef<any>(null);

    return (<>
        {isReady &&
            <Container sx={{ py: "16px!important", flex: "1 1 auto" }} className="report" maxWidth={false}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                {isReady.preparedDisplayFields &&
                                    <Card>
                                        <CardHeader
                                            title={t("application.Report.Summary")}
                                            titleTypographyProps={{
                                                sx: {
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden'
                                                },
                                                variant: "h5"
                                            }}
                                            action={
                                                <IconButton
                                                    onClick={() => setCollapse("preparedDisplayFields")}
                                                    aria-label="expand"
                                                    size="small"
                                                    className='no-print'
                                                >
                                                    {!collapsedCards.preparedDisplayFields ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>}
                                                </IconButton>
                                            }>
                                        </CardHeader >
                                        <Collapse in={!collapsedCards.preparedDisplayFields} timeout="auto" unmountOnExit>
                                            <Divider variant="middle" />
                                            <CardContent id="tovekSummary">
                                                {prepareSummary()}
                                            </CardContent>
                                            <Divider variant="middle" className="no-print" />
                                            <CardActions className="no-print" sx={{
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}>
                                                <Tooltip title={t("application.Report.Print")} disableInteractive>
                                                    <IconButton size="small" onClick={() => {
                                                        //printjs('tovekSummary', 'html');
                                                        const winPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                                                        if (winPrint) {
                                                            $(winPrint.document.body).append($("style").clone());
                                                            $(winPrint.document.body).append($("#tovekSummary").clone());
                                                            winPrint.document.close();
                                                            winPrint.focus();
                                                            winPrint.print();
                                                            winPrint.close();
                                                        }
                                                    }}>
                                                        <Icon>print</Icon>
                                                    </IconButton >
                                                </Tooltip>
                                                <Tooltip title={t("application.Report.Download csv")} disableInteractive>
                                                    <IconButton size="small" onClick={() => {
                                                        const header: string[] = [];
                                                        const csvData: string[] = [];
                                                        prepareDisplayFields().map((dispField: { config: any; data: { values: any[]; }; }) => {
                                                            if (dispField) {
                                                                header.push(utilObjectLocalization(dispField.config, "title"));
                                                                csvData.push("\"" + dispField.data.values.join(", ") + "\"");
                                                            }
                                                        })
                                                        const blob = new Blob([header.join(",") + "\n" + csvData.join(",")], { type: 'text/csv;charset=utf-8' });
                                                        saveAs(blob, "TovekFinder-Summary_" + dayjs().format('DD-MM-YYYY') + ".csv");
                                                    }}>
                                                        <Icon>download</Icon>
                                                    </IconButton >
                                                </Tooltip>
                                            </CardActions>
                                        </Collapse>
                                    </Card>
                                }
                                {isReady.computedAllFieldsTxt &&
                                    <Card>
                                        <CardHeader
                                            title={t("application.Report.All Fields")}
                                            titleTypographyProps={{
                                                sx: {
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden'
                                                },
                                                variant: "h5"
                                            }}
                                            action={
                                                <IconButton
                                                    onClick={() => setCollapse("computedAllFieldsTxt")}
                                                    aria-label="expand"
                                                    size="small"
                                                    className='no-print'
                                                >
                                                    {!collapsedCards.computedAllFieldsTxt ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>}
                                                </IconButton>
                                            }>
                                        </CardHeader >
                                        <Collapse in={!collapsedCards.computedAllFieldsTxt} timeout="auto">
                                            <Divider variant="middle" />
                                            <CardContent id="AllFields" sx={{ py: 0 }}>
                                                <Editor
                                                    id={"AllFields-TextField"}
                                                    //@ts-ignore                                       
                                                    onInit={(evt, editor) => editorRef.current = editor}
                                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                    initialValue={isReady.computedAllFieldsTxt}
                                                    init={{
                                                        entity_encoding: 'raw',
                                                        statusbar: false,
                                                        language: i18n.language,
                                                        menubar: true,
                                                        plugins: [
                                                            'autoresize',
                                                            'preview', 'importcss', 'searchreplace', 'autolink', 'save', 'directionality', 'code', 'visualblocks', 'visualchars',
                                                            'fullscreen', 'image', 'link', 'media', 'template', 'codesample', 'table', 'charmap', 'pagebreak', 'nonbreaking',
                                                            'anchor', 'insertdatetime', 'advlist', 'lists', 'wordcount', 'charmap', 'quickbars', 'emoticons', 'accordion'
                                                        ],
                                                        toolbar: "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | print | pagebreak anchor codesample | ltr rtl",
                                                        quickbars_insert_toolbar: false,
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } img { max-width: 100% } sup { font-size: x-small; }',
                                                        images_upload_handler: (blobInfo) => new Promise((resolve) => {
                                                            imageCompression(blobInfo.blob() as File, {
                                                                maxSizeMB: 204800 / 1024 / 1024,
                                                                maxWidthOrHeight: 1024,
                                                                useWebWorker: true
                                                            }).then(function (compressedFile) {
                                                                blobToBase64(compressedFile).then(function (base64) {
                                                                    resolve(base64);
                                                                });
                                                            })
                                                                .catch(function (error) {
                                                                    console.log(error.message);
                                                                });
                                                        }),
                                                        /*setup: (editor) => {
                                                            editor.on('beforeunload', (e) => {
                                                            });
                                                        }*/
                                                    }}
                                                />
                                            </CardContent>
                                            <Divider variant="middle" className="no-print" />
                                            <CardActions className="no-print" sx={{
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}>
                                                <Tooltip title={t("application.Report.Print")} disableInteractive>
                                                    <IconButton size="small" onClick={() => {
                                                        editorRef.current.execCommand("mcePrint");
                                                        /*const winPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                                                        if (winPrint) {
                                                            winPrint.document.write("<pre>" + $("#AllFields-TextField").val() + "</pre>");
                                                            winPrint.document.close();
                                                            winPrint.focus();
                                                            winPrint.print();
                                                            winPrint.close();
                                                        }*/
                                                    }}>
                                                        <Icon>print</Icon>
                                                    </IconButton >
                                                </Tooltip>
                                                {true &&
                                                    <>
                                                        <Tooltip title={t("application.Report.Download html report")} disableInteractive>
                                                            <IconButton size="small" onClick={() => {
                                                                const blob = new Blob([editorRef.current.getContent() as string], { type: "text/html;charset=utf-8" });
                                                                saveAs(blob, "TovekFinder-AllFields_" + dayjs().format('DD-MM-YYYY') + ".html");
                                                            }}>
                                                                <Icon>archive</Icon>
                                                            </IconButton >
                                                        </Tooltip>
                                                        <Tooltip title={t("application.Report.Upload html report")} disableInteractive>
                                                            <IconButton size='small'
                                                                component="label"
                                                                tabIndex={-1}
                                                                sx={{ ml: "8px" }}>
                                                                <Icon>unarchive</Icon>
                                                                <VisuallyHiddenInput type="file" accept="text/html" onChange={(e) => {
                                                                    if (e.target.files && e.target.files[0]) {
                                                                        const file = e.target.files[0];
                                                                        file.text().then((content) => {
                                                                            editorRef.current.setContent(content);
                                                                        });
                                                                    }
                                                                }} />
                                                            </IconButton >
                                                        </Tooltip>
                                                    </>}
                                                <Tooltip title={t("application.Report.Download txt")} disableInteractive>
                                                    <IconButton size="small" onClick={() => {
                                                        const blob = new Blob([editorRef.current.getContent({ format: 'text' })], { type: "text/plain;charset=utf-8" });
                                                        saveAs(blob, "TovekFinder-AllFields_" + dayjs().format('DD-MM-YYYY') + ".txt");
                                                    }}>
                                                        <Icon>download</Icon>
                                                    </IconButton >
                                                </Tooltip>
                                            </CardActions>
                                        </Collapse>
                                    </Card>
                                }
                                {isReady.computedBaseTQL &&
                                    <Card>
                                        <CardHeader
                                            title={t("application.Report.Tovek Query")}
                                            titleTypographyProps={{
                                                sx: {
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden'
                                                },
                                                variant: "h5"
                                            }}
                                            action={
                                                <IconButton
                                                    onClick={() => setCollapse("computedBaseTQL")}
                                                    aria-label="expand"
                                                    size="small"
                                                    className='no-print'
                                                >
                                                    {!collapsedCards.computedBaseTQL ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>}
                                                </IconButton>
                                            }>
                                        </CardHeader >
                                        <Collapse in={!collapsedCards.computedBaseTQL} timeout="auto" >
                                            <Divider variant="middle" />
                                            <CardContent id="Query" sx={{ py: 0 }}>
                                                <TextField
                                                    inputRef={tqlRef}
                                                    id={"Query-TextField"}
                                                    multiline
                                                    margin="dense"
                                                    label={""}
                                                    fullWidth
                                                    variant={"standard"}
                                                    defaultValue={isReady.computedBaseTQL}
                                                    InputProps={{ disableUnderline: true }}
                                                />
                                            </CardContent>
                                            <Divider variant="middle" className="no-print" />
                                            <CardActions className="no-print" sx={{
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}>
                                                <Tooltip title={t("application.Report.Print")} disableInteractive>
                                                    <IconButton size="small" onClick={() => {
                                                        const winPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                                                        if (winPrint) {
                                                            winPrint.document.write("<pre>" + $("#Query-TextField").val() + "</pre>");
                                                            winPrint.document.close();
                                                            winPrint.focus();
                                                            winPrint.print();
                                                            winPrint.close();
                                                        }
                                                    }}>
                                                        <Icon>print</Icon>
                                                    </IconButton >
                                                </Tooltip>
                                                <Tooltip title={t("application.Report.Open in TT")} disableInteractive>
                                                    <IconButton size="small" onClick={() => { window.open("tovek-tools://search?query=" + $("#Query-TextField").val(), "_blank"); }}>
                                                        <SvgIcon>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <g transform="matrix(.009561 0 0 -.009561 0 24.47616)" fill="#757575">
                                                                    <path d="m1095 2526c-153-25-310-80-435-151-206-117-396-317-502-530-157-312-176-688-52-1008 36-94 92-207 103-207 4 0 51 45 105 99l99 100-32 71c-161 362-70 810 223 1087 85 81 156 130 268 183 143 69 218 84 408 84 150 0 173-3 255-27 346-103 602-367 691-712 23-92 25-112 21-260-5-189-22-257-97-410-129-261-360-446-648-517-69-18-115-22-227-21-163 0-276 25-409 88l-79 37-101-101c-116-117-119-101 29-175 196-99 335-131 570-130 191 0 281 16 442 76 381 144 677 481 774 882 98 410-17 847-304 1152-184 195-392 314-657 375-112 25-336 33-445 15z"></path>
                                                                    <path d="m1565 1699c-88-21-326-77-530-124-203-47-378-89-388-93-14-6 66-90 408-432 233-233 426-422 428-420 3 3 59 246 126 540s124 543 127 553c6 21 22 23-171-24z"></path>
                                                                </g>
                                                            </svg>
                                                        </SvgIcon>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={t("application.Report.Download TQL") + ""} disableInteractive>
                                                    <IconButton size="small" onClick={() => {
                                                        const blob = new Blob([$("#Query-TextField").val() as string], { type: "text/plain;charset=utf-8" });
                                                        saveAs(blob, "TovekFinder-Query_" + dayjs().format('DD-MM-YYYY') + ".tql");
                                                    }}>
                                                        <Icon>download</Icon>
                                                    </IconButton >
                                                </Tooltip>
                                            </CardActions>
                                        </Collapse>
                                    </Card>
                                }
                                {isReady.preparedDiagram &&
                                    <Card>
                                        <CardHeader
                                            title={t("application.Report.Tovek Diagram")}
                                            titleTypographyProps={{
                                                sx: {
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden'
                                                },
                                                variant: "h5"
                                            }}
                                            action={
                                                <IconButton
                                                    onClick={() => setCollapse("preparedDiagram")}
                                                    aria-label="expand"
                                                    size="small"
                                                    className='no-print'
                                                >
                                                    {!collapsedCards.preparedDiagram ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>}
                                                </IconButton>
                                            }>
                                        </CardHeader >
                                        <Collapse in={!collapsedCards.preparedDiagram} timeout="auto" unmountOnExit>
                                            <CardContent id="tovekDiagram" sx={{ py: 0 }}>
                                                <Cytoscape jsonData={isReady.preparedDiagram} tqlRef={tqlRef} editorRef={editorRef} diagramDef={isReady.diagramDef} registerCallback={registerCallback} />
                                            </CardContent>
                                            <CardActions className="no-print" sx={{
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}>
                                                <Tooltip title={t("application.Report.Print") + ""} disableInteractive>
                                                    <IconButton size="small" onClick={() => {
                                                        if (callbackRef && callbackRef?.current) {
                                                            //@ts-ignore
                                                            blobToBase64(callbackRef.current()).then((res) => {
                                                                printjs(res, 'image');
                                                            });
                                                        }
                                                        else {
                                                            const canvas = document.getElementsByTagName("canvas");
                                                            const canvasDiagram = canvas[canvas.length - 1];
                                                            if (canvasDiagram) {
                                                                canvasDiagram.toBlob(async (blob) => {
                                                                    // @ts-ignore
                                                                    printjs(await blobToBase64(blob), 'image');
                                                                });
                                                            }
                                                        }
                                                    }}>
                                                        <Icon>print</Icon>
                                                    </IconButton >
                                                </Tooltip>
                                                <Tooltip title={t("application.Report.Download Image") + ""} disableInteractive>
                                                    <IconButton size="small" onClick={() => {
                                                        if (callbackRef && callbackRef?.current) {
                                                            //@ts-ignore
                                                            saveAs(callbackRef?.current(), "TovekFinder-Diagram_" + dayjs().format('DD-MM-YYYY') + ".png");
                                                        }
                                                        else {
                                                            const canvas = document.getElementsByTagName("canvas");
                                                            const canvasDiagram = canvas[canvas.length - 1];
                                                            if (canvasDiagram) {
                                                                canvasDiagram.toBlob((blob) => {
                                                                    saveAs(blob as Blob, "TovekFinder-Diagram_" + dayjs().format('DD-MM-YYYY') + ".png");
                                                                });
                                                            }
                                                        }
                                                    }}>
                                                        <Icon>image</Icon>
                                                    </IconButton >
                                                </Tooltip>
                                                <Tooltip title={t("application.Report.Download JSON") + ""} disableInteractive>
                                                    <IconButton size="small" onClick={() => {
                                                        const diagramInJSON = prepareOutput(isReady.preparedDiagram, tqlRef, editorRef, isReady.diagramDef, true);
                                                        const blob = new Blob([JSON.stringify(diagramInJSON)], { type: "application/json" });
                                                        saveAs(blob, "TovekFinder-IndexableJsonData_" + dayjs().format('DD-MM-YYYY') + ".json");
                                                    }}>
                                                        <Icon>download</Icon>
                                                    </IconButton >
                                                </Tooltip>
                                            </CardActions>
                                        </Collapse>
                                    </Card>
                                }
                                {
                                    isReady.reports.map((reportKey: string) => {
                                        // @ts-ignore
                                        const findedReportDef: IReport = _.find(config.reports, { "key": reportKey }) || { type: "err" };
                                        if (findedReportDef) {
                                            if (findedReportDef.type == "txt") {
                                                return (
                                                    <Card key={reportKey} className={findedReportDef.no_print ? 'no-print' : ''}>
                                                        <CardHeader
                                                            title={utilObjectLocalization(findedReportDef, "title")}
                                                            titleTypographyProps={{
                                                                sx: {
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden'
                                                                },
                                                                variant: "h5"
                                                            }}
                                                            action={
                                                                <IconButton
                                                                    onClick={() => setCollapse(reportKey)}
                                                                    aria-label="expand"
                                                                    size="small"
                                                                    className='no-print'
                                                                >
                                                                    {!collapsedCards[reportKey] ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>}
                                                                </IconButton>
                                                            }>
                                                        </CardHeader >
                                                        <Divider variant="middle" />
                                                        <Collapse in={!collapsedCards[reportKey]} timeout="auto" unmountOnExit>
                                                            <CardContent id={reportKey} sx={{ py: 0 }}>
                                                                <TextField
                                                                    id={reportKey + "-TextField"}
                                                                    multiline
                                                                    margin="dense"
                                                                    label={""}
                                                                    fullWidth
                                                                    variant={"standard"}
                                                                    defaultValue={isReady[reportKey]}
                                                                    InputProps={{ disableUnderline: true }}
                                                                />
                                                            </CardContent>
                                                            <Divider variant="middle" className="no-print" />
                                                            <CardActions className="no-print" sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-end"
                                                            }}>
                                                                <Tooltip title={t("application.Report.Download") + ((" " + findedReportDef.ext) || "")} disableInteractive>
                                                                    <IconButton size="small" onClick={() => {
                                                                        const blob = new Blob([$("#" + reportKey + "-TextField").val() as string], { type: "text/plain;charset=utf-8" });
                                                                        saveAs(blob, utilObjectLocalization(findedReportDef, "title") + "_" + dayjs().format('DD-MM-YYYY') + "." + findedReportDef.ext);
                                                                    }}>
                                                                        <Icon>download</Icon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </CardActions>
                                                        </Collapse>
                                                    </Card>)
                                            }
                                        }
                                    })
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Container >
        }
    </>
    );
}