export type INoteData = {
    value: string | undefined;
    img?: string | undefined;
    timestamp?: string | undefined;
}

function newINoteData(value?: string, img?: string, timestamp?: string) {
    const newINoteData: INoteData = { value: value || "" };
    if (img) {
        newINoteData.img = img;
    } if (timestamp) {
        newINoteData.timestamp = timestamp;
    }
    return newINoteData;
}

export { newINoteData }