import React, { useEffect, useState } from "react";
import { TextField, InputAdornment, IconButton, Grid, Grow, Tooltip, Typography, Icon, Link, Box, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { TovekAppContext } from "../../contexts/AppContext";
import { IField } from "../../types/IConfiguration";
import { ITovekAppContext } from "../../types/ITovekContextApp";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SearchIcon from '@mui/icons-material/Search';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '@mui/icons-material/Star';
import _ from "lodash";
import Dropzone, { FileWithPath } from "react-dropzone";
import FileUploadIcon from '@mui/icons-material/FileUpload';

import FormSectionPhoneNumber from './FormSectionPhoneNumber';
import FormSectionDate, { defaultDateFormat } from './FormSectionDate';
import { debounce } from "lodash";

import { t } from 'i18next';

import $ from 'jquery';
import imageCompression from 'browser-image-compression';
import { toast } from "react-toastify";

import { useConfirm } from "material-ui-confirm";

import { INoteData, newINoteData } from "../../types/INoteData";
import FormSectionHelperText from "./FormSectionHelperText";

import { Editor } from '@tinymce/tinymce-react';
import i18n from "../../i18n/config";
import moment from "moment";

type FormSectionProps = {
    field: IField;
    index: React.Key | number;
}

export default function FormSection(props: FormSectionProps) {
    const [isNoteVisible, setNoteVisible] = useState<boolean>(false);
    const { config, startBatch, shiftFieldData, getFieldData, setFieldData, removeField, isNotesVisible, getSourceSelected, setFieldImagesData, step, utilObjectLocalization, setNeedBackdrop } = React.useContext<ITovekAppContext>(TovekAppContext);

    const muiConfirm = useConfirm();

    const [isValidateError, setValidateError] = useState<boolean>(false);

    const fieldData = getFieldData(props.field.key);
    const index: number = parseInt(props.index.valueOf() + "", 10);

    const fieldValue = fieldData.values[index];
    const fieldNote: INoteData | undefined = fieldData.notes[index];
    const fieldUrl = fieldData.urls ? fieldData.urls[index] : "";
    const fieldLabel = fieldData.labels ? (fieldData.labels[index] || utilObjectLocalization(props.field, "title")) : utilObjectLocalization(props.field, "title");
    const fieldSearchOff = fieldData.searchOff[index];

    const openNote = () => {
        setNoteVisible(true);
    }

    const closeNote = (value: string) => {
        if (value != null) {
            let newUpdateFieldNote = fieldNote;
            if (!newUpdateFieldNote) {
                newUpdateFieldNote = newINoteData();
            }
            newUpdateFieldNote.value = value;
            setFieldData(props.field.key, index, undefined, newUpdateFieldNote);
        }
        setNoteVisible(false);
    }

    const isUsedField = () => {
        const selectedSources = getSourceSelected(step);
        for (const sourceIndex in selectedSources) {
            if (_.indexOf(selectedSources[sourceIndex].used_fields, props.field.key) != -1) {
                return true;
            }
        }
        return false;
    }

    function inputLabelProps() {
        const props = { sx: {} };
        //let props = { shrink: true };

        if (fieldValue == "" || fieldValue == null) {
            _.extend(props.sx, { color: "rgba(0, 0, 0, 0.3)" });
        }

        //_.extend(props.sx, isRequiredField() ? { fontStyle: "italic" } : (isUsedField() ? { fontStyle: "italic" } : undefined));

        return props;
    }

    function InputPropsEndAdornment() {
        const [isHovered, setIsHovered] = useState(false);
        const [isFocused, setIsFocused] = useState(false);
        const debouncedHandleMouseEnter = debounce(() => setIsHovered(true), 250);
        const debouncedHandleBlur = debounce(() => { setIsFocused(false); setIsHovered(false); }, 250);
        const handlOnMouseLeave = () => {
            if (!isFocused) {
                setIsHovered(false);
                debouncedHandleMouseEnter.cancel();
            }
        };

        return (
            <InputAdornment sx={{ position: "absolute", right: 0, backgroundColor: "white", height: "80%" }} position="end" className='appear-item'
                onMouseOver={debouncedHandleMouseEnter}
                onMouseLeave={handlOnMouseLeave}
                onFocus={() => { setIsFocused(true); setIsHovered(true); }}
                onBlur={debouncedHandleBlur}
            >
                <Grow unmountOnExit
                    in={isHovered}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(isHovered ? { timeout: 1000 } : {})}
                >
                    <Tooltip title={t("application.FormSection.Remove") + ""} disableInteractive>
                        <IconButton size='small'
                            onClick={() => removeField(props.field.key, index)}
                            edge="end"
                            tabIndex={-1}
                        >
                            <DeleteOutlinedIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </Grow>
                <Grow unmountOnExit
                    in={isHovered}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(isHovered ? { timeout: 500 } : {})}
                >
                    {isNoteVisible ?
                        <Tooltip title={t("application.FormSection.Hide Note") + ""} disableInteractive>
                            <IconButton size='small'
                                onClick={(e) => closeNote(e.currentTarget?.value)}
                                edge="end"
                                tabIndex={-1}
                            >
                                <StickyNote2Icon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title={t("application.FormSection.Open Note") + ""} disableInteractive>
                            <IconButton size='small'
                                onClick={openNote}
                                edge="end"
                                tabIndex={-1}
                            >
                                <StickyNote2OutlinedIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    }
                </Grow>
                <Grow unmountOnExit
                    in={isHovered}
                >
                    <Tooltip title={fieldSearchOff ?
                        t("application.FormSection.Enable for search") + "" :
                        t("application.FormSection.Disable for search") + ""
                    } disableInteractive>
                        <IconButton size='small'
                            onClick={() => setFieldData(props.field.key, index, undefined, undefined, !fieldSearchOff)}
                            edge="end"
                            tabIndex={-1}
                        >
                            {fieldSearchOff ?
                                <SearchIcon fontSize='small' /> :
                                <SearchOffIcon fontSize='small' />
                            }
                        </IconButton>
                    </Tooltip>
                </Grow>
                {
                    props.field.type == "image" && props.index > 0 &&
                    < Grow unmountOnExit
                        in={isHovered}
                    >
                        <Tooltip title={t("application.FormSection.Set as main image") + ""} disableInteractive>
                            <IconButton size='small'
                                onClick={() => shiftFieldData("image", index)}
                                edge="end"
                                tabIndex={-1}
                            >
                                <StarIcon fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </Grow>
                }
                <IconButton size='small'
                    onClick={() => setIsHovered(true)}
                    edge="end"
                    tabIndex={-1}
                >
                    <MoreVertIcon fontSize='small' />
                </IconButton>
            </InputAdornment >
        );
    }

    function validate(newValue: string, onchange: boolean) {
        if (newValue.length != 0) {
            if (props.field.validation) {
                setValidateError(newValue.match(props.field.validation.regex) == null);
                // const error = e.target.value.match(props.field.validation.regex) == null;
                // if (onchange) {
                //     if (!error) {
                //         setValidateError(false);
                //     }
                // }
                // else {
                //     setValidateError(error);
                // }
            }
        }
        else {
            setValidateError(false);
        }
    }

    function prepareUrl(str: string) {
        try {
            const u = new URL(str);
            return str;
        } catch (e) {
            return '//' + str;
        }
    }

    useEffect(() => {
        $(".separator").each(function () {
            const allNextSiblings = $(this).nextAll();
            if (allNextSiblings.length == 0 || !allNextSiblings.is(":not(:empty)")) {
                $(this).hide();
            }
            else {
                $(this).show();
            }
        });
    });

    const maxImageSizeByte = 204800;
    async function handleImageUpload(imageFile: File) {
        const options = {
            maxSizeMB: maxImageSizeByte / 1024 / 1024,
            maxWidthOrHeight: 1024,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.log(error);
        }
    }

    function readAsDataURL(file: Blob) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onerror = reject;
            fr.onload = () => {
                resolve({ file: file, value: fr.result });
            }
            fr.readAsDataURL(file);
        });
    }

    function blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result + "");
            reader.readAsDataURL(blob);
        });
    }

    return (
        <>
            {props.field.type == "separator" ?
                <Grid item xs={12} sm={12} md={12} lg={12} className="separator" sx={utilObjectLocalization(props.field, "title", "") ? {} : { pt: "0px!important" }}>
                    <Typography variant="subtitle2" color="secondary">{utilObjectLocalization(props.field, "title", "")}</Typography>
                </Grid >
                :
                <Grid item xs={12} sm={(props.field.width || 3) * 2} md={(props.field.width || 3) * 2} lg={props.field.width || 3} className={props.field.key}>
                    {fieldValue != undefined &&
                        <>
                            {props.field.type == "phone" &&
                                <FormSectionPhoneNumber
                                    key={fieldValue} //very important!!! make react rerender defaultValue
                                    inputLabelProps={inputLabelProps}
                                    InputPropsEndAdornment={InputPropsEndAdornment}
                                    helperText={<FormSectionHelperText openNote={openNote} index={index} field={props.field} fieldNote={fieldNote} isNoteVisible={isNoteVisible} closeNote={closeNote} />}
                                    openNote={openNote}
                                    index={index}
                                    field={props.field}
                                    fieldValue={fieldValue}
                                    fieldNote={fieldNote}
                                    fieldUrl={fieldUrl}
                                    fieldLabel={fieldLabel}
                                    fieldSearchOff={fieldSearchOff}
                                    isUsedField={isUsedField}
                                />
                            }
                            {props.field.type == "date" &&
                                <FormSectionDate
                                    key={fieldValue} //very important!!! make react rerender defaultValue
                                    inputLabelProps={inputLabelProps}
                                    InputPropsEndAdornment={InputPropsEndAdornment}
                                    helperText={<FormSectionHelperText openNote={openNote} index={index} field={props.field} fieldNote={fieldNote} isNoteVisible={isNoteVisible} closeNote={closeNote} />}
                                    openNote={openNote}
                                    index={index}
                                    field={props.field}
                                    fieldValue={fieldValue}
                                    fieldNote={fieldNote}
                                    fieldUrl={fieldUrl}
                                    fieldLabel={fieldLabel}
                                    fieldSearchOff={fieldSearchOff}
                                    isUsedField={isUsedField}
                                />
                            }
                            {props.field.type == "image" &&
                                <>
                                    {fieldValue ?
                                        <>
                                            <img
                                                src={fieldValue}
                                                alt={fieldNote?.value || ""}
                                                style={{ objectFit: "contain", height: 200, width: "100%", opacity: fieldSearchOff ? 0.38 : undefined }}
                                            />
                                            <TextField
                                                required={isUsedField()}
                                                disabled={fieldSearchOff}
                                                variant="standard"
                                                fullWidth={true}
                                                //label={fieldLabel}
                                                value={fieldUrl}
                                                FormHelperTextProps={{
                                                    // @ts-ignore
                                                    component: "div",
                                                    onDoubleClick: openNote,
                                                    sx: isNotesVisible ? { minHeight: 16 } : { minHeight: 8 }
                                                }}
                                                helperText={<FormSectionHelperText openNote={openNote} index={index} field={props.field} fieldNote={fieldNote} isNoteVisible={isNoteVisible} closeNote={closeNote} />}
                                                InputProps={{
                                                    sx: {
                                                        cursor: 'default',
                                                        ".MuiInputBase-input": {
                                                            cursor: 'default',
                                                            marginRight: '30px'
                                                        }
                                                    },
                                                    readOnly: true,
                                                    endAdornment:
                                                        <InputPropsEndAdornment />
                                                }}
                                                InputLabelProps={inputLabelProps()}
                                            />
                                        </>
                                        :
                                        <Dropzone
                                            onDrop={async (acceptedFiles: FileWithPath[], fileRejections, e) => {
                                                try {
                                                    setNeedBackdrop(true);
                                                    const tooLargeFiles: File[] = [];
                                                    fileRejections.map((fileRejection) => {
                                                        if (fileRejection.errors.length == 1 && _.find(fileRejection.errors, { code: 'file-too-large' }))
                                                            tooLargeFiles.push(fileRejection.file);
                                                    });
                                                    if (tooLargeFiles.length > 0) {
                                                        await Promise.all(tooLargeFiles.map(async (tooLargeFile) => {
                                                            if (confirm("\n" + tooLargeFile.name + "\n\n" + t("application.FormSection.The image is too large, do you want to upload it compressed?")) == true) {
                                                                const rejectedFile = (tooLargeFile as FileWithPath);
                                                                const compressedFile: FileWithPath = _.extend(await handleImageUpload(rejectedFile), { path: rejectedFile.path });
                                                                acceptedFiles.push(compressedFile);
                                                            }
                                                        }));
                                                    }
                                                    if (acceptedFiles.length == 0) {
                                                        setNeedBackdrop(false);
                                                    } else {
                                                        // @ts-ignore
                                                        const path = acceptedFiles.length == 1 ? (e && e.dataTransfer ? e.dataTransfer.getData("URL") : "") : "";
                                                        const loadedImages: any[] = [];
                                                        Promise.all(acceptedFiles.map.call(acceptedFiles, readAsDataURL))
                                                            .then((loadedFiles: any) => {
                                                                loadedFiles.map((loadedFile: { value: any; file: { name: any; path: any; }; }, acceptedFileIndex: number) => {
                                                                    const base64 = loadedFile.value;
                                                                    const image = {
                                                                        index: index + acceptedFileIndex,
                                                                        value: typeof base64 === 'string' ? base64 : "",
                                                                        label: loadedFile.file.name,
                                                                        url: path || loadedFile.file.path || "",
                                                                        note: "",
                                                                        searchOff: false
                                                                    }
                                                                    loadedImages.push(image);
                                                                    if (loadedImages.length == acceptedFiles.length) {
                                                                        setFieldImagesData(props.field.key, loadedImages);
                                                                        setNeedBackdrop(false);
                                                                    }
                                                                });
                                                            })
                                                            .catch(error => {
                                                                console.error(error);
                                                                setNeedBackdrop(false);
                                                                toast(
                                                                    t("application.FormSection.Failed to load images."),
                                                                    { type: toast.TYPE.ERROR }
                                                                );
                                                            });
                                                    }
                                                } catch (e) {
                                                    setNeedBackdrop(false);
                                                }
                                            }}
                                            maxSize={maxImageSizeByte}
                                            accept={{ 'image/*': [] }}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps({ className: 'dropzone' })} style={{ height: 254 }}>
                                                    <input {...getInputProps()} />
                                                    <p style={{ textAlign: "center" }}>{t("application.FormSection.Drag and drop image file here or click")}</p>
                                                    <FileUploadIcon />
                                                </div>
                                            )}
                                        </Dropzone>
                                    }
                                </>
                            }
                            {props.field.type == "description" &&
                                <Editor
                                    key={fieldValue}
                                    //@ts-ignore                                       
                                    //onInit={(evt, editor) => editorRef.current = editor}
                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                    initialValue={fieldValue}
                                    init={{
                                        entity_encoding: 'raw',
                                        statusbar: false,
                                        language: i18n.language,
                                        menubar: true,
                                        plugins: [
                                            'autoresize',
                                            'preview', 'importcss', 'searchreplace', 'autolink', 'save', 'directionality', 'code', 'visualblocks', 'visualchars',
                                            'fullscreen', 'image', 'link', 'media', 'template', 'codesample', 'table', 'charmap', 'pagebreak', 'nonbreaking',
                                            'anchor', 'insertdatetime', 'advlist', 'lists', 'wordcount', 'charmap', 'quickbars', 'emoticons', 'accordion'
                                        ],
                                        toolbar: "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
                                        quickbars_insert_toolbar: false,
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } img { max-width: 100% }',
                                        images_upload_handler: (blobInfo) => new Promise((resolve) => {
                                            imageCompression(blobInfo.blob() as File, {
                                                maxSizeMB: 204800 / 1024 / 1024,
                                                maxWidthOrHeight: 1024,
                                                useWebWorker: true
                                            }).then(function (compressedFile) {
                                                blobToBase64(compressedFile).then(function (base64) {
                                                    resolve(base64);
                                                });
                                            })
                                                .catch(function (error) {
                                                    console.log(error.message);
                                                });
                                        })
                                    }}
                                    onBlur={function (e) { setTimeout(() => setFieldData(props.field.key, index, e.target.getContent()), 0) }}
                                />
                            }
                            {props.field.type != "date" && props.field.type != "phone" && props.field.type != "image" && props.field.type != "description" &&
                                // <Tooltip
                                //     key={fieldValue} //very important!!! make react rerender defaultValue
                                //     title={utilObjectLocalization(props.field, "tooltip", '')}>
                                <TextField
                                    key={fieldValue} //very important!!! make react rerender defaultValue
                                    autoComplete="new-password"
                                    type={props.field.type}
                                    error={isValidateError}
                                    multiline={props.field.multiline}
                                    required={isUsedField()}
                                    disabled={fieldSearchOff}
                                    variant="standard"
                                    fullWidth={true}
                                    label={fieldLabel + (isValidateError && props.field.validation?.title ? (" (" + utilObjectLocalization(props.field.validation, "title") + ")") : "")}
                                    defaultValue={fieldValue}
                                    onChange={(e) => { validate(e.target.value, true); }}
                                    onBlur={(e) => { setFieldData(props.field.key, index, e.target.value) }}
                                    FormHelperTextProps={{
                                        // @ts-ignore
                                        component: "div",
                                        onDoubleClick: openNote,
                                        sx: isNotesVisible ? { minHeight: 16 } : { minHeight: 8 }
                                    }}
                                    helperText={<FormSectionHelperText openNote={openNote} index={index} field={props.field} fieldNote={fieldNote} isNoteVisible={isNoteVisible} closeNote={closeNote} />}
                                    InputProps={{
                                        sx: {
                                            ".MuiInputBase-input": {
                                                marginRight: '30px'
                                            }
                                        },
                                        startAdornment: props.field.type == "url" && fieldValue != undefined && fieldValue != "" ?
                                            <InputAdornment position="start">
                                                <Link href={prepareUrl(fieldValue)} target="_blank">
                                                    <IconButton size='small'
                                                        tabIndex={-1}
                                                    >
                                                        <Icon fontSize='small'>link</Icon>
                                                    </IconButton>
                                                </Link>
                                            </InputAdornment> : null,
                                        endAdornment: <InputPropsEndAdornment />
                                    }}
                                    InputLabelProps={inputLabelProps()}
                                    title={utilObjectLocalization(props.field, "tooltip", '')}
                                    onPaste={e => {
                                        const paste_parses = props.field.paste_parses;
                                        if (paste_parses) {
                                            const clipboardText = e.clipboardData.getData("text");
                                            const content: JSX.Element[] = [];
                                            const uniqueValues: any[] = [];
                                            _.forEach(paste_parses, (paste_parse_def, paste_parse_def_index) => {
                                                const regexStr = paste_parse_def.regex;
                                                const fields = paste_parse_def.fields;
                                                if (regexStr && fields) {
                                                    const regex = new RegExp(regexStr, 'gm');
                                                    const m = regex.exec(clipboardText);
                                                    if (m != undefined && m.filter((n: any) => n).length > 2) {
                                                        const textFields: JSX.Element[] = [];
                                                        const uniqueValue: any = {};
                                                        m.forEach((match: any, matchIndex: number) => {
                                                            if (matchIndex != 0) {
                                                                const fieldKey = fields[matchIndex - 1];
                                                                let fieldValue = match || "";
                                                                const findedConfigField = _.find(config.fields, { key: fieldKey });
                                                                if (findedConfigField) {
                                                                    if (findedConfigField.type == "number") {
                                                                        fieldValue = fieldValue.replaceAll(/\s/g, '');
                                                                    }
                                                                    for (const paste_date_format of (findedConfigField.paste_date_formats || [])) {
                                                                        const momentDate = moment(fieldValue, paste_date_format.format, paste_date_format.locale, paste_date_format.strict || true);
                                                                        if (momentDate.isValid()) {
                                                                            fieldValue = (props.field.key, props.index, momentDate.format(findedConfigField.format || defaultDateFormat));
                                                                            e.preventDefault();
                                                                            break;
                                                                        }
                                                                    }
                                                                    uniqueValue[fieldKey] = fieldValue;
                                                                    textFields.push(
                                                                        <TextField
                                                                            variant="standard"
                                                                            label={utilObjectLocalization((_.find(config.fields, { key: fieldKey }) || { title: fieldKey }), "title")}
                                                                            defaultValue={fieldValue}
                                                                            id={paste_parse_def_index + "_" + fieldKey}
                                                                        />
                                                                    );
                                                                }
                                                            }
                                                        });
                                                        if (!_.some(uniqueValues, uniqueValue)) {
                                                            uniqueValues.push(uniqueValue);
                                                            content.push(
                                                                <FormControlLabel
                                                                    value={paste_parse_def_index}
                                                                    control={<Radio />}
                                                                    labelPlacement="end"
                                                                    label={<Box
                                                                        key={paste_parse_def_index}
                                                                        component="form"
                                                                        sx={{
                                                                            '& > :not(style)': { m: 2, width: '25ch' },
                                                                        }}
                                                                        noValidate
                                                                        autoComplete="off"
                                                                    >
                                                                        {textFields}
                                                                    </Box>}
                                                                />
                                                            )
                                                        }
                                                    }
                                                }
                                            });
                                            /*content.push(
                                                <FormControlLabel
                                                    value={-1}
                                                    control={<Radio />}
                                                    labelPlacement="end"
                                                    label={<Box
                                                        key={-1}
                                                        component="form"
                                                        sx={{
                                                            '& > :not(style)': { m: 2, width: '140ch' },
                                                        }}
                                                        noValidate
                                                        autoComplete="off"
                                                    >
                                                        <TextField
                                                            variant="standard"
                                                            label={utilObjectLocalization((_.find(config.fields, { key: props.field.key }) || { title: props.field.key }), "title")}
                                                            defaultValue={clipboardText}
                                                            id={-1 + "_" + props.field.key}
                                                        />
                                                    </Box>}
                                                />
                                            )*/
                                            if (content != undefined && content.length > 0) {
                                                e.preventDefault();
                                                muiConfirm({
                                                    confirmationButtonProps: { autoFocus: true },
                                                    title: t("application.FormSection.Do you want to split pasted text"),
                                                    confirmationText: <Icon>check</Icon>,
                                                    cancellationText: <Icon>do_disturb</Icon>,
                                                    content: <RadioGroup
                                                        id="splitAddressRadioGroup"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            // @ts-ignore
                                                            event.target.parentElement.parentElement.parentElement.dataset.value = event.target.value;
                                                        }}
                                                        defaultValue={0}
                                                    >
                                                        {content}
                                                    </RadioGroup>,
                                                    dialogProps: {
                                                        fullWidth: true, maxWidth: "xl"
                                                    }
                                                })
                                                    .then(() => {
                                                        startBatch(true);
                                                        const radioGroup: any = document.getElementById("splitAddressRadioGroup");
                                                        const paste_parse_def_index_from_html = radioGroup.dataset.value || 0;
                                                        _.forEach(paste_parses[paste_parse_def_index_from_html].fields, (fieldKey) => {
                                                            const textField: any = document.getElementById(paste_parse_def_index_from_html + "_" + fieldKey);
                                                            if (textField && textField.value) {
                                                                setFieldData(fieldKey, index, textField.value);
                                                            }
                                                        });
                                                        startBatch(false);
                                                    }).catch(() => {
                                                        const textField = e.target as HTMLInputElement;
                                                        const caretStart = textField.selectionStart as number;
                                                        const caretEnd = textField.selectionEnd as number;
                                                        const originalText = textField.value as string;
                                                        const newText = originalText.substring(0, caretStart) + clipboardText + originalText.substring(caretEnd);
                                                        textField.value = newText;
                                                        const newCaretPosition = caretStart + clipboardText.length;
                                                        textField.setSelectionRange(newCaretPosition, newCaretPosition);
                                                    });
                                                return false;
                                            }
                                        }
                                    }}
                                />
                                // </Tooltip>
                            }
                        </>
                    }
                </Grid >
            }
        </>
    )
}