import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import logo from '../resources/images/logoTovek_Finder_02.png';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@emotion/react';
import theme from '../styles/theme';
import i18n from '../i18n/config';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { Alert, AlertTitle } from '@mui/material';

export default function Login() {
    i18n;
    const { t, ready } = useTranslation();

    const [isError, setError] = React.useState<boolean>(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        setError(false);
        e.preventDefault();
        $.ajax({
            url: e.currentTarget.action,
            type: "POST",
            data: $(e.currentTarget).serializeArray(),
            success: function (data) {
                location.reload();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                setError(true);
            }
        });
        return false;
    };

    return (<ThemeProvider theme={theme}>
        {ready &&
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            mr: 4,
                            height: 45
                        }}
                        alt="logo"
                        src={logo}
                    />
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}
                        //@ts-ignore
                        action={__host + "config/get"}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            id="user"
                            label={i18n.t("application.Login.Username")}
                            name="user"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            name="pwd"
                            label={i18n.t("application.Login.Password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <input
                            name="entry"
                            type="hidden"
                            id="entry"
                            value="trest.currentUsername"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {i18n.t("application.Login.TovekFinderLogin")}
                        </Button>
                    </Box>
                    {isError &&
                        <Alert severity="error">
                            <AlertTitle>{i18n.t("application.Login.Error")}</AlertTitle>
                            {i18n.t("application.Login.Wrong credentials")}
                        </Alert>
                    }
                </Box>
            </Container>
        }
    </ThemeProvider >
    );
}