import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { TovekAppContext } from '../../contexts/AppContext';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import _ from 'lodash';
import moment from 'moment';
import 'moment/min/locales';
import { IField } from '../../types/IConfiguration';

interface FormSectionDateProps {
    inputLabelProps: any;
    InputPropsEndAdornment: any;
    helperText: any;
    openNote: any;
    index: any;
    field: IField;
    fieldValue: any;
    fieldNote: any;
    fieldUrl: any;
    fieldLabel: any;
    fieldSearchOff: any;
    isUsedField: any;
}

export const defaultDateFormat = 'DD.MM.YYYY';

export default function FormSectionDate(props: FormSectionDateProps) {
    const { setFieldData, isNotesVisible, utilObjectLocalization } = React.useContext<ITovekAppContext>(TovekAppContext);

    const format = props.field.format || defaultDateFormat;

    const datejs = dayjs(props.fieldValue, format);
    let date: dayjs.Dayjs | null = props.fieldValue ? (datejs.isValid() ? datejs : null) : null;

    return (<LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            label={utilObjectLocalization(props.field, "title")}
            inputFormat={format}
            disableMaskedInput={false}
            mask={format.replace(/\w/g, '_')}
            value={date}
            onChange={(newValue) => {
                date = newValue;
            }}
            components={{
                OpenPickerIcon: () => (
                    <CalendarTodayIcon fontSize='small' />
                )
            }}
            autoFocus
            onAccept={(e: any) => setFieldData(props.field.key, props.index, e.format(format))}
            disabled={props.fieldSearchOff}
            renderInput={(params) =>
                <TextField {...params}
                    onPaste={(e) => {
                        for (const paste_date_format of (props.field.paste_date_formats || [])) {
                            const pastedData = e.clipboardData.getData('text').trim();
                            const momentDate = moment(pastedData, paste_date_format.format, paste_date_format.locale, paste_date_format.strict || true);
                            if (momentDate.isValid()) {
                                setFieldData(props.field.key, props.index, momentDate.format(format));
                                e.preventDefault();
                                break;
                            }
                        }
                    }}
                    error={params?.inputProps?.value != "" && !dayjs(params?.inputProps?.value, format).isValid()}
                    required={props.isUsedField()}
                    onBlur={(e) => {
                        const val = e.target.value;
                        if (val == null || val == "") {
                            setFieldData(props.field.key, props.index, "");
                        }
                        else if (dayjs(val, format).isValid()) {
                            setFieldData(props.field.key, props.index, val);
                        }
                        else {
                            setFieldData(props.field.key, props.index, props.fieldValue);
                        }
                    }}
                    variant="standard"
                    fullWidth={true}
                    label={utilObjectLocalization(props.field, "title")}
                    value={date}
                    helperText={props.helperText}
                    FormHelperTextProps={{
                        onDoubleClick: props.openNote,
                        sx: isNotesVisible ? { minHeight: 16 } : { minHeight: 8 }
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <>
                            <Box sx={{ mr: 5 }}>
                                {params.InputProps?.endAdornment}
                            </Box>
                            <props.InputPropsEndAdornment />
                        </>
                    }}
                    InputLabelProps={{ ...props.inputLabelProps() }}
                    title={utilObjectLocalization(props.field, "tooltip", '')}
                />
            }
        />
    </LocalizationProvider>
    );
}

