import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Tab, Tabs, styled } from '@mui/material';

import SourcesCheckboxes from './SourcesCheckboxes';
import _ from 'lodash';
import { ISource } from '../../types/IConfiguration';
import { TovekAppContext } from '../../contexts/AppContext';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import UserSourcesSelectbox from './UserSourcesSelectbox';
import { t } from 'i18next';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledTab = styled(Tab)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.03)"
  },
  fontSize: "0.8rem!important",
  p: "16px"
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function HorizontalTabs() {
  const { loadSteps, loadSources, category, setStep, utilObjectLocalization } = React.useContext<ITovekAppContext>(TovekAppContext);

  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const steps = loadSteps(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    let stepKey = "";
    if (newValue == steps.length) {
      stepKey = "ALL";
    }
    else if (newValue > steps.length) {
      stepKey = "CUSTOM";
    }
    else {
      stepKey = steps[newValue].key;
    }
    setStep(stepKey);
  };

  React.useEffect(() => {
    let stepIndex = 0;
    const step = JSON.parse(window.localStorage.getItem(category + "_step") || "\"\"");
    if (step == "") {
      stepIndex = 0;
    }
    else if (step == "ALL") {
      stepIndex = steps.length;
    }
    else if (step == "CUSTOM") {
      stepIndex = steps.length + 1;
    }
    else {
      stepIndex = _.findIndex(steps, { key: step });
    }
    setTabIndex(stepIndex);
  }, [category])

  return (
    <Box sx={{ width: '100%' }} className="horizontal-tabs">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} display="flex" justifyContent="center">
        <Tabs
          variant="scrollable"
          allowScrollButtonsMobile
          value={tabIndex}
          onChange={handleChange}
          aria-label="tabs"
        >
          {steps.map((step, index) => (
            <StyledTab key={index} label={utilObjectLocalization(step, "title")} {...a11yProps(index)} />
          ))}
          <StyledTab label={t("application.VerticalTabs.All")} {...a11yProps(steps.length)} />
          <StyledTab label={t("application.VerticalTabs.Custom")} {...a11yProps(steps.length + 1)} />
        </Tabs>
      </Box>
      {
        steps.map((step, index) => (
          <TabPanel key={index} value={tabIndex} index={index}>
            <SourcesCheckboxes sources={loadSources(step.key)} stepKey={step.key} />
          </TabPanel>
        ))
      }
      <TabPanel value={tabIndex} index={steps.length}>
        <SourcesCheckboxes sources={loadSources()} groupBy={(option: ISource) => option.group} stepKey={"ALL"} />
      </TabPanel>
      <TabPanel value={tabIndex} index={steps.length + 1}>
        <UserSourcesSelectbox />
      </TabPanel>
    </Box>
  );
}

