import _ from "lodash";
import { newINoteData } from "../types/INoteData";

export default function update(data: any, from: string, to: string) {
    const updatedData = JSON.parse(JSON.stringify(data));
    //if (updatedData.fields && updatedData.fields[0] && updatedData.fields[0].note && typeof updatedData.fields[0].note[0] === 'string') {
    //if (from && from.startsWith("2.0.0")) {
    //    if (to && to.startsWith("2.0.1")) {
    const updatedFields = _.mapValues(data.fields, (field) => {
        field.notes = _.map(field.notes, (note) => {
            return typeof note === 'string' ? newINoteData(note) : note;
        });
        return field;
    });
    updatedData.fields = updatedFields;
    //}
    return updatedData;
}