import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Stack, Card, CardContent, CardHeader, Typography, IconButton } from '@mui/material';
import _ from 'lodash';
import { IField, IFieldGroup, ISection } from '../../types/IConfiguration';
import { TovekAppContext } from '../../contexts/AppContext';
import Icon from '@mui/material/Icon';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import FormSection from './FormSection';
import PromptDialog from '../Dialogs/PromptDialog';
import { t } from 'i18next';
import MenuPopupState from '../Menus/MenuPopupState';

export default function FormTextFields() {
    const { config, startBatch, loadVisibleSections, loadVisibleFields, loadMaxFieldValues, createField, createFieldCustom, utilObjectLocalization } = React.useContext<ITovekAppContext>(TovekAppContext);

    const reducer = (prevState: any, state: any) => {
        return { open: state.open, value: state.value || "", searchOff: state.searchOff || false, key: state.key || "custom" };
    };
    const [state, dispatch] = React.useReducer(reducer, { open: false, value: "", searchOff: false, key: "custom" });
    const handleClosePromptDialogSet = (succes: boolean, returnValue: string) => {
        if (succes) {
            createFieldCustom(state.key, returnValue);
        }
        dispatch({ open: false });
    };

    const computeMenuItemsForAddingFields = (section: ISection): IField[] => {
        const possibleFields: IField[] = [];
        let fieldsGroups: any[] = [];
        section.fields.forEach((fieldKey: string) => {
            const findedConfigField: IField | undefined = _.find(config.fields, (o) => o.key === fieldKey);
            if (findedConfigField != undefined && findedConfigField.type != "separator") {
                possibleFields.push(findedConfigField);
            }
            if (findedConfigField != undefined && findedConfigField.group) {
                const findedConfigFieldGroup: IFieldGroup | undefined = _.find(config.field_groups, (o) => o.key === findedConfigField.group);
                fieldsGroups = _.unionWith(fieldsGroups, [findedConfigFieldGroup], _.isEqual);
            }
        });
        fieldsGroups.forEach((group) => {
            possibleFields.push(_.extend({
                "type": "group"
            }, group));
        });
        /*if (!_.find(possibleFields, { type: "custom" })) {
            possibleFields.push(_.find(config.fields, { key: "custom" }) || {
                "title": t("application.FormTextFields.Custom"),
                "key": "custom_" + section.key.replace("-", ""),
                "width": 6,
                "type": "custom",
                "multiline": true
            });
        }*/
        const customs = _.remove(possibleFields, { type: "custom" });
        return _.concat(possibleFields, customs);
    };

    const createSomeField = (section: ISection, fieldOrGroup: IField) => {
        if (fieldOrGroup.type == "custom") {
            dispatch({ open: true, value: "", key: fieldOrGroup.key });
        }
        else if (fieldOrGroup.type == "group") {
            startBatch(true);
            section.fields.forEach((fieldKey: string) => {
                const findedConfigField = _.find(config.fields, (o) => o.key === fieldKey);
                if (findedConfigField != undefined && findedConfigField.type != "separator" &&
                    findedConfigField.group == fieldOrGroup.key) {
                    createField(findedConfigField.key);
                }
            });
            startBatch(false);
        }
        else {
            createField(fieldOrGroup.key)
        }
    };

    return (
        <Box
            component="form"
            sx={{
                my: 3, ml: 3, mr: 1.5
                //'& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            //noValidate
            autoComplete="off"
        >
            <Box >
                <Stack spacing={2} >
                    {loadVisibleSections().map((section, rowIndex) => (
                        <Card key={rowIndex}>
                            <CardHeader
                                style={{ justifyContent: "center", display: "flex" }}
                                avatar={<Icon sx={{ color: "#002168" }}>{section.icon}</Icon>}
                                action={
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}>
                                        {section.type != "image" && section.type != "description" &&
                                            <MenuPopupState
                                                menuId={"section_" + section.key}
                                                menuStartIcon="add"
                                                menuitems={computeMenuItemsForAddingFields(section)}
                                                action={(field: IField) => createSomeField(section, field)}
                                                size='small'
                                            />
                                        }
                                    </Box>}
                                title={<Typography variant="h6" color="secondary">{utilObjectLocalization(section, "title")}</Typography>}
                            />
                            {!section.single ?
                                <CardContent sx={{ pb: "16px!important" }} className="section">
                                    {[...Array(loadMaxFieldValues(section.key))].map((value: any, valueIndex: React.Key) => (
                                        <Grid className="section-content" container key={valueIndex} spacing={4} sx={{ paddingBottom: valueIndex < (loadMaxFieldValues(section.key) - 1) ? 4 : 0 }}>
                                            {loadVisibleFields(section.key).map((field: IField) => (<FormSection key={field.key} field={field} index={valueIndex} />))}
                                        </Grid>
                                    ))}
                                </CardContent>
                                :
                                <CardContent sx={{ pb: "16px!important" }}>
                                    <Grid container spacing={4}>
                                        {[...Array(loadMaxFieldValues(section.key))].map((value: any, valueIndex: React.Key) => (
                                            <React.Fragment key={valueIndex}>
                                                {loadVisibleFields(section.key).map((field: IField) => (<FormSection key={field.key} field={field} index={valueIndex} />))}
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                </CardContent>
                            }
                        </Card>
                    ))}
                </Stack>
                <PromptDialog
                    title={t("application.FormTextFields.Create custom field")}
                    text={t("application.FormTextFields.Enter a custom field name")}
                    fieldValue={state.value}
                    open={state.open}
                    onClose={handleClosePromptDialogSet}
                    actions={["close", "save"]} />
            </Box>
        </Box >
    );
}