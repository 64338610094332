import * as React from 'react';
import Box from '@mui/material/Box';
import logo from '../resources/images/logoTovek_Finder_02.png';
import { AppBar, Button, Icon, Link, Toolbar, Typography, useMediaQuery } from '@mui/material';
import LangDialog from './Dialogs/LangDialog';
import CurrentUsername from './Hostname';
import MenuPopupState from './Menus/MenuPopupState';

import { t } from 'i18next';
import { TovekAppContext } from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import CategoryMenu from './Menus/LeftCategoryMenu';
import { ITovekAppContext } from '../types/ITovekContextApp';

import { saveAs } from 'file-saver';
import _ from 'lodash';
import { ICategory, IReport } from '../types/IConfiguration';
import { useRef } from 'react';
import dayjs from 'dayjs';
import JSZip from 'jszip';
import $ from 'jquery';

import ConfigService from '../services/config';
import { AxiosResponse } from 'axios';
import TargetsDialog from './Dialogs/TargetsDialog';
import { Bounce, toast } from 'react-toastify';
import { IFieldData } from '../types/IFieldData';
import { useConfirm } from 'material-ui-confirm';
import SaveDataConfigDialog, { SaveDataConfig } from './Dialogs/SaveDataConfigDialog';
import update from '../Utils/versionUpdater';


type IRightMenuItem = {
  title: string,
  icon?: string,
  action?: any
}

export default function TovekAppBar() {
  const [openLanguageSettings, setOpenLanguageSettings] = React.useState(false);
  const navigate = useNavigate();

  const { setDownload, download, config, category, createEmptyData, data, reload, setReload, getDataFields, setStep, saveData, prepareDiagram, lastConfigVersion, getLastIndexedObject, setLastIndexedObject, setNeedBackdrop, utilObjectLocalization, prepareDisplayFields } = React.useContext<ITovekAppContext>(TovekAppContext);

  const isSearch = location.hash.indexOf("/search/") != -1;

  const isReport = location.hash.indexOf("/report/") != -1;

  const isHelp = location.hash.indexOf("/help") != -1;

  const handleClickTranslation = () => {
    setOpenLanguageSettings(true);
  };

  const handleCloseLanguageSettings = () => {
    setOpenLanguageSettings(false);
  };

  let saveDataConfigState: SaveDataConfig;
  const getSaveDataConfigState = (a: SaveDataConfig) => {
    saveDataConfigState = a;
  };

  const handleClickSaveData = () => {
    muiConfirm({
      confirmationButtonProps: { autoFocus: true },
      title: t('application.TovekAppBar.Export data'),
      confirmationText: <Icon>check</Icon>,
      cancellationText: <Icon>do_disturb</Icon>,
      content: <SaveDataConfigDialog getSaveDataConfigState={getSaveDataConfigState} />
    })
      .then(() => {
        console.log(saveDataConfigState);
        const savedData = JSON.parse(JSON.stringify(data));
        if (!saveDataConfigState.form) {
          delete savedData["fields"];
          _.forOwn(savedData["categories"], function (value, key) {
            delete savedData["categories"][key]["visible_sections"];
            delete savedData["categories"][key]["visible_fields"];
          });
        }
        if (!saveDataConfigState.checkedSources) {
          _.forOwn(savedData["categories"], function (value, key) {
            delete savedData["categories"][key]["steps"];
          });
        }
        if (!saveDataConfigState.customSourcesSets) {
          _.forOwn(savedData["categories"], function (value, key) {
            delete savedData["categories"][key]["customSteps"];
          });
        }
        savedData.version = lastConfigVersion;
        const blob = new Blob([JSON.stringify(savedData)], { type: "application/json" });
        saveAs(blob, "TovekFinderData_" + dayjs().format('DD-MM-YYYY') + ".json");
      });
  };

  const inputRef = useRef(null);
  const handleClickImportData = () => {
    (inputRef?.current as any).click();
  }
  const importData = (event: any) => {
    setNeedBackdrop(true);
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    fileObj.text()
      .then((value: string) => {
        setNeedBackdrop(false);
        try {
          const loadedData = JSON.parse(value);
          if (!_.isObject(loadedData.categories)) {
            throw Error;
          }
        } catch (e) {
          toast(
            <span style={{ wordBreak: "break-word" }}>
              {t('application.TovekAppBar.The imported file is not a data file')}
            </span>,
            { type: toast.TYPE.ERROR, autoClose: false, transition: Bounce }
          );
          return;
        }
        muiConfirm({
          confirmationButtonProps: { autoFocus: true },
          title: t('application.TovekAppBar.Import data'),
          confirmationText: <Icon>check</Icon>,
          cancellationText: <Icon>do_disturb</Icon>,
          content: <SaveDataConfigDialog getSaveDataConfigState={getSaveDataConfigState} />
        })
          .then(() => {
            setNeedBackdrop(true);
            const loadedData = JSON.parse(value);
            const emptyData = createEmptyData();
            if (!saveDataConfigState.form) {
              delete loadedData["fields"];
              _.forOwn(loadedData["categories"], function (value, key) {
                delete loadedData["categories"][key]["visible_sections"];
                delete loadedData["categories"][key]["visible_fields"];
              });
            }
            if (!saveDataConfigState.checkedSources) {
              _.forOwn(loadedData["categories"], function (value, key) {
                delete loadedData["categories"][key]["steps"];
              });
            }
            if (!saveDataConfigState.customSourcesSets) {
              _.forOwn(loadedData["categories"], function (value, key) {
                delete loadedData["categories"][key]["customSteps"];
              });
            }

            _.forOwn(loadedData["categories"], function (value, key) {
              if (data["categories"][key]) {
                _.forEach(data["categories"][key]["customSteps"], function (customStep) {
                  if (loadedData["categories"][key]["customSteps"] == undefined) {
                    loadedData["categories"][key]["customSteps"] = {};
                  }
                  if (!loadedData["categories"][key]["customSteps"][customStep.key]) {
                    loadedData["categories"][key]["customSteps"][customStep.key] = customStep
                  }
                });
              }
            });

            update(loadedData, loadedData.version, config.version);

            setStep("");
            localStorage.setItem(category + "_userStep", "");
            saveData(_.mergeWith({}, emptyData, loadedData, (objValue, srcValue) => {
              if (_.isArray(srcValue)) {
                return srcValue;
              }
            }));
            setReload(!reload);
            setNeedBackdrop(false);
            toast(
              <span style={{ wordBreak: "break-word" }}>
                {t('application.TovekAppBar.Data has been successfully imported')}
              </span>,
              { type: toast.TYPE.SUCCESS, toastId: "imported", transition: Bounce }
            );
          });
      })
      .catch((error: string) => {
        console.log("Something went wrong" + error);
        location.reload();
      });
    event.target.value = null;
  }

  const logout = () => {
    setNeedBackdrop(true);
    // @ts-ignore
    const logoutUrl = __host + 'logout';
    $.ajax({
      async: false,
      url: logoutUrl,
      type: 'GET',
      username: 'logout'
    }).always(function (data) {
      location.reload();
    });
  }

  const mainMenuItems: IRightMenuItem[] = [
    //{ title: t('application.TovekAppBar.Identity management'), icon: "fingerprint" },    
    { title: t('application.TovekAppBar.Export data'), icon: "file_download", action: handleClickSaveData },
    { title: t('application.TovekAppBar.Import data'), icon: "file_upload", action: handleClickImportData },
    { title: t('application.TovekAppBar.Language'), icon: "g_translate", action: handleClickTranslation },
    { title: t('application.TovekAppBar.Help'), icon: "help", action: () => navigate("/help") },
    { title: t('application.TovekAppBar.Logout'), icon: "logout", action: logout }
  ];

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dialogOptions, setDialogOptions] = React.useState<string[]>([]);
  const handleClose = (targetIndexName?: string, reindex?: boolean) => {
    if (targetIndexName) {
      //TODO define indexed object separatly
      const iReport: IReport | undefined = _.find(config.reports, { "key": category + "_diagram" });
      if (iReport) {
        const objectDocument = prepareDiagram(iReport).data.elements.nodes[0].data.document;
        const fieldObjects = objectDocument.fields;
        const fieldFlatObjects = [];
        for (const fieldKey in fieldObjects) {
          fieldFlatObjects.push({ name: fieldKey, value: fieldObjects[fieldKey].value });
        }
        if (!getLastIndexedObject() || !reindex) {
          setLastIndexedObject(objectDocument);
        }
        fieldFlatObjects.push({ name: "_key", value: (reindex ? (getLastIndexedObject() || objectDocument) : objectDocument)._uuidkey });
        //fieldFlatObjects.push({ name: "_uuidkey", value: lastIndexedUUIDKEY });
        //fieldFlatObjects.push({ name: "_gwkey", value: lastIndexedUUIDKEY });
        ConfigService.index(targetIndexName, fieldFlatObjects).then((response: AxiosResponse<any, string>) => {
          setOpenDialog(false);
          if (response.data.info.indexed) {
            toast(
              t("application.TovekAppBar.Object succesfully indexed"),
              { type: toast.TYPE.SUCCESS, toastId: "indexed", transition: Bounce }
            );
          } else {
            toast(
              t("application.TovekAppBar.Object indexing failed"),
              { type: toast.TYPE.ERROR, toastId: "indexed", autoClose: false, transition: Bounce }
            );
          }
        }).catch((e: Error) => {
          ConfigService.check401(e);
          setOpenDialog(false);
          toast(
            // @ts-ignore
            <><b>{t("application.TovekAppBar.Object indexing failed") + ":"}</b><br />{ConfigService.getErrorText(e)}</>,
            { type: toast.TYPE.ERROR, toastId: "indexed", autoClose: false, transition: Bounce }
          );
        });
      }
    }
    setOpenDialog(false);
  };

  const minSize = useMediaQuery('(min-width:600px)', { noSsr: true });

  const muiConfirm = useConfirm();

  const iReport: IReport | undefined = _.find(config.reports, { "key": category + "_diagram" });

  return (
    <>
      <input
        title="import-data"
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        accept='application/json'
        onChange={importData}
      />
      <TargetsDialog
        id="targets-dialog"
        keepMounted
        open={openDialog}
        onClose={handleClose}
        options={dialogOptions}
        value={dialogOptions[0]}
      />
      <AppBar className="no-print">
        <Toolbar>
          <Box
            component="img"
            sx={{
              mr: 4,
              height: 45
            }}
            alt="logo"
            src={logo}
          />
          <Typography variant="button" color="secondary" sx={{ flexGrow: 1 }}>
            {isSearch && _.some(config.categories, { key: category })
              ? <CategoryMenu />
              : <>
                <Button
                  size="large"
                  color="secondary"
                  startIcon={<Icon>keyboard_return</Icon>}
                  onClick={() => { location.href = isReport ? "#/search/" + category : "#" }}
                >
                  {t("application.TovekAppBar.Back to search")}
                </Button>
                {isReport && <>
                  <Button
                    size="large"
                    color="secondary"
                    startIcon={<Icon>print</Icon>}
                    onClick={window.print}>
                    {t("application.TovekAppBar.Print")}
                  </Button >
                  <Button
                    size="large"
                    color="secondary"
                    startIcon={<Icon>download</Icon>}
                    onClick={() => {
                      setDownload("all");
                    }}
                  >
                    {t("application.TovekAppBar.Download")}
                  </Button >
                  {config.indexable && iReport && prepareDiagram(iReport).data.elements.nodes[0] &&
                    <Button
                      size="large"
                      color="secondary"
                      startIcon={<Icon>flash_on</Icon>}
                      onClick={() => {
                        if (config.indexable == "TS") {
                          setNeedBackdrop(true);
                          ConfigService.getTargets().then((response: AxiosResponse<any, string>) => {
                            const targets: string[] = [];
                            _.forEach(response.data.targets, function (target) {
                              targets.push(target.target);
                            });
                            setDialogOptions(targets);
                            setOpenDialog(true);
                            setNeedBackdrop(false);
                          }).catch((e: Error) => {
                            console.log(e);
                            ConfigService.check401(e);
                          });
                        } else {
                          setDownload("indexableData");
                        }
                      }}>
                      {config.indexable == "TS" ?
                        t("application.TovekAppBar.Index") :
                        t("application.TovekAppBar.Download Indexable Data")
                      }
                    </Button >
                  }
                </>
                }
                {isHelp && <>
                  <Link
                    href="./help/licenses.json" target="_blank"
                  >
                    <Button
                      size="large"
                      color="secondary"
                      startIcon={<Icon>gavel</Icon>}
                    >
                      {t("application.LeftCategoryMenu.ThirdParty Licenses")}
                    </Button>
                  </Link>
                  <Button
                    size="large"
                    color="secondary"
                    startIcon={<Icon>restart_alt</Icon>}
                    onClick={() => { if (confirm("Tímto resetujete Tovek Finder do prvotního nastavení (vymaže se formulář, zaškrtnuté zdroje, vlastní sady zdrojů a resetují se další informace uložené v paměti prohlížeče).")) { localStorage.clear(); location.reload(); } }}> {t("application.LeftCategoryMenu.Reset All")} </Button>
                </>
                }
              </>
            }
          </Typography>
          {(isSearch || isReport) &&
            <Box sx={{ flexGrow: 0 }}>
              <MenuPopupState
                menuId={"categoriesMenu"}
                menuTitle={minSize ? t("application.TovekAppBar.Category") : " "}
                menuEndIcon="keyboard_arrow_down_icon"
                menuitems={config.categories}
                actualValue={category}
                action={(category: ICategory) => navigate("search/" + category.key)}
                size="large"
                color="secondary" />
            </Box>
          }
          <MenuPopupState
            menuId={"mainMenu"}
            menuTitle={minSize ? <CurrentUsername /> : " "}
            menuStartIcon={minSize ? "account_circle" : undefined}
            menuEndIcon="menu"
            menuitems={mainMenuItems}
            action={(menuItem: any) => menuItem.action()}
            size="large"
            color="secondary" />
          <LangDialog
            open={openLanguageSettings}
            onClose={handleCloseLanguageSettings}
          />
        </Toolbar>
      </AppBar >
      <Toolbar />
    </>
  );
}