import React, { useContext } from 'react'
import ReactRouter from './ReactRouter';
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryFallbackProps } from './types/ErrorBoundaryFallbackProps';
import { TovekAppContext } from './contexts/AppContext';
import { ITovekAppContext } from './types/ITovekContextApp';
import { Backdrop, CircularProgress } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import logo from './resources/images/logoTovek_Finder_02.png';

import i18n from './i18n/config';
import { useTranslation } from 'react-i18next';

const Loader = () => (
    <Backdrop
        sx={{
            backgroundImage: 'url("' + logo + '")',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            color: '#fff',
            zIndex: (theme: { zIndex: { drawer: number; }; }) => theme.zIndex.drawer + 9999
        }}
        open={true}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
);

const App: React.FC = () => {
    const { appReady, needBackdrop } = useContext<ITovekAppContext>(TovekAppContext);
    i18n;
    const { t, ready } = useTranslation();

    const ErrorBoundaryFallback = ({ error, resetErrorBoundary }: ErrorBoundaryFallbackProps) => (
        <div role="alert" style={{ backgroundImage: 'url("' + logo + '")', height: "95vh", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
            <p>{t("application.App.Something went wrong")}:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>{t("application.App.Try again")}</button>
            &nbsp;
            <button onClick={() => { localStorage.clear(); location.reload(); }}>{t("application.App.Reset to Factory")}</button>
        </div >
    )

    return (
        <>
            {appReady && ready
                ? <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onReset={() => location.reload()}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme: { zIndex: { drawer: number; }; }) => theme.zIndex.drawer + 9999 }}
                        open={needBackdrop}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <ReactRouter />
                </ErrorBoundary >
                : <Loader />}
        </>
    )
}

export default App;
