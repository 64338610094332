import i18n from 'i18next';
import backend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';

const lang = localStorage.getItem('lang') || ("\"" + window.navigator.language.split('-')[0] + "\"");

i18n.use(backend).use(initReactI18next).init({
    lng: lang ? JSON.parse(lang) : "cs",

    fallbackLng: "cs",

    backend: {
        allowMultiLoading: true,
        loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}.json`
    },

    preload: ["cs"],

    interpolation: {
        escapeValue: false
    },

    react: {
        useSuspense: false
    }
});

export default i18n;
