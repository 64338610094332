import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { NestedMenuItem } from 'mui-nested-menu';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Checkbox, FormControlLabel, FormGroup, Divider, ListItemIcon, Icon, useMediaQuery } from '@mui/material';
import { TovekAppContext } from '../../contexts/AppContext';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import _, { debounce } from 'lodash';
import { t } from 'i18next';
import { ISection } from '../../types/IConfiguration';
import {
    usePopupState,
    bindHover,
    bindMenu,
    bindFocus
} from 'material-ui-popup-state/hooks'
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { useNavigate } from 'react-router-dom';

export default function LeftCategoryMenu() {
    const { config, category, startBatch, loadSections, loadVisibleSections, showHideSection, resetForm, resetSources, isNotesVisible, setNotesVisible, utilObjectLocalization, setNeedBackdrop } = React.useContext<ITovekAppContext>(TovekAppContext);
    const navigate = useNavigate();
    const popupState = usePopupState({ variant: 'popover', popupId: "leftCategoryMenu" });
    const checked = loadVisibleSections();

    const debouncedHandleMouseLeave = debounce(
        (e) => popupState.onMouseLeave(e), 100
    );
    const handlOnMouseEnter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        debouncedHandleMouseLeave.cancel();
        if (!popupState.isOpen && popupState.anchorEl) {
            // @ts-ignore
            e.currentTarget = popupState.anchorEl;
            e.type = "mouseover";
            popupState.setOpen(true, e);
        }
    };

    const minSize = useMediaQuery('(min-width:600px)', { noSsr: true });

    return (
        <>
            <Button
                {...bindHover(popupState)}
                {...bindFocus(popupState)}
                onMouseLeave={debouncedHandleMouseLeave}
                color="secondary"
                size="large"
                endIcon={<MoreVertIcon />}
            >
                {minSize ? utilObjectLocalization(_.find(config.categories, { key: category }), "title") : " "}
            </Button>
            <HoverMenu
                {...bindMenu(popupState)}
                onMouseEnter={handlOnMouseEnter}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}>
                <NestedMenuItem
                    sx={{
                        px: 2,
                        "& p": {
                            pl: 0
                        }
                    }}
                    rightIcon={<ArrowRightIcon />}
                    label={t("application.LeftCategoryMenu.Show section")}
                    parentMenuOpen={popupState.isOpen}
                    leftIcon={
                        <ListItemIcon>
                            <Icon fontSize="small">vertical_split</Icon>
                        </ListItemIcon>
                    }
                    onClick={(e) => { popupState.anchorEl?.focus() }}
                >
                    <FormGroup itemProp='' sx={{ pl: 0 }}>
                        {loadSections().map((section: ISection, rowIndex) => (
                            <MenuItem key={rowIndex} sx={{ pt: 0, pb: 0 }}>
                                <FormControlLabel control={<Checkbox checked={_.some(checked, { "key": section.key })} />} label={utilObjectLocalization(section, "title")} onChange={() => showHideSection(section.key)} />
                            </MenuItem>
                        ))}
                    </FormGroup>
                </NestedMenuItem>
                <Divider sx={{ my: "8px" }} />
                <MenuItem onClick={() => { setNotesVisible(!isNotesVisible); popupState.close(); }}>
                    <ListItemIcon>
                        <Icon fontSize="small">sticky_note_2</Icon>
                    </ListItemIcon>
                    {isNotesVisible ? t("application.LeftCategoryMenu.Hide notes") : t("application.LeftCategoryMenu.Show notes")}
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => navigate("/report/" + category)}>
                    <ListItemIcon>
                        <Icon fontSize="small">assignment_turned_in</Icon>
                    </ListItemIcon>
                    {t('application.LeftCategoryMenu.Create category summary')}
                </MenuItem>
                {/*<MenuItem onClick={() => navigate("/report/all")}> {t('application.LeftCategoryMenu.Create all summary')} </MenuItem>
                 <MenuItem onClick={() => handleClickTTObject()}> {t('application.LeftCategoryMenu.Create TT Object')} </MenuItem> */}
                <Divider />
                <MenuItem onClick={(e) => { setNeedBackdrop(true); resetForm(); popupState.setOpen(false, e); setNeedBackdrop(false); }}><ListItemIcon>
                    <Icon fontSize="small">delete_sweep</Icon>
                </ListItemIcon>
                    {t("application.LeftCategoryMenu.Reset Form")}
                </MenuItem>
                <MenuItem onClick={(e) => { setNeedBackdrop(true); resetSources(); popupState.setOpen(false, e); setNeedBackdrop(false); }}><ListItemIcon>
                    <Icon fontSize="small">remove_done</Icon>
                </ListItemIcon>
                    {t("application.LeftCategoryMenu.Reset Sources")}
                </MenuItem>
                <MenuItem onClick={(e) => { setNeedBackdrop(true); startBatch(true); resetSources(); resetForm(); startBatch(false); popupState.setOpen(false, e); setNeedBackdrop(false); }}><ListItemIcon>
                    <Icon fontSize="small">delete</Icon>
                </ListItemIcon>
                    {t("application.LeftCategoryMenu.Reset Form and Sources")}
                </MenuItem>
            </HoverMenu>
        </>
    );
}
