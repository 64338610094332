import React, { ForwardedRef, forwardRef, useEffect } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import $ from 'jquery';

const CustomListboxComponent = forwardRef(
    function ListBoxBase(
        props: any,
        ref: ForwardedRef<HTMLUListElement>
    ) {
        const [tabPanelsHeight, setTabPanelsHeight] = React.useState<number>(0);

        useEffect(() => {
            if ($(".MuiAutocomplete-root") != undefined) {
                let height = 0;
                if ($(".user-sources-selectbox") != undefined && $(".user-sources-selectbox").length > 0) {
                    height += 56 + 16;
                }
                if ($(".horizontal-tabs") != undefined && $(".horizontal-tabs").length > 0) {
                    height += 48;
                }
                if ($(".bottom-navigation") != undefined && $(".bottom-navigation").length > 0 && $(".bottom-navigation").css("display") != "none") {
                    height += 56;
                }
                setTabPanelsHeight(($(".MuiAutocomplete-root").height() || 0) + height);
            }
        });

        return (<PerfectScrollbar style={{ height: "calc( 100vh - " + (64 + 24 * 2 + 24 * 2 + 42.25 + 16 + tabPanelsHeight) + "px )", minHeight: "150px" }}>
            <ul
                ref={ref}
                {...props}
            />
        </PerfectScrollbar>)
    }
);

export default CustomListboxComponent;