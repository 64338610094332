import React, { useMemo, useState } from 'react'
import { createContext, useEffect } from 'react';
import useLocalStorage from '../controller/useLocalStorage';
import { ICategory, IConfiguration, IField, IObject, IReport, ISection, ISource, IStep } from '../types/IConfiguration';
import _ from 'lodash';
import ConfigService from '../services/config'
import { AxiosResponse } from 'axios';
import { IResponse } from '../types/IResponse';
import i18next, { t } from 'i18next';
import { IFieldData, ISourceData } from '../types/IFieldData';
import { v4 as uuidv4 } from 'uuid';
import getUuidByString from 'uuid-by-string';
import { toast, Bounce } from 'react-toastify';
import ReactDOM from 'react-dom';
import Login from '../pages/Login';
import { INoteData, newINoteData } from '../types/INoteData';
import update from '../Utils/versionUpdater';

type TovekAppContextProviderProps = {
    children?: React.ReactNode;
}

export const emptyConfig = {
    version: "0",
    languages: [
        { name: "Čeština", code: 'cs' },
        { name: "English", code: 'en' }
    ],
    variables: [],
    categories: [
        {
            key: "",
            title: "",
            sections: [],
            steps: [],
            reports: []
        }
    ], sections: [], fields: [], steps: [], sources: [], empty: true
};
export const emptyData = { categories: [], fields: [], description: "", empty: true };
const emptyField = { values: [""], notes: [{ value: "" }], searchOff: [false] };

const res = new RegExp("#{([0-9a-zA-Z.:_-]+)}", "g");

const TovekAppContext = createContext<any>("");

function TovekAppContextProvider({ children }: TovekAppContextProviderProps) {
    const [lastConfigVersion, setLastConfigVersion] = useLocalStorage<string>("lastConfigVersion", "0");
    const [isNotesVisible, setNotesVisible] = useLocalStorage<boolean>("showNotes", true);

    const [oldDataFields, setOldDataFields] = useLocalStorage<any>("oldDataFields", {});

    const [noConfig, setNoConfig] = useLocalStorage<boolean>("noconfig", false);

    const [category, setCategory] = useState<string>((location.hash || "/").substring("/search/".length + 1));
    const [step, setStep] = useLocalStorage<string>(category + "_step", "");

    const [appReady, setReady] = useState<boolean>(false);
    const [config, setConfig] = useState<IConfiguration>(emptyConfig);

    const [reload, setReload] = useState<boolean>(false);

    const [download, setDownload] = useState<string>();

    const options = {
        logger: (error: any) => {
            console.error(error);
            toast(
                t("application.AppContext.Sorry, but the browser's memory size has been exceeded, the last changes will not be saved. Try deleting an image or a larger text field."),
                { type: toast.TYPE.ERROR, toastId: "localStorageError", autoClose: false, transition: Bounce }
            );
            const oldData = window.localStorage.getItem("data");
            if (oldData)
                setData(JSON.parse(oldData));
        }
    };
    const [data, setData] = useLocalStorage<any>("data", emptyData, options);

    const dataMemo = useMemo(() => JSON.parse(JSON.stringify(data)), [data]);

    const [needBackdrop, setNeedBackdrop] = React.useState(false);

    function badConfiguration() {
        alert(t("application.AppContext.Tovek Finder configuration is not set, Contact the administrator."));
        location.reload();
    }

    function prepareConfig(defaultConfig?: string) {
        ConfigService.getConfig(defaultConfig)
            .then(async (response: AxiosResponse<IResponse, string>) => {
                const loadedConfig = response.data.data;
                if (loadedConfig.noconfig) {
                    if (defaultConfig == undefined) {
                        setNoConfig(true);
                        await fetch("./config/minimal.json")
                            .then((res) => res.json()).then((defaultConfig) => prepareConfig(defaultConfig));
                    } else {
                        badConfiguration();
                    }
                } else {
                    if (loadedConfig.version == undefined || loadedConfig.categories == undefined) {
                        badConfiguration();
                    }
                    else if (loadedConfig.version != lastConfigVersion) {
                        if (lastConfigVersion != "0") {
                            alert(t("application.AppContext.There has been a change in the resource configuration version or the application version and therefore your settings (not data) will be deleted."));
                            update(data, lastConfigVersion, loadedConfig.version);
                            const _oldDataFields = data.fields;
                            localStorage.clear();
                            if (!_.isEmpty(_oldDataFields)) {
                                setOldDataFields(_oldDataFields);
                            }
                        }
                        setLastConfigVersion(loadedConfig.version);
                        location.reload();
                    }
                    else {
                        if (loadedConfig.empty) {
                            badConfiguration();
                        }
                        else {
                            setConfig(loadedConfig);
                        }
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status == 401) {
                        ReactDOM.render(<Login></Login>,
                            document.getElementById('root')
                        )
                    }
                }
            });
    }

    useEffect(() => {
        setNoConfig(false);
        prepareConfig();
    }, [])

    useEffect(() => {
        if (!config.empty) {
            if (data.empty) {
                setData(createEmptyData());
            } else {
                setReady(true);
            }
        }
    }, [config])

    useEffect(() => {
        if (!config.empty) {
            setReady(true);
        }
    }, [data])

    function createEmptyData(): any {
        const dataCategory: any = {};
        const dataFields: any = oldDataFields || {};
        setOldDataFields({});

        for (const categoryId in config.categories) {
            const categories = config.categories[categoryId];

            const sectionsVisibleFields: string[] = [];
            for (const sectionKey in (categories.visible_sections || categories.sections)) {
                const findedSection = _.find(config.sections, { "key": (categories.visible_sections || categories.sections)[sectionKey] });
                if (findedSection) {
                    (findedSection.visible_fields || findedSection.fields).forEach(function (fieldKey) {
                        const findedField = _.find(config.fields, { "key": fieldKey });
                        if (findedField && findedField.type != "custom") {
                            sectionsVisibleFields.push(fieldKey);
                        }
                    });
                }
            }

            const categoryData = { visible_sections: categories.visible_sections || categories.sections, visible_fields: sectionsVisibleFields };
            dataCategory[categories.key] = categoryData;

            for (const visibleFieldKey in sectionsVisibleFields) {
                dataFields[sectionsVisibleFields[visibleFieldKey]] = dataFields[sectionsVisibleFields[visibleFieldKey]] || emptyField;
            }
        }
        const newData = { fields: dataFields, categories: dataCategory };
        return newData;
    }

    function getData() {
        //newData["timestamp"] = Date();
        if (batchActive) {
            return tempData;
        }
        else {
            return JSON.parse(JSON.stringify(data));
        }
    }

    function saveData(newData: any) {
        //newData["timestamp"] = Date();
        if (batchActive) {
            tempData = newData;
        }
        else {
            setData(newData);
        }
    }

    function getDataFields() {
        const copiedDataFields = dataMemo.fields;
        if (copiedDataFields["image"]) {
            const copiedDataImages = _.omit(copiedDataFields["image"], "values")
            copiedDataFields["image.url"] = copiedDataImages;
            copiedDataFields["image.url"].values = copiedDataFields["image"].urls;
            copiedDataFields["image.label"] = copiedDataImages;
            copiedDataFields["image.label"].values = [];
            copiedDataFields["image.label"].notes.map((note: INoteData, index: number) => {
                copiedDataFields["image.label"].values[index] = note.value || (copiedDataFields["image.label"].labels ? copiedDataFields["image.label"].labels[index] : "");
            });
        }
        _.filter(config.fields, { type: "custom" }).forEach((customField: IField) => {
            if (copiedDataFields[customField.key]) {
                const copiedDataCustom = _.omit(copiedDataFields[customField.key], "values")
                copiedDataFields[customField.key + ".label"] = copiedDataCustom;
                copiedDataFields[customField.key + ".label"].values = copiedDataFields[customField.key].labels;
                _.forEach(copiedDataFields[customField.key].labels, (label, index) => {
                    copiedDataFields[customField.key + ":" + label] = copiedDataFields[customField.key];
                })
            }
        });
        return copiedDataFields;
    }

    function preparedReportDataForSend(reportDef: IReport) {
        const newData = JSON.parse(JSON.stringify(data));
        newData.fields = getDataFields();
        const fields = [];

        for (const fieldKey in newData.fields) {
            const values = [];
            for (const valueIndex in newData.fields[fieldKey].values) {
                if (newData.fields[fieldKey].values[valueIndex] != "") {// && !newData.fields[fieldKey].searchOff[valueIndex]) {
                    values.push(newData.fields[fieldKey].values[valueIndex]);
                }
            }
            if (values.length != 0) {
                fields.push({ key: fieldKey, values: values });
            }
        }

        const reportFields = _.map(reportDef.values, (value) => {
            if (Number.isInteger(value) && Number(value) < 0) {
                return "" + value
            }
            else {
                return value;
            }
        });

        const reports = {
            key: reportDef.key,
            remove_fields_without_values: true,
            disable_escaping: true,
            escape_only_form_fields: true,
            url: "#{qry}",
            fields: reportFields
        }

        const preparedData = { fields: fields, reports: [reports] };
        return preparedData;
    }

    function preparedDataForSend(category: string, step: string) {
        const newData = JSON.parse(JSON.stringify(data));
        newData.fields = getDataFields();

        const fields = [];
        const sources: ISourceData[] = []

        const usedFields: string[] = [];
        if (category) {
            for (const categoryKey in newData.categories) {
                if (categoryKey == category) {
                    if (step) {
                        for (const stepKey in newData.categories[categoryKey].steps) {
                            if (stepKey == step) {
                                _.map(newData.categories[categoryKey].steps[stepKey].selectedSources, (selectedSource) => {
                                    const preparedSource: ISourceData = { key: selectedSource.key };
                                    const findedSourcesDisabledFields = _.find((data.categories[category]?.steps || {})[stepKey]?.sourcesDisabledFields || [], { key: selectedSource.key });
                                    if (findedSourcesDisabledFields) {
                                        preparedSource.disabled_fields = findedSourcesDisabledFields.fields;
                                    }
                                    sources.push(preparedSource);
                                    const findedSource = _.find(config.sources, { "key": selectedSource.key });
                                    usedFields.push(...findedSource?.used_fields || []);
                                });
                            }
                        }
                    }
                }
            }
        }

        if (usedFields.length > 0) {
            const visible_sections = newData.categories[category].visible_sections;
            const possibleFields: string[] = [];
            for (const visibleSectionIndex in visible_sections) {
                const findedSection = _.find(config.sections, { "key": visible_sections[visibleSectionIndex] });
                if (findedSection) {
                    possibleFields.push(...findedSection.fields);
                }
            }

            for (const fieldKey in newData.fields) {
                const sendedFields = _.intersection(possibleFields, usedFields);
                //sendedFields = sendedFields.length > 0 ? sendedFields : usedFields;
                if (sendedFields.indexOf(fieldKey) != -1) {
                    const values = [];
                    for (const valueIndex in newData.fields[fieldKey].values) {
                        if (newData.fields[fieldKey].values[valueIndex] != "" && !newData.fields[fieldKey].searchOff[valueIndex]) {
                            values.push(newData.fields[fieldKey].values[valueIndex]);
                        }
                    }
                    if (values.length != 0) {
                        fields.push({ key: fieldKey, values: values });
                    }
                }
            }
        }

        const preparedData = { fields: fields, sources: sources };
        return preparedData;
    }

    function createField(fieldName: any) {
        setFieldData(fieldName, -1, "", newINoteData(), false);
    }

    function createFieldCustom(fieldName: string, label: string) {
        if (label) {
            setFieldCustomData(fieldName, -1, "", newINoteData(), label, false);
        }
    }

    function removeField(fieldName: any, index: number) {
        const newData = JSON.parse(JSON.stringify(data));
        if (newData.fields[fieldName].values.length == 1) {
            delete newData.fields[fieldName];
            const visibleFieldsIndex = newData.categories[category].visible_fields.indexOf(fieldName);
            newData.categories[category].visible_fields.splice(visibleFieldsIndex, 1);
        }
        else {
            if (index > -1 && newData.fields[fieldName].values.length > index) {
                newData.fields[fieldName].values.splice(index, 1);
                newData.fields[fieldName].notes.splice(index, 1);
                newData.fields[fieldName].searchOff.splice(index, 1);
                newData.fields[fieldName].urls?.splice(index, 1);
                newData.fields[fieldName].labels?.splice(index, 1);
            }
            else {
                newData.fields[fieldName].values.splice(-1);
                newData.fields[fieldName].notes.splice(-1);
                newData.fields[fieldName].searchOff.splice(-1);
                newData.fields[fieldName].urls?.splice(-1);
                newData.fields[fieldName].labels?.splice(-1);
            }
        }
        setData(newData);
    }

    function shiftFieldData(fieldName: string, index: number) {
        const newData = JSON.parse(JSON.stringify(data));
        let array = newData.fields[fieldName].values;
        array.unshift(array.splice(index, 1)[0]);
        array = newData.fields[fieldName].labels;
        array.unshift(array.splice(index, 1)[0]);
        array = newData.fields[fieldName].notes;
        array.unshift(array.splice(index, 1)[0]);
        array = newData.fields[fieldName].searchOff;
        array.unshift(array.splice(index, 1)[0]);
        array = newData.fields[fieldName].urls;
        array.unshift(array.splice(index, 1)[0]);
        saveData(newData);
    }

    function getFieldData(fieldName: any) {
        return data.fields[fieldName] || { values: [""], notes: newINoteData(), searchOff: [""] };
    }

    let batchActive = false;
    let tempData: any;

    function startBatch(batch: boolean) {
        batchActive = batch;
        if (batchActive) {
            tempData = JSON.parse(JSON.stringify(data));
        } else {
            saveData(tempData);
            tempData = undefined;
            batchActive = false;
        }
    }

    function setFieldData(fieldName: any, index: number, value: string | undefined, note: INoteData | undefined, searchOff: boolean | undefined) {
        saveData(_setFieldData(getData(), fieldName, index, value, note, searchOff, undefined, undefined));
    }

    function setFieldImagesData(fieldName: string, images: any[]) {
        let newData = data;
        images.map((image) => {
            newData = _setFieldData(newData, fieldName, image.index, image.value, image.note, image.searchOff, image.url, image.label);
        })
        newData = _setFieldData(newData, fieldName, -1, "", newINoteData(), false, "", "");
        saveData(newData);
    }

    function setFieldCustomData(fieldName: any, index: number, value: string | undefined, note: INoteData | undefined, label: string | undefined, searchOff: boolean | undefined) {
        const newData = _setFieldData(data, fieldName, index, value, note, searchOff, undefined, label);
        saveData(newData);
    }

    function _setFieldData(data: any, fieldName: any, index: number, value: string | undefined, note: INoteData | undefined, searchOff: boolean | undefined, url: string | undefined, label: string | undefined) {
        const newData = JSON.parse(JSON.stringify(data));
        if (newData.fields[fieldName]) {
            if (newData.categories[category].visible_fields.indexOf(fieldName) != -1) {
                if (value != undefined) {
                    if (newData.fields[fieldName].values) {
                        if (index != -1 && newData.fields[fieldName].values[index] != undefined) {
                            newData.fields[fieldName].values[index] = value;
                        }
                        else {
                            newData.fields[fieldName].values.push(value)
                        }
                    } else {
                        newData.fields[fieldName].values = [value];
                    }
                }
                if (note != undefined) {
                    if (newData.fields[fieldName].notes) {
                        if (index != -1 && newData.fields[fieldName].notes[index] != undefined) {
                            newData.fields[fieldName].notes[index] = note;
                        }
                        else {
                            newData.fields[fieldName].notes.push(note)
                        }
                    } else {
                        newData.fields[fieldName].notes = [note];
                    }
                }
                if (url != undefined) {
                    if (newData.fields[fieldName].urls) {
                        if (index != -1 && newData.fields[fieldName].urls[index] != undefined) {
                            newData.fields[fieldName].urls[index] = url;
                        }
                        else {
                            newData.fields[fieldName].urls.push(url)
                        }
                    } else {
                        newData.fields[fieldName].urls = [url];
                    }
                }
                if (label != undefined) {
                    if (newData.fields[fieldName].labels) {
                        if (index != -1 && newData.fields[fieldName].labels[index] != undefined) {
                            newData.fields[fieldName].labels[index] = label;
                        }
                        else {
                            newData.fields[fieldName].labels.push(label)
                        }
                    } else {
                        newData.fields[fieldName].labels = [label];
                    }
                }
                if (searchOff != undefined) {
                    if (newData.fields[fieldName].searchOff) {
                        if (index != -1 && newData.fields[fieldName].searchOff[index] != undefined) {
                            newData.fields[fieldName].searchOff[index] = searchOff;
                        }
                        else {
                            newData.fields[fieldName].searchOff.push(searchOff)
                        }
                    } else {
                        newData.fields[fieldName].searchOff = [searchOff];
                    }
                }
            }
        }
        else {
            const newField: IFieldData = { values: [value], notes: [note], searchOff: [searchOff] };
            if (url) {
                newField.urls = [url];
            }
            if (label) {
                newField.labels = [label];
            }
            newData.fields[fieldName] = newField;
        }
        newData.categories[category].visible_fields = newData.categories[category].visible_fields.concat([fieldName]);
        return newData;
    }

    function showHideSection(sectionKey: string): void {
        const categoryConfig: ICategory = (_.find(config.categories, { "key": category }) || emptyConfig.categories[0]);
        const newData = JSON.parse(JSON.stringify(data));

        const indexOfSection = newData.categories[category].visible_sections.indexOf(sectionKey);
        if (indexOfSection != -1) {
            newData.categories[category].visible_sections.splice(indexOfSection, 1);
        }
        else {
            if (categoryConfig) {
                const findedSection = _.find(config.sections, { "key": sectionKey });
                if (findedSection) {
                    (findedSection.visible_fields || findedSection.fields).forEach(function (fieldKey) {
                        const findedField = _.find(config.fields, { "key": fieldKey });
                        if (findedField && findedField.type != "custom") {
                            if (!newData.fields[fieldKey]) {
                                newData.fields[fieldKey] = emptyField;
                            }
                            newData.categories[category].visible_fields = _.union(newData.categories[category].visible_fields, [fieldKey]);
                        }
                    });
                }
            }

            newData.categories[category].visible_sections.push(sectionKey);
        }
        saveData(newData);
    }

    function setDescription(description: string): void {
        const newData = JSON.parse(JSON.stringify(data));

        newData.description = description;

        saveData(newData);
    }

    function loadSections(): ISection[] {
        const categoryConfig: ICategory = (_.find(config.categories, { "key": category }) || emptyConfig.categories[0]);

        const sections: ISection[] = [];

        if (categoryConfig) {
            const sectionsKeys: string[] = categoryConfig.sections;
            for (const sectionKey in sectionsKeys) {
                const findedSection = _.find(config.sections, { "key": sectionsKeys[sectionKey] });
                if (findedSection) {
                    sections.push(findedSection);
                }
            }
        }
        return sections;
    }

    function loadVisibleSections(): ISection[] {
        const categoryConfig: ICategory = (_.find(config.categories, { "key": category }) || emptyConfig.categories[0]);
        const categoryData: any = data.categories[category];

        const sections: ISection[] = [];

        if (categoryConfig) {
            let sectionsKeys: string[] = [];
            if (categoryData && categoryData.visible_sections) {
                for (const sectionIndex in categoryConfig.sections) {
                    const sectionKey = categoryConfig.sections[sectionIndex];
                    if (categoryData.visible_sections.indexOf(sectionKey) != -1) {
                        sectionsKeys.push(sectionKey);
                    }
                }
            }
            else {
                sectionsKeys = categoryConfig.visible_sections || categoryConfig.sections;
            }

            for (const sectionKey in sectionsKeys) {
                const findedSection = _.find(config.sections, { "key": sectionsKeys[sectionKey] });
                if (findedSection) {
                    sections.push(findedSection);
                }
            }
        }
        return sections;
    }

    function loadMaxFieldValues(sectionKey: string): number {
        let count = 0;
        let fieldsKeys: string[] = [];

        const findedSection = _.find(config.sections, { "key": sectionKey });
        if (findedSection) {
            fieldsKeys = findedSection.fields;
        }

        for (const fieldKey in fieldsKeys) {
            const findedField = _.find(config.fields, { "key": fieldsKeys[fieldKey] });
            if (findedField) {
                let valueSize = 0;
                if (data.fields[fieldsKeys[fieldKey]] && data.fields[fieldsKeys[fieldKey]].values) {
                    valueSize = data.fields[fieldsKeys[fieldKey]].values.length;
                }
                count = Math.max(count, valueSize || 0);
            }
        }
        return count;
    }

    function loadVisibleFields(sectionKey: string): IField[] {
        const categoryData: any = data.categories[category];

        const fields: IField[] = [];
        let fieldsKeys: string[] = [];

        const findedSection = _.find(config.sections, { "key": sectionKey });
        if (findedSection) {
            if (categoryData) {
                for (const fieldIndex in findedSection.fields) {
                    const fieldKey = findedSection.fields[fieldIndex];
                    const existedDatafield = data.fields[fieldKey];
                    if (existedDatafield) {
                        if (categoryData.visible_fields.indexOf(fieldKey) == -1 && existedDatafield.values.length == 1 && existedDatafield.values[0] == "") {
                            //if empty and not visible in                            
                        }
                        else {
                            fieldsKeys.push(fieldKey);
                        }
                    }
                    else {
                        const findedField = _.find(config.fields, { "key": fieldKey });
                        if (findedField && findedField.type == "separator") {
                            fieldsKeys.push(fieldKey);
                        }
                    }
                }
            }
            else {
                fieldsKeys = findedSection.visible_fields || findedSection.fields;
            }
        }

        for (const fieldKey in fieldsKeys) {
            const findedField = _.find(config.fields, { "key": fieldsKeys[fieldKey] });
            if (findedField) {
                fields.push(findedField);
            }
        }
        return fields;
    }

    function loadSteps(customSteps: boolean): IStep[] {
        const categoryConfig: ICategory = (_.find(config.categories, { "key": category }) || emptyConfig.categories[0]);
        const categoryData: any = data.categories[category];

        const steps: IStep[] = [];
        if (categoryConfig) {
            if (!customSteps) {
                for (const stepKey in categoryConfig.steps) {
                    const findedStep = _.find(config.steps, { "key": categoryConfig.steps[stepKey] });
                    if (findedStep && _.intersectionWith(config.sources, findedStep?.sources, (o, key) => o.key === key).length > 0) {
                        steps.push(findedStep);
                    }
                }
            } else if (categoryData) {
                for (const customStep in categoryData.customSteps) {
                    steps.push(categoryData.customSteps[customStep]);
                }
            }
        }

        return steps;
    }

    /*function loadAllSources(stepKey?: string, userSources?: boolean): ISource[] {
        const allSources: ISource[] = [];
        for (const categoryId in config.categories) {
            const category = config.categories[categoryId];
            for (const stepId in category.steps) {
                const step = category.steps[stepId];
                const findedStep = _.find(config.steps, { "key": step });
                const stepSources = findedStep?.sources;
                stepSources
            }
        }
    }*/

    function loadSources(stepKey?: string, userSources?: boolean): ISource[] {
        const loadedSteps: IStep[] = loadSteps(userSources || false);

        let findedSeparator;
        const sources: ISource[] = [];
        if (stepKey) {
            const findedStep = _.find(loadedSteps, { "key": stepKey });
            if (findedStep) {
                const loadedSources: string[] = findedStep.sources;
                for (const source in loadedSources) {
                    findedSeparator = _.find(config.separators, { "key": loadedSources[source] }) || findedSeparator;
                    const findedSource = _.find(config.sources, { "key": loadedSources[source] });
                    if (findedSource) {
                        if (data.categories[category]
                            && data.categories[category].steps
                            && data.categories[category].steps[stepKey]
                            && data.categories[category].steps[stepKey].selectedSources
                            && data.categories[category].steps[stepKey].selectedSources.includes(findedSource.key)) {
                            findedSource.selected = true;
                        }
                        if (findedSeparator) {
                            findedSource.group = utilObjectLocalization(findedSeparator, "title");
                        }
                        sources.push(findedSource);
                    }
                }
            }
        }
        else {
            for (const loadedStep in loadedSteps) {
                const loadedSources: string[] = loadedSteps[loadedStep].sources;
                for (const source in loadedSources) {
                    let findedSource = _.find(config.sources, { "key": loadedSources[source] });
                    if (findedSource) {
                        findedSource = JSON.parse(JSON.stringify(findedSource));
                        if (findedSource) {
                            findedSource.group = utilObjectLocalization(loadedSteps[loadedStep], "title");
                            sources.push(findedSource);
                        }
                    }
                }
            }
        }
        return sources;
    }

    function getSourceSelected(stepKey?: string): ISource[] {
        const categoryData: any = data.categories[category];
        const sources: ISource[] = [];
        if (categoryData) {
            if (stepKey) {
                if (categoryData.steps && categoryData.steps[stepKey] && categoryData.steps[stepKey].selectedSources) {
                    const selectedSources = categoryData.steps[stepKey].selectedSources;
                    for (const sourceIndex in selectedSources) {
                        const findedSource = _.find(config.sources, { "key": selectedSources[sourceIndex].key });
                        if (findedSource) {
                            sources.push(findedSource);
                        }
                    }
                }
            }
            else {
                if (categoryData.steps) {
                    for (const stepKey in categoryData.steps) {
                        const step = categoryData.steps[stepKey];
                        if (step && step.selectedSources) {
                            for (const source in step.selectedSources) {
                                const findedSource = _.find(config.sources, { "key": step.selectedSources[source].key });
                                if (findedSource) {
                                    sources.push(findedSource);
                                }
                            }
                        }
                    }
                }
            }
        }
        return sources;
    }

    function resetSources(): void {
        const newData = getData();
        for (const categoryId in newData.categories) {
            delete newData.categories[categoryId].steps;
        }
        saveData(newData);
    }

    function setSourceSelected(sources: ISource[], stepKey: string): void {
        saveData(_setSourceSelected(sources, stepKey));
    }

    function _setSourceSelected(sources: ISource[], stepKey: string): any {
        const newData = JSON.parse(JSON.stringify(data));
        const categoryData: any = newData.categories[category];
        if (!categoryData.steps) {
            categoryData.steps = {};
        }
        if (!categoryData.steps[stepKey]) {
            categoryData.steps[stepKey] = { selectedSources: [] };
        }
        categoryData.steps[stepKey].selectedSources = [];
        for (const sourceIndex in sources) {
            categoryData.steps[stepKey].selectedSources.push(sources[sourceIndex]);
        }
        return newData;
    }

    function setSourceDisabledField(source: ISource, stepKey: string, field: string, disable: boolean): void {
        const newData = JSON.parse(JSON.stringify(data));
        const categoryData: any = newData.categories[category];
        if (!categoryData.steps) {
            categoryData.steps = {};
        }
        if (!categoryData.steps[stepKey]) {
            categoryData.steps[stepKey] = { sourcesDisabledFields: [] };
        }
        if (!categoryData.steps[stepKey].sourcesDisabledFields) {
            categoryData.steps[stepKey].sourcesDisabledFields = [];
        }

        const findedSourceDisabledFields = _.find(categoryData.steps[stepKey].sourcesDisabledFields, { key: source.key }) || { key: source.key, fields: [] };
        if (disable) {
            findedSourceDisabledFields.fields.push(field);
        }
        else {
            _.pull(findedSourceDisabledFields.fields, field);
        }

        _.remove(categoryData.steps[stepKey].sourcesDisabledFields, { key: source.key });

        if (findedSourceDisabledFields.fields.length > 0) {
            categoryData.steps[stepKey].sourcesDisabledFields.push({ key: source.key, fields: findedSourceDisabledFields.fields });
        }
        saveData(newData);
    }

    function isSourceDisabledField(source: ISource, stepKey: string, field: string): boolean {
        const findedSourceDisabledFields = _.find((data.categories[category]?.steps || {})[stepKey]?.sourcesDisabledFields || [], { key: source.key }) || { key: source.key, fields: [] };
        return _.indexOf(findedSourceDisabledFields.fields, field) != -1;
    }

    function hasSourceDisabledFields(source: ISource, stepKey: string): boolean {
        return _.find((data.categories[category]?.steps || {})[stepKey]?.sourcesDisabledFields || [], { key: source.key }) != undefined;
    }

    function editUserStep(stepKey: string, newStepKey: string): void {
        const newData = _saveUserStep(newStepKey);
        const categoryData: any = newData.categories[category];
        delete categoryData.customSteps[stepKey];
        saveData(newData);
    }

    function saveUserStep(stepKey: string): void {
        saveData(_saveUserStep(stepKey));
    }

    function _saveUserStep(stepKey: string): any {
        const selectedSources = getSourceSelected("NEW");
        const newData = _setSourceSelected([], "NEW");
        const categoryData: any = newData.categories[category];

        const customStep = {
            "key": stepKey,
            "title": stepKey,
            "sources": selectedSources.map(s => s.key)
        }

        if (!categoryData.customSteps) {
            categoryData.customSteps = {};
        }
        categoryData.customSteps[stepKey] = customStep;

        if (!categoryData.steps["CUSTOM"]) {
            categoryData.steps["CUSTOM"] = { selectedSources: [] };
        }
        categoryData.steps["CUSTOM"].selectedSources = [];
        for (const sourceIndex in selectedSources) {
            categoryData.steps["CUSTOM"].selectedSources.push(selectedSources[sourceIndex]);
        }
        return newData;
    }

    function removeUserStep(stepKey: string): void {
        const newData = JSON.parse(JSON.stringify(data));
        const categoryData: any = newData.categories[category];
        delete categoryData.customSteps[stepKey];
        saveData(newData);
    }

    function resetForm(): void {
        const newData = getData();
        for (const fieldKey in newData.fields) {
            newData.fields[fieldKey] = emptyField;
            const configField = _.find(config.fields, { key: fieldKey });
            if (configField?.type == "custom") {
                delete newData.fields[fieldKey];
            }
        }
        for (const categoryKey in newData.categories) {
            delete newData.categories[categoryKey].lastIndexedObject;
        }
        saveData(newData);
    }

    function utilObjectLocalization(object: any, prop: string, defaultVal?: string) {
        if (object) {
            const defaultValue = defaultVal == undefined ? t(object.title) || t(object.key) : defaultVal;
            const value = object[prop + "_" + i18next.language] || object[prop] || defaultValue;
            return value;
        }
        return "";
    }

    function computeAllFieldsTxt() {
        let summary = "";
        let actualGroup = "";
        let summaryGroup = [];
        let summaryGroupMaxValues = 0;

        const summaryNotes = [];

        const sections = loadSections();
        for (const sectionKey in loadSections()) {
            for (const fieldIndex in sections[sectionKey].fields) {
                const fieldKey = sections[sectionKey].fields[fieldIndex];
                const dataField = data.fields[fieldKey];
                if (dataField && dataField.values.join("") != "") {
                    const configField = _.find(config.fields, { key: fieldKey });
                    let values = "";
                    switch (configField?.type) {
                        case "image":
                            values += "<table><tbody>";
                            values += "<tr>";
                            dataField.values.map((value: string, index: string | number) => {
                                if (value) {
                                    const htmlImg = '<img src="' + value + '"/>';
                                    values += "<td>" + htmlImg + "</td>";
                                }
                            });
                            values += "</tr><tr>";
                            dataField.values.map((value: string, index: string | number) => {
                                if (value) {
                                    const note = dataField.notes[index];
                                    const htmlNote = note.value ? note.value : "";
                                    values += "<td>" + htmlNote + "</td>";
                                }
                            });
                            summary += utilObjectLocalization(configField, "title") + ":<br />" + values + "</tr></tbody></table>" + "<br /><br />";
                            break;
                        case "description":
                            dataField.values.map((value: string, index: string | number) => {
                                if (value) {
                                    const note = dataField.notes[index];
                                    values += value + (note.value ? (" (" + note.value + ")") : "") + "<br />";
                                }
                            });
                            summary += utilObjectLocalization(configField, "title") + ": " + values.slice(0, -1) + "<br /><br />";
                            break;
                        case "custom":
                            for (const valueIndex in dataField.values) {
                                const note = dataField.notes[valueIndex];

                                if (note.value || note.img) {
                                    let summaryNote = '<tr>';
                                    summaryNote += '<td style="border-width: 0px;"><sup>[' + (summaryNotes.length + 1) + "]</sup></td>";
                                    summaryNote += '<td style="border-width: 0px;">' + note.value || "" + "</td>";
                                    summaryNote += '<td style="border-width: 0px;">' + (note.img ? '<img src="' + note.img + '"/></td>' : '</td>');
                                    summaryNote += "</tr>";
                                    summaryNotes.push(summaryNote);
                                }

                                values += dataField.labels[valueIndex] + ": " + dataField.values[valueIndex];
                                if (note.value || note.img)
                                    values += " <sup>[" + summaryNotes.length + "]</sup>";//(note.value ? (" (" + note.value + ")") : "");
                                values += "<br /><br />";
                            }
                            summary += values;
                            break;
                        default:
                            if (configField?.group) {
                                if (actualGroup == "") {
                                    actualGroup = configField?.group;
                                }
                                if (actualGroup != configField?.group) {
                                    summary = _writeActualGroupFromAllTxtSummary(summaryGroupMaxValues, summaryGroup, summary, summaryNotes);
                                    actualGroup = configField?.group;
                                    summaryGroup = [];
                                    summaryGroupMaxValues = 0;
                                }
                                summaryGroup.push({ config: configField, data: dataField.values, notes: dataField.notes });
                                summaryGroupMaxValues = Math.max(summaryGroupMaxValues, dataField.values.length);
                            }
                            else {
                                if (actualGroup) {
                                    summary = _writeActualGroupFromAllTxtSummary(summaryGroupMaxValues, summaryGroup, summary, summaryNotes);
                                    actualGroup = "";
                                    summaryGroup = [];
                                    summaryGroupMaxValues = 0;
                                }
                                dataField.values.map((value: string, index: string | number) => {
                                    if (value) {

                                        const note = dataField.notes[index];
                                        if (note.value || note.img) {
                                            let summaryNote = '<tr>';
                                            summaryNote += '<td style="border-width: 0px;"><sup>[' + (summaryNotes.length + 1) + "]</sup></td>";
                                            summaryNote += '<td style="border-width: 0px;">' + note.value || "" + "</td>";
                                            summaryNote += '<td style="border-width: 0px;">' + (note.img ? '<img src="' + note.img + '"/></td>' : '</td>');
                                            summaryNote += "</tr>";
                                            summaryNotes.push(summaryNote);
                                        }

                                        values += value;
                                        if (note.value || note.img)
                                            values += " <sup>[" + summaryNotes.length + "]</sup>";//(note.value ? (" (" + note.value + ")") : "");
                                        values += ", ";
                                    }
                                });
                                summary += utilObjectLocalization(configField, "title") + ": " + values.slice(0, -2) + "<br /><br />";
                            }
                    }
                }
            }
        }
        if (actualGroup) {
            summary = _writeActualGroupFromAllTxtSummary(summaryGroupMaxValues, summaryGroup, summary, summaryNotes);
        }

        if (summaryNotes.length > 0) {
            summary += "<hr>";
            summary += '<table style="border-collapse: collapse; border-width: 0px;" border="1"><tbody>';

            summary += summaryNotes.join("");

            summary += "</tbody></table>";
        }

        return summary;
    }

    function _writeActualGroupFromAllTxtSummary(summaryGroupMaxValues: number, summaryGroup: { data: string[]; notes: any[]; config: any; }[], summary: string, summaryNotes: string[]): string {
        for (let i = 0; i < summaryGroupMaxValues; i++) {
            summaryGroup.map((group: { data: string[]; notes: any[]; config: any; }) => {
                if (group.data[i] || group.notes[i]) {

                    const value = group.data[i];
                    const note = group.notes[i];

                    if (note.value || note.img) {
                        let summaryNote = '<tr>';
                        summaryNote += '<td style="border-width: 0px;"><sup>[' + (summaryNotes.length + 1) + "]</sup></td>";
                        summaryNote += '<td style="border-width: 0px;">' + note.value || "" + "</td>";
                        summaryNote += '<td style="border-width: 0px;">' + (note.img ? '<img src="' + note.img + '"/></td>' : '</td>');
                        summaryNote += "</tr>";
                        summaryNotes.push(summaryNote);
                    }

                    summary += utilObjectLocalization(group.config, "title") + ": " + group.data[i];
                    if (note.value || note.img)
                        summary += " <sup>[" + summaryNotes.length + "]</sup>";//(note.value ? (" (" + note.value + ")") : "");
                    summary += "<br />";
                }
            });
            summary += "<br />";
        }
        return summary;
    }


    function prepareDisplayFields(): any[] {
        const displayFields = [];
        let actualGroup = "";
        let summaryGroup = [];
        let summaryGroupMaxValues = 0;
        const dataFields = getDataFields();
        const sections = loadSections();
        for (const sectionKey in loadSections()) {
            for (const fieldIndex in sections[sectionKey].fields) {
                const fieldKey = sections[sectionKey].fields[fieldIndex];
                const configField = _.find(config.fields, { "key": fieldKey });
                switch (configField?.type) {
                    case "description":
                    case "image":
                    case "negator":
                    case "positive":
                    case "separator":
                        {
                            break;
                        }
                    default: {
                        const dataField = dataFields[fieldKey];
                        if (dataField && dataField.values.join("").length > 0) {
                            ////displayFields.push({ config: configField, data: { values: dataField.values, notes: dataField.notes } });
                            if (configField?.group) {
                                if (actualGroup == "") {
                                    actualGroup = configField?.group;
                                }
                                if (actualGroup != configField?.group) {
                                    for (let i = 0; i < summaryGroupMaxValues; i++) {
                                        summaryGroup.map((group) => {
                                            if (group.data[i] || group.notes[i]) {
                                                displayFields.push({ config: group.config, data: { values: [group.data[i]], notes: [group.notes[i]] } });
                                            }
                                        });
                                    }
                                    actualGroup = configField?.group;
                                    summaryGroup = [];
                                    summaryGroupMaxValues = 0;
                                }
                                summaryGroup.push({ config: configField, data: dataField.values, notes: dataField.notes });
                                summaryGroupMaxValues = Math.max(summaryGroupMaxValues, dataField.values.length);
                            }
                            else {
                                if (actualGroup) {
                                    for (let i = 0; i < summaryGroupMaxValues; i++) {
                                        summaryGroup.map((group) => {
                                            if (group.data[i] || group.notes[i]) {
                                                displayFields.push({ config: group.config, data: { values: [group.data[i]], notes: [group.notes[i]] } });
                                            }
                                        });
                                    }
                                    actualGroup = "";
                                    summaryGroup = [];
                                    summaryGroupMaxValues = 0;
                                }
                                if (configField?.type == "custom") {
                                    dataField.labels.map((label: any, i: string | number) => {
                                        const customConfig = { title: label };
                                        displayFields.push({ config: customConfig, data: { values: [dataField.values[i]], notes: [dataField.notes[i]] } });
                                    });
                                }
                                else {
                                    displayFields.push({ config: configField, data: { values: dataField.values, notes: dataField.notes, labels: dataField.labels } });
                                }
                            }
                        }
                    }
                }
            }
            //if (displayFields[displayFields.length - 1] != null)
            //displayFields.push(null);
        }
        if (actualGroup) {
            for (let i = 0; i < summaryGroupMaxValues; i++) {
                summaryGroup.map((group) => {
                    if (group.data[i] || group.notes[i]) {
                        displayFields.push({ config: group.config, data: { values: [group.data[i]], notes: [group.notes[i]] } });
                    }
                });
            }
        }
        return displayFields;
    }

    function prepareDiagram(findedExportDef: IReport | undefined) {
        const nodes: any[] = [];
        const edges: any[] = [];
        const dataFields = getDataFields();
        const allFieldsTxt = computeAllFieldsTxt();
        const findedCategoryDef = _.find(config.categories, { "key": category });
        if (findedCategoryDef) {
            if (findedExportDef) {
                const objects = JSON.parse(JSON.stringify(findedExportDef.values));
                if (objects) {
                    objects.map((object: IObject, objIndex: number) => {
                        if (objIndex != 0 && object.type != "link") {
                            let maxDuplicates = 0;
                            const fields = _.extend(object.fields, { __label: utilObjectLocalization(object, "label"), "_v11n:label": utilObjectLocalization(object, "label"), __content: utilObjectLocalization(object, "content") });
                            if (object.uuid) {
                                fields.__uuid = object.uuid;
                            }
                            _.keys(fields).map((key: any) => {
                                const row = fields[key];
                                if (row.search(res) != -1) {
                                    let m;
                                    const objectIndicators = [];
                                    while ((m = res.exec(row))) {
                                        objectIndicators.push({
                                            fieldname: m[1],
                                            replace: m[0]
                                        });
                                    }
                                    for (let i = 0; objectIndicators.length > i; i++) {
                                        maxDuplicates = Math.max(maxDuplicates, _.reject(dataFields[objectIndicators[i].fieldname]?.values, _.isEmpty).length);
                                    }
                                }
                            });

                            for (let duplicateIndex = 1; duplicateIndex < maxDuplicates; duplicateIndex++) {
                                const duplicatedObject = JSON.parse(JSON.stringify(object));
                                duplicatedObject.index = duplicateIndex;
                                duplicatedObject.id = duplicatedObject.id + "__" + duplicateIndex;
                                objects.push(duplicatedObject);

                                let findedDuplicatedEdge = _.find(objects, { target: object.id });
                                if (findedDuplicatedEdge) {
                                    findedDuplicatedEdge = JSON.parse(JSON.stringify(findedDuplicatedEdge));
                                    findedDuplicatedEdge.index = duplicateIndex;
                                    findedDuplicatedEdge.target = duplicatedObject.id;
                                    objects.push(findedDuplicatedEdge);
                                }
                            }
                        }
                    });

                    objects.map((object: IObject, objIndex: number) => {
                        const diagramFields: any = {};
                        const fields = _.extend(object.fields, { __label: utilObjectLocalization(object, "label"), "_v11n:label": utilObjectLocalization(object, "label"), __content: utilObjectLocalization(object, "content") });
                        if (object.uuid) {
                            fields.__uuid = object.uuid;
                        }
                        _.keys(fields).map((key: any) => {
                            let value = "";
                            let row = fields[key];
                            if (row.search(res) != -1) {
                                let m;
                                const objectIndicators = [];
                                while ((m = res.exec(row))) {
                                    objectIndicators.push({
                                        fieldname: m[1],
                                        replace: m[0]
                                    });
                                }
                                let emptyValues = 0;
                                for (let i = 0; objectIndicators.length > i; i++) {
                                    const fieldName = objectIndicators[i].fieldname;
                                    const fieldValues = dataFields[fieldName]?.values || [""];
                                    if (fieldValues.length == 1 && fieldValues[0] == "") {
                                        emptyValues++;
                                    }
                                    row = row.replace(objectIndicators[i].replace, fieldValues[object.index || 0] || "");
                                }
                                if (emptyValues != objectIndicators.length) {
                                    value += row.replace(/  +/g, " ").replace(/ ' |' | '/g, "'").trim();
                                }
                            }
                            else {
                                value += row;
                            }
                            diagramFields[key] = ({
                                type: "u:STRING",
                                value: value
                            });
                        });

                        let guid = uuidv4();
                        if (object.type == "link") {
                            if (diagramFields["__uuid"]) {
                                guid = getUuidByString(diagramFields["__uuid"].value);
                            }
                            else {
                                const findedSource = _.find(nodes, (o) => {
                                    return o.data.finderId == object.source;
                                });
                                const findedTarget = _.find(nodes, (o) => {
                                    return o.data.finderId == object.target;
                                });
                                if (findedSource && findedTarget) {
                                    guid = getUuidByString(findedSource.data.id + "_" + findedTarget.data.id);
                                }
                            }
                        }
                        else {
                            if (diagramFields["__uuid"]) {
                                guid = getUuidByString(diagramFields["__uuid"].value);
                            }
                            else {
                                guid = getUuidByString(diagramFields["__label"].value);
                            }
                        }

                        const mainObject = objIndex == 0;

                        diagramFields["_key"] = ({
                            type: "u:STRING",
                            value: guid
                        });

                        diagramFields["_v11n:id"] = ({
                            type: "u:STRING",
                            value: guid
                        });

                        diagramFields["_v11n:query"] = ({
                            type: "u:STRING",
                            value: diagramFields["_v11n:query"]?.value || (mainObject ? "##FINDER_OBJECT_TQL##" : "")
                        });

                        const filteredDiagramFields = _.pickBy(diagramFields, function (value, key) { return key != "__label" && key != "__content" && key != "__uuid" });
                        const diagramObject: any = {
                            "data": {
                                "id": guid,
                                "finderId": object.id,
                                "labelText": diagramFields["__label"].value,
                                "document": {
                                    "_source": "[tt-virtual].none",
                                    "_uuidkey": guid,
                                    "fields": filteredDiagramFields,
                                    "content": diagramFields["__content"]?.value || (mainObject ? "##FINDER_OBJECT_CONTENT##" : ""),
                                    "virtual": true
                                }
                            }
                        }

                        if (mainObject) {
                            diagramObject.data.__mainObject = true;
                        }

                        if (object.type == "link") {
                            const findedSource = _.find(nodes, (o) => {
                                return o.data.finderId == object.source;
                            });
                            const findedTarget = _.find(nodes, (o) => {
                                return o.data.finderId == object.target;
                            });
                            if (findedSource && findedTarget) {
                                const sourceGuid = findedSource.data.id;
                                const targetGuid = findedTarget.data.id;
                                diagramObject.data.source = sourceGuid;
                                diagramObject.data.target = targetGuid;
                                diagramObject.data.document.fields["_v11n:node1"] = {
                                    "type": "u:STRING",
                                    "value": sourceGuid
                                };
                                diagramObject.data.document.fields["_v11n:node2"] = {
                                    "type": "u:STRING",
                                    "value": targetGuid
                                };
                                diagramObject.data.document.fields["_v11n:itemtype"] = {
                                    "type": "u:STRING",
                                    "value": "2"
                                };
                                edges.push(diagramObject);
                            }
                        } else {
                            if (diagramFields["__label"].value || fields["label"] == "") {
                                nodes.push(diagramObject);
                            }
                        }
                    });
                }
            }
        }

        return {
            "version": "Tovek-2.0",
            "datatype": "Chart",
            "data": {
                "name": "Chart",
                "arrowStyle": "STANDARD:DEFAULT",
                "hideEdgeLabels": false,
                "mergeEdges": false,
                "elements": {
                    "nodes": nodes,
                    "edges": edges
                }
            }
        };
    }

    function getLastIndexedObject(): void {
        const newData = JSON.parse(JSON.stringify(data));
        return newData.categories[category]?.lastIndexedObject;
    }

    function setLastIndexedObject(object: string): void {
        const newData = JSON.parse(JSON.stringify(data));
        if (object) {
            newData.categories[category].lastIndexedObject = object;
        } else {
            delete newData.categories[category].lastIndexedObject;
        }
        saveData(newData);
    }

    return (
        <TovekAppContext.Provider value={{
            appReady,
            reload,
            setReload,
            setConfig,
            config,
            data,
            setDownload,
            download,
            createEmptyData,
            getDataFields,
            category,
            step,
            setStep,
            setCategory,
            isNotesVisible,
            setNotesVisible,
            getFieldData,
            setFieldData,
            startBatch,
            setFieldImagesData,
            shiftFieldData,
            removeField,
            createField,
            createFieldCustom,
            loadMaxFieldValues,
            saveData,
            loadVisibleSections,
            loadSections,
            showHideSection,
            loadVisibleFields,
            loadSteps,
            loadSources,
            getSourceSelected,
            setSourceSelected,
            setSourceDisabledField,
            isSourceDisabledField,
            hasSourceDisabledFields,
            preparedDataForSend,
            preparedReportDataForSend,
            setDescription,
            saveUserStep,
            removeUserStep,
            editUserStep,
            resetForm,
            resetSources,
            utilObjectLocalization,
            lastConfigVersion,
            computeAllFieldsTxt,
            prepareDisplayFields,
            prepareDiagram,
            getLastIndexedObject,
            setLastIndexedObject,
            needBackdrop,
            setNeedBackdrop
        }}>
            {children}
        </TovekAppContext.Provider>
    )
}

export { TovekAppContextProvider, TovekAppContext }
