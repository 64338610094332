import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react'
import ConfigService from '../services/config';
import { IResponse } from '../types/IResponse';

const CurrentUsername: React.FC = () => {
    const [config, setConfig] = useState({
        loading: true,
        username: "no-login"
    });

    useEffect(() => {
        setConfig(state => ({ ...state, loading: true }));
        ConfigService.get("trest.currentUsername")
            .then((response: AxiosResponse<IResponse, string>) => {
                setConfig({ loading: false, username: response.data.data.username });
                //console.log(response.data.data);
            })
            .catch((e: any) => {
                console.log(e);
                ConfigService.check401(e);
            });
    }, [setConfig]);

    return (

        <div> {config.loading ? "" : config.username}</div>
    )
}

export default CurrentUsername
