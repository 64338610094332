import { AxiosError, AxiosResponse } from "axios";
import http from "./http-common";
import { IResponse } from "../types/IResponse";
import { t } from "i18next";

declare const __apikey: string;

class ConfigService {
  get(entry: string): Promise<AxiosResponse<IResponse, string>> {
    return http.get<IResponse>(`/config/get?entry=${entry}&apikey=${__apikey}`);
  }

  getConfig(defaultConfig?: string): Promise<AxiosResponse<IResponse, string>> {
    const params = new URLSearchParams();
    params.append('apikey', __apikey);
    if (defaultConfig) params.append('config', JSON.stringify(defaultConfig));
    return http.post<IResponse>(`/finder/config`, params, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
  }

  getRequests(category: string, step: string, data: string, defaultConfig?: string): Promise<AxiosResponse<IResponse, string>> {
    const params = new URLSearchParams();
    params.append('apikey', __apikey);
    params.append('category', category);
    params.append('step', step);
    params.append('data', data);
    if (defaultConfig) params.append('config', JSON.stringify(defaultConfig));
    return http.post<IResponse>(`/finder/requests`, params, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
  }

  getReports(category: string, data: string, defaultConfig?: string): Promise<AxiosResponse<IResponse, string>> {
    const params = new URLSearchParams();
    params.append('apikey', __apikey);
    params.append('category', category);
    params.append('data', data);
    if (defaultConfig) params.append('config', JSON.stringify(defaultConfig));
    return http.post<IResponse>(`/finder/reports`, params, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
  }

  getTargets(): Promise<AxiosResponse<IResponse, string>> {
    return http.get<IResponse>(`/index/targets?&apikey=${__apikey}`);
  }

  index(target: string, objectFields: any): Promise<AxiosResponse<IResponse, string>> {
    const params = new URLSearchParams();
    params.append('apikey', __apikey);
    params.append('target', target);

    objectFields.map((param: { name: string; value: string; }) => {
      params.append('document[0][' + param.name + ']', param.value);
    });

    return http.post<IResponse>(`/index/index`, params, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
  }

  check401(e: any) {
    if (e?.response?.status == 401) {
      location.reload();
    }
  }

  getErrorText(e: any) {
    const errMessages = e?.response?.data?.header?.messages;
    return (errMessages ? (errMessages[0]?.text || e?.message) : e?.message) || t("application.App.Something went wrong");
  }
}
export default new ConfigService();