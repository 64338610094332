import * as React from 'react';
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { t } from 'i18next';

export type SaveDataConfigDialogProps = {
    getSaveDataConfigState: (a: SaveDataConfig) => void;
}

export type SaveDataConfig = {
    form: boolean;
    checkedSources: boolean;
    customSourcesSets: boolean;
}

export default function SaveDataConfigDialog(props: SaveDataConfigDialogProps) {

    const [saveDataConfigState, setSaveDataConfigState] = React.useState({
        form: true,
        checkedSources: true,
        customSourcesSets: false
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaveDataConfigState({
            ...saveDataConfigState,
            [event.target.name]: event.target.checked,
        });
    };

    React.useEffect(() => {
        props.getSaveDataConfigState(saveDataConfigState);
    }, [saveDataConfigState])

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox checked={saveDataConfigState.form} onChange={handleChange} name="form" />
                }
                label={t("application.SaveDataConfigDialog.from forms")}
            />
            <FormControlLabel
                control={
                    <Checkbox checked={saveDataConfigState.checkedSources} onChange={handleChange} name="checkedSources" />
                }
                label={t("application.SaveDataConfigDialog.selected sources")}
            />
            <FormControlLabel
                control={
                    <Checkbox checked={saveDataConfigState.customSourcesSets} onChange={handleChange} name="customSourcesSets" />
                }
                label={t("application.SaveDataConfigDialog.named resource lists")}
            />
        </FormGroup>
    );
}