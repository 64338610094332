import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IconButton, Icon, Checkbox, FormGroup } from '@mui/material';
import { t } from 'i18next';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import { TovekAppContext } from '../../contexts/AppContext';

export interface TargetsDialogProps {
    id: string;
    keepMounted: boolean;
    value: string;
    open: boolean;
    onClose: (value?: string, reindex?: boolean) => void;
    options: string[];
}

export default function TargetsDialog(props: TargetsDialogProps) {
    const { getLastIndexedObject } = React.useContext<ITovekAppContext>(TovekAppContext);

    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef<HTMLElement>(null);

    let lastIndexedObject: any = getLastIndexedObject();

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value, !!lastIndexedObject);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleChangeChckb = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
            lastIndexedObject = undefined;
        }
        else {
            lastIndexedObject = getLastIndexedObject();
        }
    };



    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle>{t("application.TargetsDialog.Select Target Index")}</DialogTitle>
            <DialogContent dividers>
                <>
                    <RadioGroup
                        ref={radioGroupRef}
                        aria-label="ringtone"
                        name="ringtone"
                        value={value ?? ""}
                        onChange={handleChange}
                    >
                        {props.options.map((option) => (
                            <FormControlLabel
                                value={option}
                                key={option}
                                control={<Radio />}
                                label={option}
                            />
                        ))}
                    </RadioGroup>
                    <br />
                    {(!!lastIndexedObject) &&
                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked={!!lastIndexedObject} onChange={handleChangeChckb} />} label={<>{t("application.TargetsDialog.Update existed object") + ":"}<br />{lastIndexedObject.fields.label.value}</>} />
                        </FormGroup>
                    }
                </>
            </DialogContent>
            <DialogActions>
                <IconButton autoFocus onClick={handleCancel} >
                    <Icon>do_disturb</Icon>
                </IconButton >
                <IconButton onClick={handleOk} >
                    <Icon>check</Icon>
                </IconButton >
            </DialogActions>
        </Dialog>
    );
}