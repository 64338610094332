import { Container } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Content from '../components/Content';
import { TovekAppContext } from '../contexts/AppContext';
import { ITovekAppContext } from '../types/ITovekContextApp';

const HomePage: React.FC = () => {

    const { setCategory } = useContext<ITovekAppContext>(TovekAppContext);
    const location = useLocation();

    useEffect(() => {
        setCategory((location.pathname || "/").substring("/search/".length));
    }, [location])

    return (
        <Container sx={{ my: 2, p: "0px!important", flex: "1 1 auto" }} maxWidth={false} disableGutters>
            <Content />
        </Container>
    )
}

export default HomePage
