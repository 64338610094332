import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ISource, IStep } from '../../types/IConfiguration';
import SourcesCheckboxes from '../Sources/SourcesCheckboxes';
import { TovekAppContext } from '../../contexts/AppContext';
import { ITovekAppContext } from '../../types/ITovekContextApp';
import { FormControl, Box, IconButton } from '@mui/material';
import useLocalStorage from '../../controller/useLocalStorage';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NewUserSourcesDialog from '../Dialogs/NewUserSourcesDialog';
import { t } from 'i18next';

export default function UserSourcesSelectbox() {
    const { loadSources, saveUserStep, removeUserStep, editUserStep, loadSteps, category, utilObjectLocalization, setSourceSelected } = React.useContext<ITovekAppContext>(TovekAppContext);

    const [stepKey, setStepKey] = useLocalStorage<any>(category + "_userStep", "");

    const [dialogSourcesSetOpen, setDialogSourcesSetOpen] = React.useState({ editedStepKey: "", open: false });

    const handleChange = (event: SelectChangeEvent) => {
        if (loadedSteps.length == 0) {
            setDialogSourcesSetOpen({ editedStepKey: "", open: true }); handleClose();
        } else if (event.target.value != "") {
            setSourceSelected(loadSources(event.target.value, true), "CUSTOM");
            setStepKey(event.target.value);
        }
    };

    const handleCloseDialogSourcesSet = (newStep: string) => {
        if (newStep != "") {
            if (dialogSourcesSetOpen.editedStepKey != "" && dialogSourcesSetOpen.editedStepKey != newStep) {
                editUserStep(dialogSourcesSetOpen.editedStepKey, newStep);
            } else {
                saveUserStep(newStep);
            }
            setStepKey(newStep);
        }
        setDialogSourcesSetOpen({ editedStepKey: "", open: false });
    };

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const loadedSteps = loadSteps(true);

    return (
        <>
            <Box sx={{ mb: 2, pl: "148px" }} className="user-sources-selectbox">
                <FormControl variant="outlined" fullWidth={true}>
                    <InputLabel id="demo-simple-select-helper-label" shrink>{t("application.UserSourcesSelectbox.Sources Sets")}</InputLabel>
                    <Select
                        notched={true}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em>{t("application.UserSourcesSelectbox.Click to create new sources set")}</em>;
                            }

                            return selected;
                        }}
                        displayEmpty
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={stepKey}
                        label={t("application.UserSourcesSelectbox.Sources Sets")}
                        onChange={handleChange}
                        open={open}
                        onClose={handleClose}
                        onOpen={() => {
                            if (loadedSteps.length == 0) {
                                setDialogSourcesSetOpen({ editedStepKey: "", open: true }); handleClose();
                            } else {
                                handleOpen();
                            }
                        }}
                    >
                        <MenuItem value="" onClick={() => (setSourceSelected([], "NEW"), setDialogSourcesSetOpen({ editedStepKey: "", open: true }))}>
                            <em>{t("application.UserSourcesSelectbox.Click to create new sources set")}</em>
                        </MenuItem>
                        {
                            loadedSteps.map((step: IStep, index: any) => (
                                <MenuItem value={step.key} key={index}>{utilObjectLocalization(step, "title")}
                                    <IconButton size="small" sx={{ position: "absolute", right: 15 }} onClick={(e) => {
                                        e.stopPropagation();
                                        if (step.key == stepKey) {
                                            setStepKey("");
                                        }
                                        removeUserStep(step.key);
                                    }}>
                                        <DeleteIcon />
                                    </IconButton >
                                    <IconButton size="small" sx={{ position: "absolute", right: 50 }} onClick={(e) => {
                                        e.stopPropagation();
                                        setSourceSelected(loadSources(step.key, true), "NEW");
                                        setDialogSourcesSetOpen({ editedStepKey: step.key, open: true });
                                    }}>
                                        <EditIcon />
                                    </IconButton >
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <NewUserSourcesDialog
                    open={dialogSourcesSetOpen.open}
                    onClose={handleCloseDialogSourcesSet}
                    sources={loadSources("", false)}
                    step={dialogSourcesSetOpen.editedStepKey}
                />
            </Box>
            {stepKey != "" &&
                <SourcesCheckboxes sources={loadSources(stepKey, stepKey != "")} groupBy={(option: ISource) => option.group} stepKey={"CUSTOM"} searchDisabled={stepKey == ""} />
            }
        </>
    );
}
